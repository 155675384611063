
import {Vue, Options} from 'vue-class-component'
import $ from 'jquery'
import  { event } from "jquery";
import VueSelect from 'vue-next-select'
import 'vue-next-select/dist/index.min.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import { GetAllProducts, getProductModules, createProductModules, updateProductModules, deleteProductModule} from '../store/api/configsettings'

@Options({
  components: {
		VueSelect, 
        Loading
  },
})

export default class ProductModule extends Vue{
    private productModules = [];
    private products =[];
    private productNames = [];
    private newProductModuleName ='';
    private options = [];
    private isLoading = false;
    private loadingIconColor = '#00b8d0';
    private selectedOption = 'NextCounsel System';
    private editedModuleName = '';
    private editedID = '';

    created() {
        this.getModules();
        this.addProducts();
    }

    private getModules(){
        this.isLoading = true;
        getProductModules().subscribe((response: any)=>{
            this.productModules = response.data.modules;
            this.isLoading = false;
        })
    }

    private addProducts(){
        GetAllProducts().subscribe((response:any)=>{
            this.products = response.data.products;
            if (response.data.products) {
                this.productNames = response.data.products.map(product => product.name);
            }
        })
    }

    private addProductModules(productModule:any, id:any){
        const newProductModule= {
            product_id: id,
            module: productModule
        }
            this.isLoading = true;
        createProductModules( newProductModule).subscribe((response:any)=>{
            if (response.data.status === 'success'){
                this.isLoading = false;
                this.newProductModuleName = '';
                this.getModules();
                Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: response.data.message,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }else if(response.data.status=="error"){
                this.isLoading = false;
             Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: response.data.message,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }
        })    
    }


    private editProductModule(name: any, id: string, pid: string) {
        const editedModule={
            product_id: pid ,
            module: name
            }
    
        this.isLoading= true;
        updateProductModules(editedModule, id).subscribe((response: any) => {
            if (response.data.status === "success") {
                this.isLoading =false;
                $('#myModal').modal('hide');
                this.editedModuleName = "";
                this.getModules();
                Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                    id: 'toastAuthy', zindex: 99,  title: response.data.message,
                    titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                    icon: 'fa fa-check',   
                    iconColor: 'white',});
            }else if(response.data.status=="error"){
                this.isLoading = false;
             Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: response.data.message,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }
        })
    }

    private setProductModuleID (id: string, name: string ,) {
        this.editedID = id;
        this.editedModuleName = name;
    }

    get selectedProductID() :string  {
        const selectedProduct = this.products.filter((product: {name, id}) => product.name === this.selectedOption);
        let id: string = selectedProduct[0]['id'];
        return id;
    }


    private removeProductmodules(moduleid: any){
        Toast.question({
        timeout: 20000,
        close: false,
        overlay: false,
        id: 'question',
        zindex: 1000,
        title: '',
        message: 'Are you sure ?',
        position: 'center',
        buttons: [
            ['<button><b>YES</b></button>',  (instance, toast) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            
            this.isLoading = true
            deleteProductModule(moduleid).subscribe((response:any) =>{
                if (response.data.status =="success"){
                    this.isLoading = false;
                    this.getModules();
                    Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                    id: 'toastAuthy', zindex: 99,  title: response.data.message,
                    titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                    icon: 'fa fa-user-check',   
                    iconColor: 'white',});
                }else if(response.data.status=="error"){
                    this.isLoading = false;
                Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                    id: 'toastAuthy', zindex: 99,  title: response.data.message,
                    titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                    icon: 'fa fa-user-check',   
                    iconColor: 'white',});
                }
            }) 
        }, true],
        ['<button>NO</button>', (instance: any, toast: any) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        }, event],
        ],
    });    
}

}
