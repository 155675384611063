import {ApiConfigService} from './apiconfig';

const API = new ApiConfigService();

// PRODUCTS
export function GetAllProducts() {
    return API.$Request().get('/products');
}
export function getProduct(productid: string) {
    return API.$Request().get(`/products/view/${productid}`);
}
export function updateProduct(product: any, productid: string) {
    return API.$Request().post(`/products/update/${productid}`, product);
}
export function createProduct(product: any) {
    return API.$Request().post('/products/add-product', product);
}
export function deleteProduct(productid: string) {
    return API.$Request().delete(`/products/delete/${productid}`);
}

// PRODUCT MODULES
export function getProductModules(){
    return API.$Request().get ('/product-modules');
}
export function createProductModules(productModule:any,){
    return API.$Request().post ('/product-modules/add-module', productModule);
}
export function updateProductModules(productModule: any, productModuleid: string) {
    return API.$Request().post(`/product-modules/update/${productModuleid}`, productModule);
}
export function deleteProductModule(productModuleid: string) {
    return API.$Request().delete(`/product-modules/delete/${productModuleid}`);
}

// STAGES
export function getStage(){
    return API.$Request().get ('/stages');
}
export function createStage(stage: any){
    return API.$Request().post ('/stages/add-stage', stage);
}
export function updateStage( stage: any, stageId: string ){
    return API.$Request().post(`/stages/update/${stageId}`, stage);
}
export function deleteStage(stageid:string){
    return API.$Request().delete(`/stages/delete/${stageid}`);
}