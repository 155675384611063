
import {defineComponent, PropType} from 'vue';
import emitter from "@/utils/emitter";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

interface ConfigProps {
  title: string,
  list: Record<string, never>[],
}

const Configtemplate = defineComponent({
  components: {
    PulseLoader
  },
  props: {
    pageData: {
      type: Object as PropType<ConfigProps>,
      required: true
    },
    hasUpdated : {
      type: Boolean,
      required : true
    },
    editedInput : {
      type: String,
      required : true
    }
  },
  emits: ['additionEmit', 'updateEmit', 'deleteEmit'],
  data() {
    return {
      inputData: '',
      editId: '',
      isUpdating: this.$props.hasUpdated,
      editedName: this.$props.editedInput,
    }
  },
  methods: {
    editData: function (name : string, index : number) {
      this.editedName = name;
      this.editId = this.$props.pageData.list[index].id;
    },
    handleSave: function (btnType : string) {
      if(btnType === 'edit' && this.editId && this.editedName) {
        this.isUpdating = true;
        emitter.emit('updateEmit', {dataId : this.editId, dataUpdate: {name : this.editedName}})
      } else if(btnType === 'add' && this.inputData){
        emitter.emit('additionEmit', {name : this.inputData});
        this.inputData = '';
      }
    },
    cancelSave: function() {
      this.inputData = '';
      this.editId = '';
    }
  },
  watch: {
    hasUpdated(newValue) {
      this.isUpdating = newValue;
    },
    editedInput(newValue) {
      this.editedName = newValue;
    }
  }
});

export default Configtemplate;
