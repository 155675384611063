<template>
<loading :active="isLoading"  :can-cancel="true"   :is-full-page="true"></loading>
    <div class="content container-fluid">
				
    <!-- Page Header -->
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <!-- <h3 class="page-title">About this person</h3> -->
                <ul class="breadcrumb">
                    <li class="breadcrumb-item"><a>People</a></li>
                    <li class="breadcrumb-item active">Person</li>
                </ul>
            </div>
        </div>
    </div>


<!-- <div class="col-sm-12"> -->
    <div class="row">
        <div class="col-lg-8">
            <div class="card">
                <div class="card-header px-2">
                    <div class="container-fluid">
                        <div class="d-flex align-items-start">
                            <div class="profile-img d-flex align-items-center justify-content-center rounded-circle bg-secondary profile-box">
                                <h3 class="text-white m-0 p-0">{{ PersonData?.name.split(' ').map(i => i.slice(0, 1)).join('') }}</h3>
                            </div>
                            <div class="profile-details px-3">
                                <h3 class="profile-name fw-light">
                                    {{ PersonData?.name }}
                                </h3>
                                <!-- <p class="work-title">
                                    Title
                                </p> -->
                                <p class="email">
                                    {{ PersonData?.email }}
                                </p>
                            </div>
                            <!-- <div class="btn-toolbar" style="margin-left: auto">
                                <div class="btn-group btn-group-sm">
                                    <button type="button" class="btn btn-outline-secondary">Expand All</button>
                                    <button type="button" class="btn btn-outline-secondary">Collapse All</button>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="actions d-flex justify-content-center">
                            <!-- Note -->
                            <div class="action m-3 d-flex justify-content-center align-items-center flex-column">
                                <div class="contacts-icon">
                                    <i class="fas fa-edit"></i>
                                </div>
                                <small>Note</small>
                            </div>
                            <!-- Email -->
                            <div class="action m-3 d-flex justify-content-center align-items-center flex-column">
                                <div class="contacts-icon">
                                    <i class="fas fa-envelope"></i>
                                </div>
                                <small>Email</small>
                            </div>
                            <!-- Call -->
                            <div class="action m-3 d-flex justify-content-center align-items-center flex-column">
                                <div class="contacts-icon">
                                    <i class="fas fa-phone-alt"></i>
                                </div>
                                <small>Call</small>
                            </div>
                            <!-- Log -->
                            <div class="action m-3 d-flex justify-content-center align-items-center flex-column">
                                <div class="contacts-icon">
                                    <i class="fas fa-plus"></i>
                                </div>
                                <small>Log</small>
                            </div>
                            <!-- Task -->
                            <div class="action m-3 d-flex justify-content-center align-items-center flex-column">
                                <div class="contacts-icon">
                                    <i class="fas fa-calendar"></i>
                                </div>
                                <small>Task</small>
                            </div>
                            <!-- Meet -->
                            <div class="action m-3 d-flex justify-content-center align-items-center flex-column">
                                <div class="contacts-icon">
                                    <i class="fas fa-calendar-alt"></i>
                                </div>
                                <small>Meet</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="container-fluid">
                        <h5 class="my-2 text-dark">Contact Information</h5>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="my-4">
                                    <p class="text-dark">Email</p>
                                    <p class="text-muted">{{ PersonData?.email }}</p>
                                </div>
                                <div class="my-2">
                                    <p class="text-dark">Phone Number</p>
                                    <p class="text-muted">{{ PersonData?.phone }}</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="my-4">
                                    <p class="text-dark">Job Title</p>
                                    <p class="text-muted">{{ PersonData?.job_title }}</p>
                                </div>
                                <div class="my-2">
                                    <p class="text-dark">Last Contacted</p>
                                    <p class="text-muted">{{ PersonData?.updated_at.split('T').slice(0, 1).join('') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="card bg-white shadow-none">
                    <div class="card-body">
                        <ul class="nav nav-tabs nav-tabs-bottom">
                            <li class="nav-item"><a class="nav-link active" href="#bottom-tab1" data-toggle="tab">Activity</a></li>
                            <li class="nav-item"><a class="nav-link" href="#bottom-tab2" data-toggle="tab">Notes</a></li>
                            <li class="nav-item"><a class="nav-link" href="#bottom-tab3" data-toggle="tab">Emails</a></li>
                            <li class="nav-item"><a class="nav-link" href="#bottom-tab4" data-toggle="tab">Calls</a></li>
                            <li class="nav-item"><a class="nav-link" href="#bottom-tab5" data-toggle="tab">Tasks</a></li>
                            <li class="nav-item"><a class="nav-link" href="#bottom-tab6" data-toggle="tab">Meetings</a></li>
                        </ul>

                        <div class="tab-content">
                            <div class="tab-pane show active" id="bottom-tab1">
                                Activity Content
                            </div>
                            <div class="tab-pane" id="bottom-tab2">
                                <div class="row justify-content-end mb-4">
                                    <a  data-toggle="modal" data-target="#noteModal" type="button" class="btn btn-primary w-xs">
                                        <i class="fas fa-plus"></i> <small >Create Note</small>
                                    </a>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="card" v-for="data, index in personNotes" :key="index">
                                            <div class="card-body " style="background-color: #c7c9bb; border-radius:5px;">
                                                <span  v-html="data.note"></span>
                                                <div class="float-right btn-group dropup">
                                                    <a @click="setNoteId(data.id)" class=" m-2" data-toggle="modal" data-target="#noteModalEdit" style="cursor : pointer;">
                                                        <i class="fa fa-edit"> </i>
                                                    </a>
                                                    <a @click="removeNote(data.id)" class=" m-2 text-danger" href="#" style="cursor : pointer;">
                                                        <i class="fa fa-trash"> </i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                           <div v-if="personNotes.length == 0" class="mt-4 text-center">
                           <span colspan="5" class="mt-5 justify-around"> 
                             No Recorded Notes Yet, <span style="cursor:pointer;" class="text-success" data-toggle="modal" data-target="#noteModal"> Create a Note <i class="fa fa-sticky-note"> </i> </span>
                           </span>
                      </div>
                                        <!-- <table id="datatable" class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px; width: 100%;" role="grid" aria-describedby="datatable_info">
                                    <thead>
                                        <tr role="row">
                                            <th class="sorting_asc" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 163.2px;" aria-sort="ascending" aria-label="Task Title: activate to sort column descending">
                                                Note
                                            </th>
                                            <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 118.2px;" aria-label="Task Owner: activate to sort column ascending">
                                                Note Source
                                            </th>
                                            <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 102.2px;" aria-label="Status: activate to sort column ascending">
                                                Source Name
                                            </th>
                                            <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 20.2px;" aria-label=": activate to sort column ascending"></th>
                                        </tr>
                                    </thead>
                                    <tbody>    
                                    <tr v-for="data, index in personNotes" :key="index" role="row" class="odd">
                                        <td class="sorting_1 dtr-control">{{data.note}}</td>
                                        <td>{{data.note_source}}</td>
                                        <td>{{data.company.name}}</td>
                                        <td>
                                            <div class="btn-group dropup">
                                                <a class="font-size-18 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                                </a>
                                                <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                                <router-link :to="{name : 'view_company'}" class="dropdown-item text-info" style="cursor : pointer;"><i class="fa fa-eye"></i>
                                                    View
                                                </router-link>
                                                <a @click="setNoteEdit(data, index)" class="dropdown-item" data-toggle="modal" data-target="#noteModal" style="cursor : pointer;">
                                                    <i class="fa fa-edit"> </i>
                                                    Edit
                                                </a>
                                                <a @click="removeNote(data.id)" class="dropdown-item text-danger" href="#" style="cursor : pointer;">
                                                    <i class="fa fa-trash"> </i>
                                                    Delete
                                                </a>
                                                </div>
                                            </div>
                                        </td>
                                </tr>
                                    </tbody>
                                </table> -->
                                    </div>
                                </div>
                                <!-- <div class="text-center p-2" v-if="personNotes.length === 0">
                                    No Notes for {{PersonData.name}}
                                </div> -->
                            </div>
                            <div class="tab-pane" id="bottom-tab3">
                                <p>Send Emails to a contact from this record</p>
                            </div>
                            <div class="tab-pane" id="bottom-tab4">
                                Calls Content
                            </div>
                            <div class="tab-pane" id="bottom-tab5">
                                <div class="row justify-content-end">
                                    <a  data-toggle="modal" data-target="#modalTask" type="button" class="btn btn-outline-primary w-xs">
                                        <i class="fas fa-plus"></i> <small >Create Task</small>
                                    </a>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <table id="datatable" class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px; width: 100%;" role="grid" aria-describedby="datatable_info">
                                    <thead>
                                        <tr role="row">
                                            <th class="sorting_asc" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 97.2px;" aria-sort="ascending">
                                                Task Title
                                            </th>
                                            <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 157.2px;">
                                                Task Owner
                                            </th>
                                            <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 63.2px;">
                                                Status
                                            </th>
                                            <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 96.2px;">
                                                Priority
                                            </th>
                                            <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 96.2px;">
                                               Start Date
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>    
                                    <tr v-for="task in PersonTasks" :key="task.id" role="row" class="odd">
                                        <td class="sorting_1 dtr-control">{{task.task_title}}</td>
                                        <td>{{task.task_owner.first_name}} {{task.task_owner.last_name}}</td>
                                        <td>{{task.status}}</td>
                                        <td>{{task.priority}}</td>
                                        <td>{{task.start_date}}</td>
                                        <td>
                                             <div class="btn-group dropup">
                                                <a class="font-size-18 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                                </a>
                                                <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                                    <a href="javascript:void(0); " data-toggle="modal" data-target="#viewTaskModal" class="dropdown-item"> 
                                                        <i class="fa fa-eye" data-v-f0ceeb32=""></i> View
                                                    </a>
                                                    <a @click="setTaskID(task.id, task)"  href="javascript:void(0); " data-toggle="modal" data-target="#updateTaskModal" class="dropdown-item"> 
                                                        <i class="fa fa-edit" data-v-f0ceeb32=""></i>Edit
                                                    </a>
                                                    <a @click="removeTask(task.id)" class="dropdown-item text-danger" href="#">
                                                        <i class="fa fa-trash" data-v-f0ceeb32=""></i>Delete
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                    </div>
                                </div>
                                <div class="text-center p-2" v-if="PersonTasks.length === 0">
                                    No tasks for {{PersonData.name}}
                                </div>
                            </div>
                            <div class="tab-pane" id="bottom-tab6">
                                Meetings Content
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <!-- Side Panel -->
        <div class="col-md-4">
            <div class="card" style="min-height: 50%">
                <div class="card-body px-0">
                <div class="accordion" id="accordionSideBar">
                    <div class="shadow-none border border-left-0 border-right-0 border-light">
                        <div id="option1">
                        <h2 class="mb-0">
                            <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                <i class="fa fa-angle-right"></i>
                                Companies
                            </button>
                        </h2>
                        </div>

                        <div id="collapse1" class="collapse p-3" aria-labelledby="option1" data-parent="#accordionSideBar">
                            <p>
                                {{PersonData?.company?.name}}
                            </p>
                        </div>
                    </div>
                    <div class="shadow-none border border-left-0 border-right-0 border-light">
                        <div id="option2">
                        <h2 class="mb-0">
                            <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                                <i class="fa fa-angle-right"></i>
                                Opportunities
                            </button>
                        </h2>
                        </div>

                        <div id="collapse2" class="collapse p-3" aria-labelledby="option2" data-parent="#accordionSideBar">
                            <p>
                                Some info for Opportunities.
                            </p>
                        </div>
                    </div>
                    <div class="shadow-none border border-left-0 border-right-0 border-light">
                        <div id="option3">
                        <h2 class="mb-0">
                            <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                                <i class="fa fa-angle-right"></i>
                                Issues
                            </button>
                        </h2>
                        </div>

                        <div id="collapse3" class="collapse p-3" aria-labelledby="option3" data-parent="#accordionSideBar">
                            <p>
                                Some info for issues.
                            </p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->

    <!-- Add Task Modal -->
    <div class="modal right fade" id="modalTask"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title mt-0" id="myModalLabel">Task</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                
                <form @submit.prevent="createTasks()">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Company*</label>
                                    <div>
                                        <select class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="28" tabindex="-1" aria-hidden="true" v-model="createdTask.company_id">
                                            <option v-for="(company, index) in Companies" :key="index" :value="company.name">{{ company.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Task Title*</label>
                                    <input type="text" class="form-control" v-model="createdTask.task_title" placeholder="Enter Task Title">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Task Type*</label>
                                    <div>
                                        <select class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="34" tabindex="-1" aria-hidden="true" v-model="createdTask.task_type_id">
                                            <option v-for="({type}, index) in TaskType" :key="index" :value="type">{{type}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Start Date*</label>
                                    <div>
                                        <input v-model="createdTask.start_date" class="form-control" type="date" id="example-date-input">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Task Priority <span style="color : red;">*</span></label>
                                    <select required v-model.trim="createdTask.priority" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                                        <option value="" disabled >--Choose an option--</option>
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Status <span style="color : red;">*</span></label>
                                    <select required v-model.trim="createdTask.status" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                                        <option value="" disabled >--Choose an option--</option>
                                        <option value="Not Started">Not Started</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="On Hold">On Hold</option>
                                        <option value="Abandoned">Abandoned</option>
                                        <option value="Completed">Completed</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Task Owner*</label>
                                    <div>
                                        <select v-model="createdTask.task_owner" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="37" tabindex="-1" aria-hidden="true">
                                            <option v-for="(user, index) in Users" :key="index" :value="user.first_name">{{user.first_name}} {{user.last_name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Task Description*</label>
                            <div>
                                <textarea v-model="createdTask.task_description" class="form-control" rows="4" placeholder="Enter Task Description"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light waves-effect" data-dismiss="modal">Discard</button>
                        <button type="submit"  class="btn btn-primary waves-effect waves-light">Save</button>
                    </div>
                </form>
            </div><!-- /.modal-content -->
        </div><!-- modal-dialog -->
    </div>

    <!-- update task modal -->
    <div class="modal right fade" id="updateTaskModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title mt-0" id="myModalLabel">Task</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                <form @submit.prevent="editTask()">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Company*</label>
                                    <div>
                                        <select class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="28" tabindex="-1" aria-hidden="true" v-model="createdTask.company_id">
                                            <option v-for="(company, index) in Companies" :key="index" :value="company.name">{{ company.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Task Title*</label>
                                    <input type="text" class="form-control" v-model.trim="createdTask.task_title" placeholder="Enter Task Title">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Task Type*</label>
                                    <div>
                                        <select class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="34" tabindex="-1" aria-hidden="true" v-model="createdTask.task_type_id">
                                            <option v-for="({type}, index) in TaskType" :key="index" :value="type">{{type}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Start Date*</label>
                                    <div>
                                        <input v-model.trim="createdTask.start_date" class="form-control" type="date" id="example-date-input">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Task Priority <span style="color : red;">*</span></label>
                                    <select required v-model.trim="createdTask.priority" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                                    <option value="" disabled >--Choose an option--</option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Status <span style="color : red;">*</span></label>
                                    <select required v-model.trim="createdTask.status" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                                        <option value="" disabled >--Choose an option--</option>
                                        <option value="Not Started">Not Started</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="On Hold">On Hold</option>
                                        <option value="Abandoned">Abandoned</option>
                                        <option value="Completed">Completed</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Task Owner*</label>
                                    <div>
                                        <select v-model="createdTask.task_owner" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="37" tabindex="-1" aria-hidden="true">
                                            <option v-for="(user, index) in Users" :key="index" :value="user.first_name">{{user.first_name}} {{user.last_name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Task Description*</label>
                            <div>
                                <textarea v-model="createdTask.task_description" class="form-control" rows="4" placeholder="Enter Task Description"></textarea>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light waves-effect" data-dismiss="modal">Discard</button>
                            <button type="submit" class="btn btn-primary waves-effect waves-light">Update</button>
                        </div>
                    </div> 
                </form>
            </div><!-- /.modal-content -->
        </div><!-- modal-dialog -->
    </div>

    <!-- View Task Modal -->
    <div class="modal right fade" id="viewTaskModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="myModalLabel">Task</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div class="card">
                    <div class="card-header px-2">
                        <div class="container-fluid">
                            <div class="d-flex align-items-start">
                                <div class="profile-img d-flex align-items-center justify-content-center rounded-circle bg-secondary profile-box">
                                    <h3 class="text-white m-0 p-0">{{ PersonData?.name.split(' ').map(i => i.slice(0, 1)).join('') }}</h3>
                                </div>
                                <div class="profile-details px-3">
                                    <h3 class="profile-name fw-light">
                                        {{ PersonData?.name }}
                                    </h3>
                                    <p class="email">
                                        {{ PersonData?.email }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="container-fluid">
                            <h5 class="my-2 text-dark">Task Details</h5>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="my-4">
                                        <p class="text-dark">Task Source</p>
                                        <p class="text-muted">{{ PersonData?.name }}</p>
                                    </div>
                                    <div class="my-2">
                                        <p class="text-dark">Task Title</p>
                                        <p class="text-muted">{{ createdTask?.task_title }}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="my-4">
                                        <p class="text-dark">Task Owner</p>
                                        <p class="text-muted">{{ createdTask?.task_owner}}</p>
                                    </div>
                                    <div class="my-2">
                                        <p class="text-dark">Last Contacted</p>
                                        <p class="text-muted">{{ PersonData?.updated_at.split('T').slice(0, 1).join('') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Task Priority <span style="color : red;">*</span></label>
                                <select required v-model.trim="createdTask.priority" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                                <option value="" disabled >--Choose an option--</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Status <span style="color : red;">*</span></label>
                                <select required v-model.trim="createdTask.status" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                                    <option value="" disabled >--Choose an option--</option>
                                    <option value="Not Started">Not Started</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="On Hold">On Hold</option>
                                    <option value="Abandoned">Abandoned</option>
                                    <option value="Completed">Completed</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- modal-dialog -->
    </div>

    <!--Add Note Modal  -->
    <div class="modal right fade" id="noteModal" data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="background-color: #eeeeee;">
        <span @click="clearNoteData"  style="cursor: pointer;" class="closeModal"> </span>

            <div class="modal-header mb-0" style="background-color: #eeeeee;">
                <div class=" ml-4 mr-0 mt-3">
                    <h5>Note</h5>
                </div>
            </div>

          <form @submit.prevent="saveNoteData">
            <div class="modal-body" data-select2-id="59">
                <div class="row">

                </div>
                <div class="form-group">
                    <label>Note Description <span style="color : red;">*</span></label>
                    <div>
                        <!-- <textarea required v-model.trim="createdNote.note_description" class="form-control" rows="3" placeholder="Enter Description"></textarea> -->
                         <vue-editor v-model="createdNote.note_description" aria-placeholder="Enter Content Here"></vue-editor>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button @click="clearNoteData" type="button" class="btn btn-light waves-effect">Discard</button>
                <button type="submit" class="btn btn-primary waves-effect waves-light">Save</button>
            </div>
          </form>
        </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!--Edit Note Modal  -->
    <div class="modal right fade" id="noteModalEdit" data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="background-color: #eeeeee;">
        <span @click="clearNoteData"  style="cursor: pointer;" class="closeModal"> </span>

            <div class="modal-header mb-0" style="background-color: #eeeeee;">
                    <div class=" ml-4 mr-0 mt-3">
                <h5>Note</h5>
                </div>
            </div>

          <form @submit.prevent="editNoteData">
            <div class="modal-body" data-select2-id="59">
                <div class="row">
                </div>
                <div class="form-group">
                    <label>Note Description <span style="color : red;">*</span></label>
                    <div>
                        <!-- <textarea required v-model.trim="createdNote.note_description" class="form-control" rows="3" placeholder="Enter Description"></textarea> -->
                         <vue-editor v-model="createdNote.note_description" aria-placeholder="Enter Content Here"></vue-editor>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button @click="clearNoteData" type="button" class="btn btn-light waves-effect">Discard</button>
                <button type="submit" class="btn btn-primary waves-effect waves-light">Update</button>
            </div>
          </form>
        </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    </div>
</template>


<script lang="ts">
import {Vue, Options} from 'vue-class-component';
import {getTaskType, getTasks, addTask, updateTask, deleteTask} from '../store/api/tasks';
import {getCompanies} from '../store/api/people';
import {addNote, deleteNote, getNotes, NoteData, updateNote} from "@/store/api/notesApi";
import {getUsers} from '../store/api/usermgt';
import * as feather from 'feather-icons';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import $ from "jquery";
import  { event } from "jquery";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { VueEditor } from "vue3-editor";

@Options({
    name: 'peopleInfoView',
    components :{
        Loading, VueEditor,
        PulseLoader,
    },
})



export default class People extends Vue{
    private createdTask: any = {
        company_id: '',
        opportunity_id: '',
        task_type_id: '',
        task_owner: '',
        task_title: '',
        start_date: '',
        end_date: '',
        task_description: '',
        task_source: '',
        source_id: '',
        priority: '',
        status: '',
    }

    private createdNote: any = {
        company_id: '',
        oppurtunity_id: '',
        note_description: ''
    }

    private taskID = '';
    private noteId = '';
    private taskDetails
    private PersonData;
    private PersonTasks: any = [];
    private Tasks : any = [];
    private TaskType : any = [];
    private CompanyNames = [];
    private Companies = []
    private Users = [];
    private UserNames: any = [];
    private personNotes: any = [];
    private isLoading = false;
    private loadingIconColor = '#00b8d0';

    created (): void {
        this.PersonData = this.$route.params;
        this.getTaskTypeName();
        this.getTasksDetails();
        this.getCompanies();
        this.getAllUsers();
        this.getAllNotes();
        console.log('found task', this.Tasks.find((task:any) => task.source_id === this.PersonData.id));
        
        console.log(this.PersonTasks, 'Tasks for person');
        feather.replace();
    }

    private getTasksDetails(){
        this.isLoading = true;
         getTasks().subscribe((response: any) =>{
            this.Tasks = response.data.tasks;
            this.PersonTasks = this.Tasks.filter((task: any) => task.source_id === this.PersonData.id);
            console.log(this.Tasks);
            this.isLoading = false;         
        })
    }

    private getTaskTypeName(){
        getTaskType().subscribe((response:any)=>{
            // console.log({response});
            this.TaskType=response.data.types
        })
    }

     private getCompanies(){
        getCompanies().subscribe((response:any)=>{
            this.Companies = response.data.companies;
            console.log({b: this.Companies, a: 'Companies'});
            if (response.data.companies) {
                this.CompanyNames = response.data.companies.map(company => company.name);
            }
        })
    }

    private getAllUsers(){
        getUsers().subscribe((response:any)=>{
            this.Users = response.data.data;
            console.log (this.Users, 'users')
            this.UserNames = this.Users.map((user:any) => `${user.first_name} ${user.last_name}`)
            console.log (this.UserNames, 'userNames')
        })
    }


    private createTasks(){
        let c: any = (this.Companies.filter((c: any) => c.name === this.createdTask.company_id))[0]
        let tt: any = this.TaskType.filter((t: any) => t.type === this.createdTask.task_type_id)[0]
        let user: any = this.Users.filter((u:any) => u.first_name === this.createdTask.task_owner)[0]
        const task = {
            company_id: c.id,
            opportunity_id: '',
            task_type_id: tt.id,
            task_owner: user.id,

            task_title: this.createdTask.task_title,
            start_date: this.createdTask.start_date,
            end_date: this.createdTask.end_date,
            task_description: this.createdTask.task_description,
            task_source: 'User',
            source_id: this.PersonData.id,
            priority: this.createdTask.priority,
            status: this.createdTask.status,
        }

        console.log(task, "tasks");
        
        this.isLoading = true;
        addTask(task).subscribe((response: any) => {
            console.log(response);
            if (response.data.status === "success") {
                console.log('person tasks', this.PersonTasks);
                this.isLoading = false;
                $('#modalTask').modal('hide');
                this.getTasksDetails()
                Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: 'Task created successfully.',
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }else if (response.data.status == "error") {
                console.log(response.data);   
                this.isLoading = false;
                Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: 'Error! Please try again',
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }
        })
    }


    private editTask(){
        let c: any = (this.Companies.filter((c: any) => c.name === this.createdTask.company_id))[0]
        let tt: any = this.TaskType.filter((t: any) => t.type === this.createdTask.task_type_id)[0]
        let user: any = this.Users.filter((u:any) => u.first_name === this.createdTask.task_owner)[0]
        const task = {
            company_id: c.id,
            opportunity_id: '',
            task_type_id: tt.id,
            task_owner: user.id,

            task_title: this.createdTask.task_title,
            start_date: this.createdTask.start_date,
            end_date: this.createdTask.end_date,
            task_description: this.createdTask.task_description,
            task_source: 'User',
            source_id: this.PersonData.id,
            priority: 'High',
            status: 'Not Started',
        }

        this.isLoading = true;
        updateTask(task, this.taskID).subscribe((response: any) => {
            if (response.data.status === 'success') {
                this.isLoading = false;
                $('#updateTaskModal').modal('hide');
                this.getTasksDetails()
                Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                    id: 'toastAuthy', zindex: 99,  title: 'Task updated successfully.',
                    titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                    icon: 'fa fa-check',
                    iconColor: 'white', });
            } else if (response.data.status == "error") {
                this.isLoading = false;
                Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: "Task could not be updated",
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }
            
        })
    }

    private setTaskID (id: string) {
        this.taskID = id;
    }

    private setNoteId (id: string) : void {
        this.noteId = id;
    }

    private removeTask (id: string) : void {
        Toast.question({
            timeout: 20000,
            close: false,
            overlay: false,
            id: 'question',
            zindex: 1000,
            title: '',
            message: 'Are you sure ?',
            position: 'center',
            buttons: [
                ['<button><b>YES</b></button>',  (instance, toast) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

                this.isLoading = true;
                deleteTask(id).subscribe((response: any) => {
                if (response.data.status === 'success') {
                    this.isLoading = false;
                    this.getTasksDetails()
                    Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                        id: 'toastAuthy', zindex: 99,  title: 'Task deleted!',
                        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',});
                    }else if(response.data.status=="error"){
                        this.isLoading = false;
                        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                        id: 'toastAuthy', zindex: 99,  title: response.data.message,
                        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',});
                    }
                }) 
            }, true],
            ['<button>NO</button>', (instance: any, toast: any) => {
                    instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, event],
            ],
        })
    }

    private getAllNotes () : void {
        this.isLoading = true;
        getNotes().subscribe((response: any) => {
            if (response.data.status === 'success') {
                this.personNotes = response.data.notes.filter((n:any) => n.source_id === this.PersonData.id);
                this.isLoading = false;
            }
        })
    }

    private saveNoteData () : void {
        const note = {
            company_id: this.PersonData.company_id,
            opportunity_id: '',
            note_source: 'User',
            source_id: this.PersonData.id,
            note: this.createdNote.note_description,
        }

        this.isLoading = true;
        addNote(note).subscribe((response: any) => {
            if (response.data.status === 'success') {
                this.isLoading = false;
                $('#noteModal').modal('hide');
                this.getAllNotes();
                Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: 'Note created successfully.',
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }else if (response.data.status == "error") {
                console.log(response.data);   
                this.isLoading = false;
                Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: 'Error! Please try again',
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }
        })
    }

    private editNoteData () : void {
        const note = {
            company_id: this.PersonData.company_id,
            opportunity_id: '',
            note_source: 'User',
            source_id: this.PersonData.id,
            note: this.createdNote.note_description,
        }

        this.isLoading = true;
        updateNote(this.noteId, note).subscribe((response: any) => {
            if (response.data.status === "success") {
                this.isLoading = false;
                $('#noteModaleEdit').modal('hide');
                this.getAllNotes();
                Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: 'Note updated successfully.',
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }else if (response.data.status == "error") {
                console.log(response.data);   
                this.isLoading = false;
                Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: 'Error updating Note! Please try again',
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }
        })
    }

    private removeNote (id: string) : void {
        Toast.question({
            timeout: 20000,
            close: false,
            overlay: false,
            id: 'question',
            zindex: 1000,
            title: '',
            message: 'Are you sure ?',
            position: 'center',
            buttons: [
                ['<button><b>YES</b></button>',  (instance, toast) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

                this.isLoading = true;
                deleteNote(id).subscribe((response: any) => {
                    if (response.data.status === 'success') {
                        this.isLoading = false;
                        this.getAllNotes()
                        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                        id: 'toastAuthy', zindex: 99,  title: 'Note deleted!',
                        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',});
                    }else if(response.data.status=="error"){
                        this.isLoading = false;
                        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                        id: 'toastAuthy', zindex: 99,  title: response.data.message,
                        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',});
                    }
                }) 
            }, true],
            ['<button>NO</button>', (instance: any, toast: any) => {
                    instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, event],
            ],
        })
    }


    private clearNoteData() {
        $('#noteModal').modal('hide');
        $('#noteModalEdit').modal('hide');
        this.createdNote = {
            company_id: '',
            oppurtunity_id: '',
            note_description: ''
        }  
    }

}
</script>

<style scoped>
    .profile-box{
        display: block;
        width: 50px;
        height: 50px;
    }

    .profile-box h3 {
        font-size: 120%;
    }

    .contacts-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid;
        display: flex;
        align-items: center;
        justify-content: center;
        padding : 10px;
        margin-bottom: 10px;
        background-color: #eaf0f6;
        border-color: #cbd6e2;
        color: #506e91;
        cursor: pointer;
    }
</style>