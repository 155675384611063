	
    <template>
  <div class="content container-fluid">
   <div class="main-wrapper login-body">
			<div class="login-wrapper">
				<div class="container">
				
					<img class="img-fluid logo-dark mb-2" src="../assets/img/logo.png" alt="Logo">
					<div class="loginbox">
						
						<div class="login-right">
							<div class="login-right-wrap">
								<h1>Login</h1>
								<!-- <p class="account-subtitle text-info"> NextCounsel</p> -->
								<loading v-model:active="isLoading"  :can-cancel="true"   :is-full-page="true"></loading> 
								<form >
									<div class="form-group">
										<label class="form-control-label">Email Address</label>
										<input type="email" v-model="email" class="form-control">
									</div>
									<div class="form-group">
										<label class="form-control-label">Password</label>
										<div class="pass-group">
											<input type="password" v-model="password" class="form-control pass-input">
											<span class="fas fa-eye toggle-password"></span>
										</div>
									</div>
									<div class="form-group">
										<div class="row">
											<div class="col-6">
												<div class="custom-control custom-checkbox">
													<input type="checkbox" class="custom-control-input" id="cb1">
													<label class="custom-control-label" for="cb1">Remember me</label>
												</div>
											</div>
											<!-- <div class="col-6 text-right">
												<a class="forgot-link" href="#">Forgot Password ?</a>
											</div> -->
										</div>
									</div>
									<button class="btn btn-lg btn-block btn-primary" @click.prevent="loginUser()">Login</button>
									<!-- <div class="login-or">
										<span class="or-line"></span>
										<span class="span-or">or</span>
									</div> -->
									<!-- Social Login -->
									<!-- <div class="social-login mb-3">
										<span>Login with</span>
										<a href="#" class="facebook"><i class="fab fa-facebook-f"></i></a><a href="#" class="google"><i class="fab fa-google"></i></a>
									</div> -->
									<!-- /Social Login -->
									<!-- <div class="text-center dont-have">Don't have an account yet? <a href="#">Register</a></div> -->
								</form>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {  userLogin } from '../store/api/usermgt';
import toast from '../components/toast';
@Options({
  components: {
		Loading
  },
})
export default class Login extends Vue {
private files = [];
private uploadUrl = '';
private email = '';
private password = '';
private isLoading = false;
private loadingIconColor = '#00b8d0';


private loginUser()  {
    const postData = { email: this.email, password: this.password };
          this.isLoading = true;
          if (this.email !== '' && this.password) {
          userLogin(postData)
          .then(res => {
                if (res.status == "success") {
				toast.success('', 'Login Successful',5000 )
                console.log('then block', res)
                 this.isLoading = false;
				this.$router.push('/app');
                  }
                  else {
                     this.isLoading = false;
					toast.error('', 'Login Failed',5000 )
                     console.log('observed untruthtiness in then block', res);
                    // const alertComp: any = document.getElementById('alertDiv');
                    // this.alertText = 'Invalid Credentials';
                    // alertComp.classList.remove("hide-div");
                    // alertComp.classList.add("badge-danger");
                    // setTimeout(() => {
                    //   alertComp.classList.add("hide-div" );
                    //   alertComp.classList.remove("badge-warning", "badge-danger", "badge-success");
                    // }, 3000)
                   }
            
           
          })
          .catch(err => {
            console.log('then catched', Object.keys(err), err.message)

          })
          } else {
                  toast.error('', 'Enter valid credentials Error',5000 )
                    this.isLoading = false;
                  }
}
 
}
</script>


<style scoped>
.req:after {
    content:" *";
    color: red;
  }
 .dropzone.dz-clickable {
    cursor: pointer;
}

.dropzone {
    min-height: 230px;
    border: 2px dashed #ced4da;
    background: #fff;
    border-radius: 6px;
} 
.dropzone {
    min-height: 150px;
    border: 2px dashed rgba(0,0,0,0.3);
    background: white;
    padding: 20px 20px;
}
.dropzone, .dropzone * {
    box-sizing: border-box;
}
</style>
