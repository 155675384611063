<template>
	<loading v-model:active="isLoading"  :can-cancel="true"   :is-full-page="true"></loading> 
  <ConfigTemplate 
		:pageData="pageData"
		:editedInput="editedName"
		:hasUpdated="isUpdating"
		@deleteEmit="deleteIndustry"
	/>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import ConfigTemplate from "@/components/config-template.vue";
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {addIndustry, deleteIndustry, getIndustries, updateIndustry} from '@/store/api/industry';
import emitter from "@/utils/emitter";
import $, {event} from 'jquery';

@Options({
	components: {
		ConfigTemplate, 
		Loading
	}
})

export default class Industry extends Vue {
	private isLoading = false;
	private subscriptionList : any[] = []; // eslint-disable-line
	private industryList : Record<string, unknown>[] = [];
	private isUpdating = false;
	private editedName = '';
	private pageData = {
		title: 'Industry',
		list: this.industryList,
	}

	created() { //eslint-disable-line
		this.getIndustryList()
		emitter.on('updateEmit', (emitData : {dataId : string, dataUpdate : {name : string}}) => {
			console.log('New updateEmit detected', emitData);
			this.updateIndustry(emitData);
		})

		emitter.on('additionEmit', (emitData : {name : string}) => {
			console.log('New addEmit detected', emitData);
			this.addIndustry(emitData);
		})
		console.log('lifecycle hook is called');
	}

	beforeUnmount() : void { 
		this.subscriptionList.forEach((subscription) => subscription.unsubscribe())
		emitter.off('updateEmit');
		emitter.off('additionEmit');
		console.log('The unsubscription goes fine');
	}

	private getIndustryList() {
		this.isLoading = true;
		const getSubscription = getIndustries().subscribe((response) => {
			if(response && response.data.status === 'success') {
				console.log('Get Data was fetched successfuly', response);
				this.industryList = response.data.industries;
				this.pageData.list = this.industryList;
				console.log('Industry list value is: ', this.industryList);
			} else {
				Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-error',
					iconColor: 'white',
				});
				console.log('Error occured while fetching getIndustries', response);
			}

			this.isLoading = false;
		}, (error) => {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
				id: 'toastAuthy', zindex: 99,  title: `${error}`,
				titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
				icon: 'fa fa-error',
				iconColor: 'white',
			});
			console.log('Error occured while fetching data', error);
			this.isLoading = false;
		});
		this.subscriptionList.push(getSubscription);		
	}

	private addIndustry(newData : {name : string}) {
		this.isLoading = true;
		addIndustry(newData).subscribe((response) => {
			if(response.data.status === 'success') {
				console.log('Add Data was fetched successfuly', response);
				this.industryList.push(response.data.industry)
				this.pageData.list = this.industryList;
				Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-check',
					iconColor: 'white',
				});
			} else {
				console.log('Error occured while fetching data', response);
				Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-error',
					iconColor: 'white',
				});
			}

			this.isLoading = false;
		}, (error) => {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
				id: 'toastAuthy', zindex: 99,  title: `${error}`,
				titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
				icon: 'fa fa-error',
				iconColor: 'white',
			});
			console.log('Error occured while fetching data', error);
			this.isLoading = false;
		})
	}

	private updateIndustry(updateData : {dataId : string, dataUpdate : {name : string}}) {
		const {dataId, dataUpdate} = updateData;
		this.isUpdating = true;
		updateIndustry(dataId, dataUpdate).subscribe((response) => {
			if(response.data.status === 'success') {
				console.log('Data was updated successfuly', response);
				for (let i = 0; i < this.industryList.length; i++) {
					const element = this.industryList[i];
					if (element.id === dataId) {
						this.industryList.splice(i, 1, response.data.industry);
						this.pageData.list = this.industryList;
						break;
					}
				}
				$('#myModal').modal('hide');
				this.editedName = '';
				Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-check',
					iconColor: 'white',
				});
			} else {
				console.log('Error occured while fetching data', response);
				Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-error',
					iconColor: 'white',
				});
			}

			this.isUpdating = false;
		}, (error) => {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
				id: 'toastAuthy', zindex: 99,  title: `${error}`,
				titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
				icon: 'fa fa-error',
				iconColor: 'white',
			});
			console.log('Error occured while fetching data', error);
			this.isUpdating = false;
		});
	}

	private deleteIndustry(dataId : string) {
		Toast.question({
			timeout: 20000,
			close: false,
			overlay: false,
			id: 'question',
			zindex: 1000,
			title: '',
			message: 'Are you sure ?',
			position: "topRight",
			buttons: [
					['<button><b>YES</b></button>',  (instance, toast) => {
			instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
				this.isLoading = true;
				const getSubscription = deleteIndustry(dataId).subscribe((response) => {
					if(response.data.status === 'success') {
						console.log('Data was deleted successfuly', response);
						for (let i = 0; i < this.industryList.length; i++) {
							const element = this.industryList[i];
							if (element.id === dataId) {
								this.industryList.splice(i, 1);
								this.pageData.list = this.industryList;
								break;
							}
						}
						Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
							id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-check',
							iconColor: 'white',
						});
					} else {
						console.log('Error occured while fetching data', response);
						Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
							id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-error',
							iconColor: 'white',
						});
					}

					this.isLoading = false;
				}, (error) => {
					Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
						id: 'toastAuthy', zindex: 99,  title: `${error}`,
						titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
						icon: 'fa fa-error',
						iconColor: 'white',
					});
					console.log('Error occured while fetching data', error);
					this.isLoading = false;
				});
				this.subscriptionList.push(getSubscription);

			}, true],
			['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
							instance.hide({ transitionOut: 'fadeOut' }, toast, 'button'); 
				}, event],
			],
   
		});
	}
}
</script>
