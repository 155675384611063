<template>
  <loading v-model:active="isLoading"  :can-cancel="true"   :is-full-page="true"></loading> 
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="page-title">Roles & Permissions</h3>
          <ul class="breadcrumb">
            <li class="breadcrumb-item"><a>Settings</a></li>
            <li class="breadcrumb-item active">Roles & Permissions</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /Page Header -->
    <div>
      <h5 style="margin-bottom : 15px">Admin</h5>
      <div class="bs-example">
          <div class="accordion" id="accordionExample">
              <div v-for="role, index in rolesList" :key="index" class="card">
                  <div  data-toggle="collapse" :data-target="`#collapse${index+1}`" class="card-header accordion-div" :id="`heading${index+1}`" style="cursor : pointer; color : #007bff; padding : 15px 20px">
                      <h5 style="color : #007bff" class="mb-0">
                        <i class="fas fa-address-card" style="margin-right : 15px"></i>
                        {{role.name}} Role
                      </h5>
                    <span><i class="fas fa-angle-down fa-2x"></i></span>
                  </div>
                  <div :id="`collapse${index+1}`" class="collapse" :aria-labelledby="`#heading${index+1}`" data-parent="#accordionExample">
                      <div class="card-body">
                        <div class="roles-form">
                          <div style="display: flex; justify-content : space-between; align-items : center; border-bottom: 1px solid #f7f8f9; padding-right : 10px">
                            <h5 style="border-bottom : none">{{role.name}}</h5>
                            <div>
                              <a @click="editData(role.name, role.id, index)" href="javascript:void(0); " data-toggle="modal" data-target="#myModal" class="text-info" style="margin-right: 5px; cursor : pointer">
                                <i class="fa fa-edit"> </i>
																		
                              </a>
                              <a @click="deleteRole(role.id, index)" class="text-danger" style="margin-left: 5px; cursor : pointer">
                                <i class="fa fa-trash"> </i>
																		
                              </a>
                            </div>
                          </div>
                          <form @submit.prevent="handleSubmit(role.id, role.name)" class="container-fluid form-container" style="padding-bottom : 10px;">
                            <div class="row form-row">
                              <div v-for="item, index in permissionsList" 
                                :key="index"  class="col-sm-3 checkbox-div"
                              >
                                <label>
                                  <input
                                    class="checkbox-input" type="checkbox" :value="item" 
                                    v-model="approvedList[`${role.id}`]"
                                  />
                                  {{item}}
                                </label>
                              </div>
                            </div> 
                            <div class="form-group" style="margin-bottom : 10px; display: flex; justify-content: flex-end;">
                              <button class="btn btn-primary">Add Permissions</button>
                            </div>
                          </form>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="form-group" style="padding-top : 10px">
          <label>Add Role <span style="color : red">*</span></label>
          <div>
              <input v-model.trim="inputData" type="text" class="form-control" required="" placeholder="Enter Role Name">
          </div>
      </div>
      <div class="form-group mb-0" style="padding-bottom : 15px">
          <div class="text-right">
              <button @click="cancelSave" type="reset" class="btn btn-light waves-effect mr-1">
                  Cancel
              </button>
              <button @click="handleSave('add')" type="submit" class="btn btn-primary waves-effect waves-light">
                  Save
              </button>

          </div>
      </div>
    </div>
    <!-- Start Modal -->
    <div class="modal right fade" id="myModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
        <div class="modal-content" style="background-color: #eeeeee;">
        <span style="cursor: pointer;" class="closeModal" data-dismiss="modal"  > </span>

            <div class="modal-header mb-0" style="background-color: #eeeeee;">
                    <div class=" ml-4 mr-0 mt-3">
                <h5> Update Role</h5>
                </div>
            </div>
                <div class="modal-body">
                    <div  class="element-wrapper" >
                        <div class="element-box">			 						 
                <form @submit.prevent="editedName && handleSave('edit')" class="p-3">
                    <div class="form-group">
                        <label class="form-control-label">Role Name</label>
                        <input class="form-control" type="text" v-model.trim="editedName">
                    </div>
                    <div class="form-group mb-0">
                        <button class="btn btn-lg btn-block btn-primary" :disabled="isUpdating">
                            <pulse-loader v-if="isUpdating" :loading="isUpdating" color="white" size="10px" style="display: inline-block"></pulse-loader>
                            {{isUpdating ? 'Updating' : 'Update'}} 
                        </button>
                    </div>
                </form>
                        </div>
            </div>
            </div>

        </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { addPermissionsToRole, addRole, deleteRole, editRole, getPermissions, getRoles } from "@/store/api/rolesApi";
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import $, {event} from 'jquery';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

@Options({
  components: {
    Loading,
    PulseLoader
  }
})

export default class Roles extends Vue {
  private permissionsList : string[] = [];
  private rolesList : string[] = [];
  private isLoading = false;
  private inputData = '';
  private isUpdating = false;
  private editedName = '';
  private subscriptionList : any[] = []; //eslint-disable-line
  private approvedList = {} //eslint-disable-line
  private editId  = '';
  private editIndex = 0;

  created() { //eslint-disable-line
    this.getPermissionsList()
  }

  beforeUnmount() { //eslint-disable-line
    this.subscriptionList.forEach((subscription) => subscription.unsubscribe());
  }

  private editData(name, roleId, index) {
    this.editedName = name;
    this.editId = roleId;
    this.editIndex = index;
  }

  private handleSubmit(roleId : string, roleName : string) {
    console.log('Handle submit is called:', roleId, roleName);
    this.addRolePermissions(roleId, this.approvedList[`${roleId}`]);
  }

  private handleSave(btnType : string) {
    if(btnType === 'edit' && this.editId && this.editedName) {
      this.isUpdating = true;
      this.updateRole(this.editId, this.editedName, this.editIndex)
      console.log('Is updating? ', this.isUpdating);
    } else if(btnType === 'add' && this.inputData) {
      this.addRole(this.inputData);
    }
  }

  private cancelSave() {
    this.inputData = '';
    this.editId = '';
    this.editIndex = 0;
  }

  private getPermissionsList() {
    this.isLoading = true;
    const getSubscription = getPermissions().subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('Get permissions is fetched successfully');
        const responseData = response.data.permissions as Record<string, string>[];
        const listData = responseData.map((data) => data.name)
        this.permissionsList = listData;
        console.log('The permissions list value is: ', listData);
        this.getRoles();
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', response.message);
      }

    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Fetch Permissions error: ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
      this.isLoading = false;
    });
    this.subscriptionList.push(getSubscription);
  }

  private getRoles() {
    this.isLoading = true;
    const getSubscription = getRoles().subscribe((response) => {
      if(response && response.data.status === 'success') {
        console.log('Get Data was fetched successfuly', response);
        let updatedList : string[] = [];
        for (let i = 0; i < response.data.roles.length; i++) {
          const element = response.data.roles[i];
          if(element.name === 'Admin') {
            continue;
          } else {
            updatedList.push(element);
            const permissionsList = element.permissions.map((permission) => {
              return permission.name;
            })
            console.log('Permissions list is: ', permissionsList);
            this.approvedList[`${element.id}`] = permissionsList ? permissionsList : [];
          }
        }
        this.rolesList = updatedList;
        
        console.log('Roles list value is: ', this.rolesList);
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching getIndustries', response);
      }

      this.isLoading = false;
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
      this.isLoading = false;
    });
    this.subscriptionList.push(getSubscription);
  }
  
  private addRole(name : string) {
    this.isLoading = true;
    console.log(name);
    const getSubscription = addRole({name}).subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Add Data was fetched successfuly', response);
        this.rolesList.push(response.data.role)
        this.approvedList[`${response.data.role.id}`] = [];
        this.inputData = '';
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        console.log('Error occured while fetching data', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }

      this.isLoading = false;
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
      this.isLoading = false;
    });
    this.subscriptionList.push(getSubscription);
  }

  private updateRole(roleId : string, newInput : string, index : number) {
    const getSubscription = editRole(roleId, {name : newInput}).subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Role was updated successfuly', response);
        this.rolesList.splice(index, 1, response.data.role);
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
        $('#myModal').modal('hide');
        this.isUpdating = false;
        this.editedName = '';
        this.editId = '';
      } else {
        console.log('Error occured while fetching data', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
      this.isUpdating = false;
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
      this.isUpdating = false;
    });
    this.subscriptionList.push(getSubscription);
  }

  private deleteRole(roleId : string, index : number) {
    Toast.question({
      timeout: 20000,
      close: false,
      overlay: false,
      id: 'question',
      zindex: 1000,
      title: '',
      message: 'Are you sure ?',
      position: "topRight",
      buttons: [
          ['<button><b>YES</b></button>',  (instance, toast) => {
      instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        this.isLoading = true;
        const getSubscription = deleteRole(roleId).subscribe((response) => {
          if(response.data.status === 'success') {
            console.log('Role was deleted successfuly', response);
            this.rolesList.splice(index, 1);
            Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-check',
              iconColor: 'white',
            });
          } else {
            console.log('Error occured while fetching data', response);
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-error',
              iconColor: 'white',
            });
          }

          this.isLoading = false;
        }, (error) => {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${error}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', error);
          this.isLoading = false;
        });
        this.subscriptionList.push(getSubscription);
      }, true],
      ['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
         }, event],
      ],
   
    });
  }

  private addRolePermissions(role : string, permissions : string[]) {
    this.isLoading = true;
    console.log('The permisions and role Id are', role, permissions);
    const getSubscription = addPermissionsToRole(role, permissions).subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Permissions were added successfuly', response);
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        console.log('Error occured while adding permissions to role', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }

      this.isLoading = false;
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
      this.isLoading = false;
    });
    this.subscriptionList.push(getSubscription);

  }
}
</script>

<style scoped>
  h5 {
    padding: 10px;
    background: white;
    margin: 0px;
    border-radius: 5px 5px 0px 0px ;
  }

  .roles-form {
    margin-top: 10px;
    background-color: white;
  }

  .roles-form h5 {
    border-radius: 0px;
    border-bottom: 1px solid #f7f8f9;
  }

  .form-row {
    margin: 0px;
  }

  .form-container {
    padding-top: 10px;
  }

  .checkbox-div {
    padding: 0px 7px;
  }

  .checkbox-div label {
    display: flex;
    align-items: baseline;
  }

  .checkbox-input {
    margin-right: 5px;
  }

  .closeModal {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .closeModal:hover {
    opacity: 1;
  }
  .closeModal:before, .closeModal:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .closeModal:before {
    transform: rotate(45deg);
  }
  .closeModal:after {
    transform: rotate(-45deg);
  }

  .accordion-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
