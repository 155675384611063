
import {Options, Vue} from 'vue-class-component'
import {updateStage, getStage, createStage, deleteStage} from '../store/api/configsettings'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import $ from 'jquery'
import { event } from 'jquery';
import { VueDraggableNext } from 'vue-draggable-next'

@Options({
    components: {
		Loading,
        PulseLoader,
        draggable: VueDraggableNext,
  },
})
export default class Stages extends Vue{
private stagesArray= [];
private newStage = '';
private isLoading = false;
private editedName = '';
private editedID = '';
private isUpdating = false;
private drag = false;
private enabled = true;

    created() {
       this.getStages(); 
    }

    private onDrag (event) {
        let dragged = event.draggedContext.element.name;
        return (
            dragged !== "New" && 
            dragged !== "Won" &&
            dragged !== "Lost" 
            );
    }

private getStages(){
        this.isLoading =true;
    getStage().subscribe((response : any) =>{
        this.stagesArray= response.data.stages;
        this.isLoading = false;      
   })
 }

 private addStages(stageName: string){
     const newStageData = {
         name:stageName
     }
        this.isLoading= true;
     createStage(newStageData).subscribe((response : any)=>{
         if(response.data.status == "success"){
             this.isLoading = false;
             this.newStage = '';
             this.getStages();
             Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: response.data.message,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
         }else if(response.data.status=="error"){
                this.isLoading = false;
             Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: response.data.message,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
         }
     })
 }


private setStageId(id:string, name: string){
    this.editedID = id;
    this.editedName = name;
}
private updateStageName(name: any, id: string){
    const editedStageName ={
        name: name
    }
        this.isUpdating = true;
    updateStage(editedStageName, id).subscribe((response : any) =>{
        if(response.data.status == 'success'){
            this.isUpdating = false;
            $('#myModal').modal('hide')
            this.editedName = "";
            this.getStages();
            Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: response.data.message,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
        } else if(response.data.status=="error"){
                this.isLoading = false;
             Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: response.data.message,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
        }
   })
 }

 private removeStage(id: string){
    Toast.question({
    timeout: 20000,
    close: false,
    overlay: false,
    id: 'question',
    zindex: 1000,
    title: '',
    message: 'Are you sure ?',
    position: 'center',
    buttons: [
        ['<button><b>YES</b></button>',  (instance, toast) => {
        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            this.isLoading = true;
            deleteStage(id).subscribe((response:any) =>{  
                if (response.data.status =="success"){
                    this.isLoading = false;
                    this.getStages();
                    Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                        id: 'toastAuthy', zindex: 99,  title: response.data.message,
                        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',});
                }else if(response.data.status=="error"){
                        this.isLoading = false;
                    Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                        id: 'toastAuthy', zindex: 99,  title: response.data.message,
                        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',});
                }
            })  
        }, true],
        ['<button>NO</button>', (instance: any, toast: any) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        }, event],
        ],
    });    
}

}
