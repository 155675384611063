
import { Options, Vue } from 'vue-class-component';

@Options({
  methods: {
		addNewRole: function () {
			this.$store.commit('addRole', this.newRole);
			this.newRole = ''
		}
	}
})

export default class UserGroup extends Vue {

    
}
