
import {Vue, Options} from 'vue-class-component';
import * as feather from 'feather-icons';
import {   getOpportunity, updateOpportunity, getCompanies, getOpportunities } from "@/store/api/opportunityApi";
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {addTasks, deleteTask, getTasks, getTaskTypes, getUsers, TaskData, updateTask} from "@/store/api/tasksApi";
import {addNote, deleteNote, getNotes, NoteData, updateNote} from "@/store/api/notesApi";
import $, {event} from "jquery";
import { VueEditor } from "vue3-editor";
import { Calendar, DatePicker } from 'v-calendar';

@Options({
    components :{ Loading, VueEditor, Calendar, DatePicker
    },
})


export default class OpportunityInfo extends Vue {
private oppData;
private dateInput = new Date();
private oppData2;
private isLoading = false;
private opportunityList : any[] = []; //eslint-disable-line
private companyList : any[] = []; //eslint-disable-line
private usersList : any[] = []; //eslint-disable-line
private taskTypes : any[] = []; //eslint-disable-line

  private editId = "";
  private editIndex = -1;
  private opportunity = "";
  private company = "";
  private taskOwner = "";
  private taskType = "";

  private noteEditId = "";
  private noteEditIndex = -1;
  private noteOpportunity = "";
  private noteCompany = "";

private oppTasks : Record<string, unknown>[] = [];
private oppNotes : Record<string, unknown>[] = [];

private currentData : TaskData = {
    task_owner : "",
    company_id : "",
    task_type_id : "",
    opportunity_id : "",
    task_title: "",
    task_description : "",
    priority: "",
    status: "Not Started",
    start_date : "",
    end_date : "",
    source_id : "",
    task_source : "Opportunity",
  };

  private noteData : NoteData = {
    note : "",
    company_id : "",
    opportunity_id : "",
    source_id : "",
    note_source : "Opportunity",
  }

private subscriptionList : any[] = [];
 private date = new Date();
 private year = this.date.getFullYear();
 private month = this.date.getMonth();
private attrs = [
  
      {
        key: 'today',
        highlight: {
          color: 'purple',
          fillMode: 'solid',
          contentClass: 'italic',
        },
        dates: new Date(this.year, this.month, 12),
      },
      {
        highlight: {
          color: 'purple',
          fillMode: 'light',
        },
        dates: new Date(this.year, this.month, 13),
      },
      {
        highlight: {
          color: 'purple',
          fillMode: 'outline',
        },
        dates: new Date(this.year, this.month, 14),
      },
    ];


    created():void {
        this.oppData = this.$route.params;
        this.getOpp(this.$route.params.id)
        feather.replace();
            this.getTasks();
            this.getTaskTypes();
            this.getCompanies();
            this.getOpportunities();
            this.getUsers();
            this.getNotes();
    }

    
    beforeUnmount() : void { 
      this.subscriptionList.forEach((subscription) => subscription.unsubscribe());
      // console.log('The unsubscription goes fine');
    }

private numberWithCommas(x: any) {
    if (!x) return 0;
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }


private setEdit(data, index) {
    const {task_title, task_description, task_owner, task_type_id, opportunity_id, 
      company_id, priority, status, start_date, end_date, source_id, task_source
    } = data;
    const editData = {task_title, task_description, task_owner : task_owner.id, task_type_id, opportunity_id,
      company_id, priority, status, start_date, end_date : new Date(end_date).toLocaleDateString(), source_id, task_source
    };
    this.currentData = editData;
    this.editId = data.id;
    this.editIndex = index;
    this.company = data.company.name || "";
    this.opportunity = data.opportunity.name || "";
    this.taskOwner = data.task_owner.first_name || "";
    this.taskType = data.task_type.type || ""
    console.log('The current edit is: ', data);
  }

  private setNoteEdit(data, index) {
    const {opportunity_id, company_id, note, source_id, note_source} = data;
    const editData = { opportunity_id, company_id, note, source_id, note_source};
    this.noteData = editData;
    this.noteEditId = data.id;
    this.noteEditIndex = index;
    this.noteCompany = data.company.name || "";
    this.noteOpportunity = data.opportunity.name || "";
    console.log('The current edit is: ', data);
  }

  private saveData2() {
    if(this.editId && this.currentData.task_title) {
      this._updateTask();
      // console.log('ciurr data UPPDATE', this.currentData);
    } else if(!this.editId && this.currentData.task_title) {
      this.currentData.start_date = new Date().toISOString();
      this.currentData.source_id = this.oppData.id;
      this.currentData.opportunity_id = this.oppData.id;
      this.currentData.company_id = this.oppData.company_id;

      this._addTask();
      // console.log('ciurr data ADD', this.currentData);
      
    }
  }

  private saveNoteData() {
    // this runs, if the data is an edited data.
    if(this.noteEditId && this.noteData.note) {
      
      this._updateNote();
    } else if(!this.noteEditId && this.noteData.note) {

      this.noteData.source_id = this.oppData.id;
      this.noteData.opportunity_id = this.oppData.id;
      this.noteData.company_id = this.oppData.company_id;

      this._addNote();
    }
  }


private getOpp(id: any){
      this.isLoading = true;
      const getSubscription = getOpportunity(id).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.oppData2 = response.data.opportunity;
          this.isLoading = false;
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

      private getCompanies() {
    const getSubscription = getCompanies().subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        this.companyList = response.data.companies;
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private getOpportunities() {
    const getSubscription = getOpportunities().subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        this.opportunityList = response.data.opportunity;
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', response.message);
      }
    });
    this.subscriptionList.push(getSubscription);
  }

  private getUsers() {
    const getSubscription = getUsers().subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('Get People is fetched successfully');
        this.usersList = response.data.users;
        console.log("The People list value is: ", this.usersList);
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private getTaskTypes() {
    const subscription = getTaskTypes().subscribe((response) => {
      if(response.data.status === 'success') {
        let responseData : Record<string, unknown>[] = response.data.types;
        this.taskTypes = responseData;
      } else {
        console.log('Error occured while fetching products', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });

    this.subscriptionList.push(subscription);
  }

  private getTasks() {
    const getSubscription = getTasks().subscribe((response) => {
      if(response.data.status === 'success') {
        let responseData : Record<string, unknown>[] = response.data.tasks;
        let taskList : any[] = []; //eslint-disable-line
        responseData.forEach((task) => {
          if(task.source_id === this.oppData.id) taskList.push(task);
        });
        this.oppTasks = taskList;
      } else {
        console.log('Error occured while fetching products', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });

    this.subscriptionList.push(getSubscription);
  }

    private _addTask() {
    this.isLoading = true;
    const subscription = addTasks(this.currentData).subscribe((response) => {
      if(response.data.status === 'success') {
        this.getTasks();
        this.isLoading = false;
        this.clearData();

        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        console.log('Error occured while adding task', response);
        this.isLoading = false;
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while adding data', error);
    });

    this.subscriptionList.push(subscription);
  }
    private _updateTask() {
    this.isLoading = true;
    const getSubscription =  updateTask(this.editId, this.currentData).subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        this.getTasks();
        // this.companyTasks.splice(this.editIndex, 1, response.data.company);
        this.isLoading = false;
        this.clearData();
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _deleteTask(taskId : string, index : number) {
    Toast.question({
      timeout: 20000,
      close: false,
      overlay: false,
      id: 'question',
      zindex: 1000,
      title: '',
      message: 'Are you sure ?',
      position: "topRight",
      buttons: [
          ['<button><b>YES</b></button>',  (instance, toast) => {
      instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        this.isLoading = true;
        const getSubscription = deleteTask(taskId).subscribe((response) => {
          if(response.data.status === 'success') {
            console.log('Company was deleted successfuly', response);
            this.oppTasks.splice(index, 1);
            Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-check',
              iconColor: 'white',
            });
          } else {
            console.log('Error occured while fetching data', response);
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-error',
              iconColor: 'white',
            });
          }

          this.isLoading = false;
        }, (error) => {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${error}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', error);
          this.isLoading = false;
        });
        this.subscriptionList.push(getSubscription);
      }, true],
      ['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
         }, event],
      ],
   
    });
  }
    private getNotes() {
    const getSubscription = getNotes().subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Notes Data is fetched successfully', response);
        let responseData : Record<string, unknown>[] = response.data.notes;
        let taskList : any[] = []; //eslint-disable-line
        responseData.forEach((task) => {
          if(task.source_id === this.oppData.id) taskList.push(task);
        });
        this.oppNotes = taskList;
      } else {
        console.log('Error occured while fetching products', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });

    this.subscriptionList.push(getSubscription);
  }

    private _addNote() {
    this.isLoading = true;
    const subscription = addNote(this.noteData).subscribe((response) => {
      if(response.data.status === 'success') {
        this.getNotes();
        this.isLoading = false;
        this.clearNoteData();

        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        console.log('Error occured while adding task', response);
        this.isLoading = false;
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while adding data', error);
    });

    this.subscriptionList.push(subscription);
  }

  private _updateNote() {
    this.isLoading = true;
    const getSubscription =  updateNote (this.noteEditId, this.noteData).subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        // console.log('update Task is successful');
        // console.log('The update response is: ', response);
        this.getNotes();
        this.isLoading = false;
        this.clearNoteData();
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _deleteNote(noteId : string, index : number) {
    Toast.question({
      timeout: 20000,
      close: false,
      overlay: false,
      id: 'question',
      zindex: 1000,
      title: '',
      message: 'Are you sure ?',
      position: "topRight",
      buttons: [
          ['<button><b>YES</b></button>',  (instance, toast) => {
      instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        this.isLoading = true;
        const getSubscription = deleteNote(noteId).subscribe((response) => {
          if(response.data.status === 'success') {
            console.log('Company was deleted successfuly', response);
            this.oppNotes.splice(index, 1);
            Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-check',
              iconColor: 'white',
            });
          } else {
            console.log('Error occured while fetching data', response);
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-error',
              iconColor: 'white',
            });
          }

          this.isLoading = false;
        }, (error) => {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${error}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', error);
          this.isLoading = false;
        });
        this.subscriptionList.push(getSubscription);
      }, true],
      ['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
         }, event],
      ],
   
    });
  }
    private clearData() {
    $('#myModal').modal('hide');
    this.editId = "";
    this.editIndex = -1
    // this.company = "";
    // this.opportunity = "";
    // this.taskOwner = "";
    // this.taskType = "";
    this.currentData = {
      task_owner : "",
      company_id : "",
      task_type_id : "",
      opportunity_id : "",
      task_title: "",
      task_description : "",
      priority: "",
      status: "Not Started",
      start_date : "",
      end_date : "",
      source_id : "",
      task_source : "Opportunity",
    };
  }

  private clearNoteData() {
    $('#noteModal').modal('hide');
    this.noteEditId = "";
    this.noteEditIndex = -1
    this.noteCompany = "";
    this.noteOpportunity = "";
    this.noteData = {
      note : "",
      company_id : "",
      opportunity_id : "",
      source_id : "",
      note_source : "Opportunity",
    };
  }
}
