import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'popper.js'
import 'bootstrap';

import "./assets/css/bootstrap.min.css";
// import "./assets/css/select2.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/style.css";
import "./assets/css/tailwind.css";

import DropZone from 'dropzone-vue';
import 'dropzone-vue/dist/dropzone-vue.common.css';

const app = createApp(App);
app.use(DropZone)
app.use(store);
app.use(router);



app.mount('#app')
