<template>
    <div class="content container-fluid">
				
					<!-- Page Header -->
					<div class="page-header">
						<div class="row align-items-center">
							<div class="col">
								<h3 class="page-title">User Group</h3>
								<ul class="breadcrumb">
									<li class="breadcrumb-item"><a>Settings</a></li>
									<li class="breadcrumb-item active">Users</li>
								</ul>
							</div>
						</div>
					</div>


                    <!-- <div class="col-sm-12"> -->
                        <div class="row">
                            <!-- table -->
                            <div class="col-lg-6">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table class="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Group</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="role in $store.state.userGroups" :key="role">
                                                        <td>{{ role }}</td>
                                                        <td>
                                                            <a href="javascript:void(0)" class="btn btn-sm btn-white text-success mr-2"><i class="far fa-edit mr-1"></i> Edit</a> 
                                                            <a @click="$store.commit('deleteRole', role)" href="javascript:void(0);" class="btn btn-sm btn-white text-danger mr-2"><i class="far fa-trash-alt mr-1"></i>Delete</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- form -->
                            <div class="col-md-6">
                                <div class="card">
                                    <div class="card-header">
                                        <h5 class="card-title">Add a Group</h5>
                                    </div>
                                    <div class="card-body" @submit.prevent="addNewRole">
                                        <form action="#">
                                            <div class="form-group">
                                                <label>Role</label>
                                                <input type="text" class="form-control" v-model="newRole">
                                            </div>
                                            <div class="text-left">
                                                <button type="submit" class="btn btn-primary">Add</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- </div> -->
    </div>
				</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  methods: {
		addNewRole: function () {
			this.$store.commit('addRole', this.newRole);
			this.newRole = ''
		}
	}
})

export default class UserGroup extends Vue {

    
}
</script>
