import { ApiConfigService } from "./apiconfig";

const API = new ApiConfigService();

export function getLossReasons() {
  return API.$Request().get('/loss-reasons');
}

export function addLossReason(newData : {name : string}) {
  return API.$Request().post('/loss-reasons/add-reason', newData);
}

export function updateLossReason(dataId : string, update : {name : string}) {
  return API.$Request().post(`/loss-reasons/update/${dataId}`, update);
}

export function deleteLossReason(dataId : string) {
  return API.$Request().delete(`/loss-reasons/delete/${dataId}`);
}