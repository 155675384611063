<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="page-title">Email Template Setting</h3>
          <ul class="breadcrumb">
            <li class="breadcrumb-item"><a>Settings</a></li>
            <li class="breadcrumb-item active">Email Template</li>
          </ul>
        </div>
      </div>
    </div>
    <!--Mail List -->
    <loading v-model:active="isLoading"  :can-cancel="true"   :is-full-page="true"></loading> 
    <div class="row">
        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Email List</h4>
                    <p class="card-title-desc">
                    </p>
                    <div id="datatable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length"><label>Show <select style="min-width: 50px" name="datatable_length" aria-controls="datatable" class="custom-select custom-select-sm form-control form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label></div></div><div class="col-sm-12 col-md-6"><div id="datatable_filter" class="dataTables_filter"><label>Search:<input type="search" class="form-control form-control-sm" placeholder="" aria-controls="datatable"></label>
                                </div></div></div><div class="row"><div class="col-sm-12"><table id="datatable" class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px; width: 100%;" role="grid" aria-describedby="datatable_info">
                        <thead>
                            <tr role="row"><th scope="col" class="sorting_asc" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 331.2px;" aria-label="Product: activate to sort column descending" aria-sort="ascending">Templates</th><th scope="col" style="width: 19.2px;" class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label=": activate to sort column ascending"></th></tr>
                        </thead>
                        <tbody> 
                        <tr v-for="(template, i) in EmailTemplates" :key="i" role="row" class="odd">
                                <td class="dtr-control sorting_1" tabindex="0">	{{template.title}}</td>
                                <td>
                                    <div class="btn-group dropup">
                                        <a class=" " data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"> <i class="fa fa-bars"></i>   </a>
                                        <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                          <a class="dropdown-item small text-info" style="cursor:pointer;"  @click.prevent="updateTemplate(template, 'edit')"><i class="fa fa-edit"></i> View</a>
                                          <!-- <a class="dropdown-item small text-success"><i class="fa fa-pencil"></i> Edit</a> -->
                                          <a class="dropdown-item text-danger small" style="cursor:pointer;" @click.prevent="deleteTemplate(template.id)" ><i class="fa fa-trash"></i> Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr> 
                            </tbody>
                    </table></div></div><div class="row"><div class="col-sm-12 col-md-5"><div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing 1 to 4 of 4 entries</div></div><div class="col-sm-12 col-md-7"><div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate"><ul class="pagination"><li class="paginate_button page-item previous disabled" id="datatable_previous"><a href="#" aria-controls="datatable" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="datatable" data-dt-idx="1" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item next disabled" id="datatable_next"><a href="#" aria-controls="datatable" data-dt-idx="2" tabindex="0" class="page-link">Next</a></li></ul></div></div></div></div>

                </div>
            </div>
        </div> <!-- end col -->

        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">

                    <h4 class="card-title">Setting</h4>
                    <p class="card-title-desc">
                    </p>
					<div class="form-group">
						<label>Title</label>
						<div>
							<input type="text" class="form-control" required="" v-model.trim="title" placeholder="Enter Title"  >
						</div>
					</div>
                    <div class="form-group">
                    <label> Template </label>
                         <vue-editor v-model="content" aria-placeholder="Email Template here"></vue-editor>
                    </div>
                    <div class="form-group mb-0">
                        <div class="text-right">
                            <button type="reset" class="btn btn-light waves-effect mr-1">
                                Cancel
                            </button>
                            <button :disabled="title === '' && content === ''" @click.prevent="addNewTemplate()" class="btn btn-primary waves-effect waves-light" > 
											<!-- <i data-feather="user-plus"> </i> -->
										<pulse-loader v-if="isLoadingBtn" :loading="true" color="white" size="10px" style="display: inline-block"></pulse-loader>
										&nbsp; 
										{{isLoadingBtn ? 'Saving' : 'Save '}}  
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- end col -->
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { VueEditor } from "vue3-editor";
import { getEmailTemplates, addEmailTemplate, updateEmailTemplate, deleteEmailTemplate } from '../store/api/usermgt';
import { EmailTemplate } from '../store/models/configsettings';
import Loading from 'vue-loading-overlay';
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { event } from 'jquery';
@Options({
  components: {
      VueEditor, Loading, PulseLoader
  },
})
export default class MailTemplate extends Vue {
private content = "";
private title = '';
private EmailTemplates = [];
private Template!: EmailTemplate;
private TemplateData: any = {};
private formview = 'create';
private isLoading = false;
private loadingIconColor = '#00b8d0';
private isLoadingBtn = false;

created(): void {
 this.getTemplates();
}
private getTemplates() {
	this.isLoading = true;
    getEmailTemplates().subscribe((response: any) => {
		this.EmailTemplates = response.data.templates;
		this.isLoading = false;
    });
}

private addNewTemplate() {

    if(this.formview == 'create') {
        console.log(this.formview, 'formview');
        
            const templateData =  {
            title : this.title,
            template : this.content
            }
            this.isLoading = true;
            addEmailTemplate(templateData).subscribe((response: any) => {
            console.log(response, 'user update response')
			if(response.data.status == "success") {
            this.isLoading = false;
            getEmailTemplates().subscribe((response: any) => {
                    this.EmailTemplates = response.data.templates;
                });
			this.title = '';
			this.content = '';
			Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 3000, overlay: false,id: 'toastAuthy', zindex: 99,
							title: response.data.message,
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-check',   
							iconColor: 'white',
						});
            
			}
	})
    } else {
        this.editTemplate();
        console.log(this.formview, 'formview');
    }

    

}
private updateTemplate(template: any, view: string) {
    console.log(view, 'formview in update');
    this.title = template.title;
     this.content = template.template;
     this.TemplateData = template;
     this.formview = view;
}

private editTemplate() {
    const templateData =  {
       title : this.title,
    template : this.content
    }
    this.isLoading = true;
    updateEmailTemplate(templateData, this.TemplateData.id).subscribe((response: any) => {
    console.log(response, 'user update response');
        if(response.data.status == "success") {
        this.isLoading = false;
        this.title = '';
        this.content = '';
        this.TemplateData = null;
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 5000, overlay: false,id: 'toastAuthy', zindex: 99,
                        title: response.data.message, titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false, overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',
                    });
            getEmailTemplates().subscribe((response: any) => {
                    this.EmailTemplates = response.data.templates;
                });
        this.formview = 'create';
        }
    });
}
private deleteTemplate(id: string) {
     Toast.question({
    timeout: 20000,
    close: false,
    overlay: false,
    id: 'question',
    zindex: 1000,
    title: '',
    message: 'Are you sure ?',
    position: 'center',
    buttons: [
        ['<button><b>YES</b></button>',  (instance, toast) => {
		instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        deleteEmailTemplate(id).subscribe((response: any) => {
        console.log(response, 'user update response');
        if(response.data.status == "success") {
        this.title = '';
        this.content = '';
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 5000, overlay: false,id: 'toastAuthy', zindex: 99,
                        title: response.data.message, titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false, overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',
                    });
         getEmailTemplates().subscribe((response: any) => {
                    this.EmailTemplates = response.data.templates;
                });
        } else {
             Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 5000, overlay: false,id: 'toastAuthy', zindex: 99,
                        title: 'Template delete unsuccessfull', titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false, overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',
                    });
        }
    });
         
    }, true],
    ['<button>NO</button>', (instance: any, toast: any) => {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
       }, event],
    ],
   
  });
    console.log(id, 'deleteId')

}
    
}
</script>


<style>
.req:after {
    content:" *";
    color: red;
  } 	
</style>