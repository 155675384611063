<template>
  <div class="bg-white shadow rounded px-3 py-2 border border-white">
    <div class="flex justify-between" v-if="opportunity.company">
      <p class="text-gray-800 font-semibold font-sans tracking-wide text-sm">{{opportunity.company.name}}</p>

      <!-- <img
        class="w-6 h-6 rounded-full ml-3"
        src="https://pickaface.net/gallery/avatar/unr_sample_161118_2054_ynlrg.png"
        alt="Avatar"
      > -->
      <!-- <i class="fa fa-user" ></i> {{opportunity.added_by.first_name}}{{opportunity.added_by.last_name}} -->
     <div class="avatar-xs" :title="opportunity.added_by.first_name+' '+opportunity.added_by.last_name">
       <span class=" rounded avatar-title bg-info">
         {{opportunity.added_by.first_name.slice(0,1)}}.{{opportunity.added_by.last_name.slice(0,1)}}
         </span></div>
   
    </div>
    <div class="flex mt-3 justify-between items-center" v-if="opportunity">
      <span class="text-sm text-gray-600">
          <p class="text-sm text-gray-600">Value: <span class="text-success">&#x20A6; {{numberWithCommas(opportunity.license_value)}} </span></p>
          Close Date: <span class="text-dark"  > {{dateSplit(opportunity.close_date)}} </span>
      </span>
      <badge :color="badgeColor">{{opportunity.priority}}</badge>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue';
import Badge from "./Badge.vue";
const TaskCard = defineComponent( {
  components: {
    Badge
  },
  props: {
    opportunity: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    badgeColor() {
      const mappings = {
        Medium: "purple",
        "High": "green",
        Low: "blue",
        default: "teal"
      };
      return mappings[this.opportunity.priority] || mappings.default;
    }
  },
  methods: {
     numberWithCommas(x) {
    if (!x) return 0;
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  },
    dateSplit(x) {
      if (!x) return 0;
    return x.split(' ')[0];
  },
    initials(x) {
      if (!x) return 0;
  console.log(x, 'intials');
  return 'X.OS'
  }
  } 
})
export default TaskCard;
</script>
<style scoped>
.w-6 {
    width: 1.5rem;
}
.h-6 {
    height: 1.5rem;
}
</style>
// {{numberWithCommas(opportunity.license_value) }}
    private numberWithCommas(x: any) {
    if (!x) return 0;
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }