
import {Options,Vue} from 'vue-class-component';
import $ from "jquery";
import  { event } from "jquery";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { GetAllProducts, getProduct, createProduct, deleteProduct, updateProduct} from '../store/api/configsettings';

@Options({
  components: {
		Loading,
        PulseLoader
  },
})

export default class Products extends Vue{
private Products: [] = [];
private productid ='';
private product ='';
private newProduct = '';
private isLoading = false;
private loadingIconColor = '#00b8d0';
private editedID = '';
private editedText = '';

created() {
    this.getProducts();
}

private getProducts(){
    this.isLoading = true;
    GetAllProducts().subscribe((response: any) =>{
        
        this.Products = response.data.products
        this.isLoading= false;
    })
}

private getSingleProduct(){
     getProduct(this.productid).subscribe((response: any) =>{
        console.log({response});
    })
}

private addProducts(productName: string){
    const newProductData ={
        name: productName
    }
        this.isLoading = true;
    createProduct(newProductData).subscribe((response: any) =>{
        if (response.data.status =="success"){
            this.isLoading =false;
            this.newProduct = '';
            this.getProducts();
            Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: response.data.message,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',
            });
        }else if(response.data.status=="error"){
                this.isLoading = false;
             Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: response.data.message,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
         }
    })
}



private setProductID (id: string, name: string) {
    this.editedID = id;
    this.editedText = name;
}

private editProductName(name: any, id: string) {
    const editedProductName={
        name: name
    }
    this.isLoading= true;
    updateProduct(editedProductName, id).subscribe((response: any) => {
        if (response.data.status === "success") {
            this.isLoading =false;
            $('#myModal').modal('hide');
            this.editedText = "";
            this.getProducts();
            Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: response.data.message,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',
            });
        }else if(response.data.status=="error"){
                this.isLoading = false;
             Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: response.data.message,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
         }
    })
}



private removeProducts(id: string){
        Toast.question({
        timeout: 20000,
        close: false,
        overlay: false,
        id: 'question',
        zindex: 1000,
        title: '',
        message: 'Are you sure ?',
        position: 'center',
        buttons: [
            ['<button><b>YES</b></button>',  (instance, toast) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

            this.isLoading = true;
            deleteProduct(id).subscribe((response:any) =>{
                if (response.data.status =="success"){
                    this.isLoading = false;
                    this.getProducts();
                    Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                    id: 'toastAuthy', zindex: 99,  title: response.data.message,
                    titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                    icon: 'fa fa-check',   
                    iconColor: 'white',});
                }else if(response.data.status=="error"){
                    this.isLoading = false;
                    Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                    id: 'toastAuthy', zindex: 99,  title: response.data.message,
                    titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                    icon: 'fa fa-check',   
                    iconColor: 'white',});
                }
            }) 
        }, true],
        ['<button>NO</button>', (instance: any, toast: any) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        }, event],
        ],
    })
}
}
