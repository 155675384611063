
import {Vue, Options} from 'vue-class-component';
import {getTaskType, getTasks, addTask, updateTask, deleteTask} from '../store/api/tasks';
import {getCompanies} from '../store/api/people';
import {addNote, deleteNote, getNotes, NoteData, updateNote} from "@/store/api/notesApi";
import {getUsers} from '../store/api/usermgt';
import * as feather from 'feather-icons';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import $ from "jquery";
import  { event } from "jquery";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { VueEditor } from "vue3-editor";

@Options({
    name: 'peopleInfoView',
    components :{
        Loading, VueEditor,
        PulseLoader,
    },
})



export default class People extends Vue{
    private createdTask: any = {
        company_id: '',
        opportunity_id: '',
        task_type_id: '',
        task_owner: '',
        task_title: '',
        start_date: '',
        end_date: '',
        task_description: '',
        task_source: '',
        source_id: '',
        priority: '',
        status: '',
    }

    private createdNote: any = {
        company_id: '',
        oppurtunity_id: '',
        note_description: ''
    }

    private taskID = '';
    private noteId = '';
    private taskDetails
    private PersonData;
    private PersonTasks: any = [];
    private Tasks : any = [];
    private TaskType : any = [];
    private CompanyNames = [];
    private Companies = []
    private Users = [];
    private UserNames: any = [];
    private personNotes: any = [];
    private isLoading = false;
    private loadingIconColor = '#00b8d0';

    created (): void {
        this.PersonData = this.$route.params;
        this.getTaskTypeName();
        this.getTasksDetails();
        this.getCompanies();
        this.getAllUsers();
        this.getAllNotes();
        console.log('found task', this.Tasks.find((task:any) => task.source_id === this.PersonData.id));
        
        console.log(this.PersonTasks, 'Tasks for person');
        feather.replace();
    }

    private getTasksDetails(){
        this.isLoading = true;
         getTasks().subscribe((response: any) =>{
            this.Tasks = response.data.tasks;
            this.PersonTasks = this.Tasks.filter((task: any) => task.source_id === this.PersonData.id);
            console.log(this.Tasks);
            this.isLoading = false;         
        })
    }

    private getTaskTypeName(){
        getTaskType().subscribe((response:any)=>{
            // console.log({response});
            this.TaskType=response.data.types
        })
    }

     private getCompanies(){
        getCompanies().subscribe((response:any)=>{
            this.Companies = response.data.companies;
            console.log({b: this.Companies, a: 'Companies'});
            if (response.data.companies) {
                this.CompanyNames = response.data.companies.map(company => company.name);
            }
        })
    }

    private getAllUsers(){
        getUsers().subscribe((response:any)=>{
            this.Users = response.data.data;
            console.log (this.Users, 'users')
            this.UserNames = this.Users.map((user:any) => `${user.first_name} ${user.last_name}`)
            console.log (this.UserNames, 'userNames')
        })
    }


    private createTasks(){
        let c: any = (this.Companies.filter((c: any) => c.name === this.createdTask.company_id))[0]
        let tt: any = this.TaskType.filter((t: any) => t.type === this.createdTask.task_type_id)[0]
        let user: any = this.Users.filter((u:any) => u.first_name === this.createdTask.task_owner)[0]
        const task = {
            company_id: c.id,
            opportunity_id: '',
            task_type_id: tt.id,
            task_owner: user.id,

            task_title: this.createdTask.task_title,
            start_date: this.createdTask.start_date,
            end_date: this.createdTask.end_date,
            task_description: this.createdTask.task_description,
            task_source: 'User',
            source_id: this.PersonData.id,
            priority: this.createdTask.priority,
            status: this.createdTask.status,
        }

        console.log(task, "tasks");
        
        this.isLoading = true;
        addTask(task).subscribe((response: any) => {
            console.log(response);
            if (response.data.status === "success") {
                console.log('person tasks', this.PersonTasks);
                this.isLoading = false;
                $('#modalTask').modal('hide');
                this.getTasksDetails()
                Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: 'Task created successfully.',
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }else if (response.data.status == "error") {
                console.log(response.data);   
                this.isLoading = false;
                Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: 'Error! Please try again',
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }
        })
    }


    private editTask(){
        let c: any = (this.Companies.filter((c: any) => c.name === this.createdTask.company_id))[0]
        let tt: any = this.TaskType.filter((t: any) => t.type === this.createdTask.task_type_id)[0]
        let user: any = this.Users.filter((u:any) => u.first_name === this.createdTask.task_owner)[0]
        const task = {
            company_id: c.id,
            opportunity_id: '',
            task_type_id: tt.id,
            task_owner: user.id,

            task_title: this.createdTask.task_title,
            start_date: this.createdTask.start_date,
            end_date: this.createdTask.end_date,
            task_description: this.createdTask.task_description,
            task_source: 'User',
            source_id: this.PersonData.id,
            priority: 'High',
            status: 'Not Started',
        }

        this.isLoading = true;
        updateTask(task, this.taskID).subscribe((response: any) => {
            if (response.data.status === 'success') {
                this.isLoading = false;
                $('#updateTaskModal').modal('hide');
                this.getTasksDetails()
                Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                    id: 'toastAuthy', zindex: 99,  title: 'Task updated successfully.',
                    titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                    icon: 'fa fa-check',
                    iconColor: 'white', });
            } else if (response.data.status == "error") {
                this.isLoading = false;
                Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: "Task could not be updated",
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }
            
        })
    }

    private setTaskID (id: string) {
        this.taskID = id;
    }

    private setNoteId (id: string) : void {
        this.noteId = id;
    }

    private removeTask (id: string) : void {
        Toast.question({
            timeout: 20000,
            close: false,
            overlay: false,
            id: 'question',
            zindex: 1000,
            title: '',
            message: 'Are you sure ?',
            position: 'center',
            buttons: [
                ['<button><b>YES</b></button>',  (instance, toast) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

                this.isLoading = true;
                deleteTask(id).subscribe((response: any) => {
                if (response.data.status === 'success') {
                    this.isLoading = false;
                    this.getTasksDetails()
                    Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                        id: 'toastAuthy', zindex: 99,  title: 'Task deleted!',
                        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',});
                    }else if(response.data.status=="error"){
                        this.isLoading = false;
                        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                        id: 'toastAuthy', zindex: 99,  title: response.data.message,
                        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',});
                    }
                }) 
            }, true],
            ['<button>NO</button>', (instance: any, toast: any) => {
                    instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, event],
            ],
        })
    }

    private getAllNotes () : void {
        this.isLoading = true;
        getNotes().subscribe((response: any) => {
            if (response.data.status === 'success') {
                this.personNotes = response.data.notes.filter((n:any) => n.source_id === this.PersonData.id);
                this.isLoading = false;
            }
        })
    }

    private saveNoteData () : void {
        const note = {
            company_id: this.PersonData.company_id,
            opportunity_id: '',
            note_source: 'User',
            source_id: this.PersonData.id,
            note: this.createdNote.note_description,
        }

        this.isLoading = true;
        addNote(note).subscribe((response: any) => {
            if (response.data.status === 'success') {
                this.isLoading = false;
                $('#noteModal').modal('hide');
                this.getAllNotes();
                Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: 'Note created successfully.',
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }else if (response.data.status == "error") {
                console.log(response.data);   
                this.isLoading = false;
                Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: 'Error! Please try again',
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }
        })
    }

    private editNoteData () : void {
        const note = {
            company_id: this.PersonData.company_id,
            opportunity_id: '',
            note_source: 'User',
            source_id: this.PersonData.id,
            note: this.createdNote.note_description,
        }

        this.isLoading = true;
        updateNote(this.noteId, note).subscribe((response: any) => {
            if (response.data.status === "success") {
                this.isLoading = false;
                $('#noteModaleEdit').modal('hide');
                this.getAllNotes();
                Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: 'Note updated successfully.',
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }else if (response.data.status == "error") {
                console.log(response.data);   
                this.isLoading = false;
                Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: 'Error updating Note! Please try again',
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }
        })
    }

    private removeNote (id: string) : void {
        Toast.question({
            timeout: 20000,
            close: false,
            overlay: false,
            id: 'question',
            zindex: 1000,
            title: '',
            message: 'Are you sure ?',
            position: 'center',
            buttons: [
                ['<button><b>YES</b></button>',  (instance, toast) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

                this.isLoading = true;
                deleteNote(id).subscribe((response: any) => {
                    if (response.data.status === 'success') {
                        this.isLoading = false;
                        this.getAllNotes()
                        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                        id: 'toastAuthy', zindex: 99,  title: 'Note deleted!',
                        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',});
                    }else if(response.data.status=="error"){
                        this.isLoading = false;
                        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                        id: 'toastAuthy', zindex: 99,  title: response.data.message,
                        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',});
                    }
                }) 
            }, true],
            ['<button>NO</button>', (instance: any, toast: any) => {
                    instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, event],
            ],
        })
    }


    private clearNoteData() {
        $('#noteModal').modal('hide');
        $('#noteModalEdit').modal('hide');
        this.createdNote = {
            company_id: '',
            oppurtunity_id: '',
            note_description: ''
        }  
    }

}
