import { ApiConfigService } from "./apiconfig";

const API = new ApiConfigService();

export function getCountries() {
  return API.$Request().get(`/countries`);
}

export function getCompany(dataId : string) {
  return API.$Request().get(`/companies/view/${dataId}`);
}

export function getProspects() {
  return API.$Request().get('/companies/prospect');
}

export function getConverted() {
  return API.$Request().get('/companies/converted');
}

export function addCompany(newData : Record<string, unknown>) {
  return API.$Request().post('/companies/add-company', newData);
}

export function updateCompany(dataId : string, update : {name : string}) {
  return API.$Request().post(`/companies/update/${dataId}`, update);
}

export function deleteCompany(dataId : string) {
  return API.$Request().delete(`/companies/delete/${dataId}`);
}