<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="page-title">Document Template</h3>
          <ul class="breadcrumb">
            <li class="breadcrumb-item"><a>Settings</a></li>
            <li class="breadcrumb-item active">Document Template</li>
          </ul>
        </div>
                <!-- <div class="col-auto">
                <a   class="btn btn-info btn-sm" @click="addDocDiv">
                <i class="fas fa-plus"></i> <small > Add New Document </small>
                </a>

                </div> -->
      </div>
    </div>		 
    <!-- Document List -->
    <div class="row">
        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Document List</h4>
                    <p class="card-title-desc">
                    </p>
                    <div id="datatable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length"><label>Show <select style="min-width: 50px" name="datatable_length" aria-controls="datatable" class="custom-select custom-select-sm form-control form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label></div></div><div class="col-sm-12 col-md-6"><div id="datatable_filter" class="dataTables_filter"><label>Search:<input type="search" class="form-control form-control-sm" placeholder="" aria-controls="datatable"></label>
                                </div></div></div><div class="row"><div class="col-sm-12"><table id="datatable" class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px; width: 100%;" role="grid" aria-describedby="datatable_info">
                        <thead>
                            <tr role="row"><th scope="col" class="sorting_asc" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 331.2px;" aria-label="Product: activate to sort column descending" aria-sort="ascending">Product</th><th scope="col" style="width: 19.2px;" class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label=": activate to sort column ascending"></th></tr>
                        </thead>
                        <tbody> 
                        <tr role="row" class="odd" v-for="(product, i) in Products" :key="i">
                                <td class="dtr-control sorting_1" tabindex="0">{{product.name}}</td>
                                <td>
                                    <div class="btn-group dropup">
                                    <button class="btn btn-light" @click="manageDocs(product)" >
                                        view
                                    </button>			 
                                        <!-- <a class="small  btn btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i class="fa fa-bars"></i> </a> -->
                                        <!-- <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                          <a class="dropdown-item small text-info" @click="manageDocs(product)"><i data-feather="edit-2"></i> Manage Documents</a>
                                        </div> -->
                                    </div>
                                </td>
                        </tr>
                             </tbody>
                    </table>
                    </div></div>
                    <div class="row">
                        <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                            Showing 1 to 4 of 4 entries
                        </div>
                        </div>
                        <div class="col-sm-12 col-md-7">
                            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous disabled" id="datatable_previous"><a href="#" aria-controls="datatable" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li>
                                    <li class="paginate_button page-item active"><a href="#" aria-controls="datatable" data-dt-idx="1" tabindex="0" class="page-link">1</a></li>
                                    <li class="paginate_button page-item next disabled" id="datatable_next"><a href="#" aria-controls="datatable" data-dt-idx="2" tabindex="0" class="page-link">Next</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div> <!-- end col -->

           
        <div class="col-xl-6" >
            <div class="card">
                <div class="card-body">

                    <h4 class="card-title">Setting</h4>
                    <p class="card-title-desc">
                    </p>
                    <div class="card bg-primary">
                        <div class="card-body  ">
                            <div class="col-sm-12    ">
                                <span class="text-white font-size-18 "><b> {{prodObj.name ? prodObj.name : 'Select a Product'}}</b></span>
                                    <!-- <div class="form-group ">
                                        <select @input="popSelect($event)" class="custom-select custom-select-lg  ">
                                        <option selected> Select Product</option>
                                        <option  v-for="(productx, i) in Products" :key="i"   :value="productx.id">{{productx.name}}</option>
                                        </select>
											</div> -->
                                    <!-- </span> -->
                            </div>
                  
                        </div>
                    </div>
                    <!-- <div class="card bg-primary">
                        <div class="card-body">
                            <div class="col-sm-12">
                                <span class="text-white font-size-18"><b>{{currentProduct.name ? currentProduct.name : 'Select a Product'}}</b></span>
                            </div>
                        </div>
                    </div> -->
                    <div class="form-group">
                        <div >
                            
              <DropZone 
                   
                    url="https://cikiapi-3mnak.ondigitalocean.app/api/document-templates/add-template"
                    @addedFile="addDocs"
                    @error-add="errorDocs"
                    @errorUpload="onErrorUpload"
                    @removedFile="onFileRemove"
                    :acceptedFiles="['pdf', 'doc', 'docx', 'xlsx','mpp']"
                  
                    :uploadOnDrop="false"
                    :retryOnError="true"
                    :parallelUpload="3" />
                             </div>
                             <!--   :maxFileSize="100000000"   :maxFiles="Number(10000000000)" -->
                        <hr>
                    </div>

                            <div class="table-responsive">
                        <table class="table table-bordered dt-responsive nowrap">
                            <thead>
                                <tr>
                                    <th style="width: 30px;">#</th>
                                    <th style="width: 100%;">Documents</th>
                                    <th style="width: 10px;"></th>
                                </tr>
                            </thead>
                            <tbody>
															<tr v-for="(docs, i) in productDocs" :key="i">
																	<td>{{i + 1}} </td>
																	<td>
																			<a style="white-space: pre-line" href="#" id="inline-username" data-type="textarea" data-pk="1" data-placeholder="Field cannot be empty" data-title="Enter Item" class="editable editable-pre-wrapped editable-click"> {{docs}} </a>
																	</td>
																	<td>
																			<a   class="text-info" style="margin-right: 5px; cursor : pointer">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
																		
																			</a>
																			<a  class="text-danger" style="margin-left: 5px; cursor : pointer">
																				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
																		
																			</a>
																	</td>
															</tr>
															<tr v-if="productDocs.length === 0">
																	<td class="text-left"> No documents available <span></span> </td>
															</tr>
                            </tbody>
                        </table>
                    </div>
                     <!-- :multipleUpload="true" "-->
                    <!-- <div class="form-group mb-0">
                        <div class="text-right">
                            <button type="reset" class="btn btn-light waves-effect mr-1">
                                Cancel
                            </button>
                            <button type="submit" class="btn btn-primary waves-effect waves-light">
                                Save
                            </button>

                        </div>
                    </div> -->
                </div>
            </div>
        </div> <!-- end col -->
           
        <!-- <div class="col-xl-6" :class="{'hide-div': switchCase}">
            <div class="card">
                <div class="card-body">

                    <h4 class="card-title">{{prodObj}} - <span class="text-secondary"> Documents </span></h4>
                    <p class="card-title-desc">
                    </p>
                    <div class="card ">
                        <div class="card-body  ">
                    
                            <div class="  row">
                                
                                    <div class="col-12" >
                                        <ul>
                                        <li > 
                                        <i class="fa fa-file fa-2x "> </i>
                                      <span> {{docs.template}} </span>
                                        </li>
                                         </ul>
                                      
									</div>
                                  
                            </div>
                        </div>
                    </div>
      
                </div>
            </div>
        </div>  -->
        <!-- end col -->


    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { getDocumentTemplates, addDocumentTemplate, updateDocumentTemplate, deleteDocumentTemplate, getProducts } from '../store/api/usermgt';
import VueUploadComponent from 'vue-upload-component';
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import { DropZone } from 'dropzone-vue';
import 'dropzone-vue/dist/dropzone-vue.common.css';
@Options({
  components: {
    DropZone
  },
})
export default class DocumentTemplate extends Vue {
private Products: any = [];
private DocTemplates = [];
private uploadUrl = '';
private isLoading = false;
private loadingIconColor = '#00b8d0';
private uploadDataId = '';
private switchCase = true;
private prodObj = '';
private productDocs: any = {}


created(): void {
    this.getProductList();
}
private getTemplates() {
    getDocumentTemplates().subscribe((response: any) => {
        this.DocTemplates = response.data.templates;
        // console.log(response.data, 'doc response', this.DocTemplates);
        const templates = response.data.templates;
        this.Products.map((prod: any) => {
            templates.map((temp: any) => {
                if(prod.id === temp.product_id) {
                    prod.documents.push(temp);
                }
            })
        });
        // console.log(this.Products, 'produtsss');
    });
}
private getProductList() {
	this.isLoading = true;
    getProducts().subscribe((response: any) => {
        this.Products = response.data.products.map((product: any) => {
            return {...product, documents: [] }
        });
        // console.log(this.Products, 'product response');
        this.getTemplates()
		this.isLoading = false;
    });
}

private manageDocs(product: any) {
    this.prodObj = product;
    this.productDocs = product.documents;
    this.uploadDataId = product.id;
    this.switchCase = false;
    console.log(product,'manage documents');   
}

// private manageDoc(product: any) {
//     this.prodObj = product.name
//     this.productDocs = product.documents;
//     // this.uploadDataId = dd.target.value
//     this.switchCase = false;
//     console.log(product,'manage documents');   
// }
private addDocDiv() {
    this.switchCase = true;
    // console.log(product,'manage documents');
    
}
 
 private addDocs(id: any, file: any) {
     const fileObj = {
         product_id : this.uploadDataId,
         template : file
     }
      console.log('add nhere' ,fileObj, file );
     addDocumentTemplate(fileObj).subscribe((response: any) => {
			if(response.data.status == "success") {
            this.getProductList();
			Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 3000, overlay: false,id: 'toastAuthy', zindex: 99,
							title: response.data.message,
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-check',   
							iconColor: 'white',
						});
			}  
			else if(response.data.status == "error") {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#B22222', timeout: 3000, overlay: false,id: 'toastAuthy', zindex: 99,
							title: response.data.message,
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-times',   
							iconColor: 'white',
						});
			}  
	})
 }
 private errorDocs(files: any, error: any) {
     console.log('file not compt', files, error);
     Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#B22222', timeout: 3000, overlay: false,id: 'toastAuthy', zindex: 99,
							title: 'File type/size is not compatible',
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-times',   
							iconColor: 'white',
						});
     
 }
 private onErrorUpload() { 
     console.log('EronErrorUpload');
     
 }
 private onFileRemove() { 
     console.log(' file removed');
     
 }

 private popSelect(dd: any) {
	// console.log(dd.target.value, 'selecInput');
	this.uploadDataId = dd.target.value
    // console.log( 'then id', this.uploadDataId );
}
}
</script>


<style scoped>
.req:after {
    content:" *";
    color: red;
  }
 .dropzone.dz-clickable {
    cursor: pointer;
}

.dropzone {
    min-height: 230px;
    border: 2px dashed #ced4da;
    background: #fff;
    border-radius: 6px;
} 
.dropzone {
    min-height: 150px;
    border: 2px dashed rgba(0,0,0,0.3);
    background: white;
    padding: 20px 20px;
}
.dropzone, .dropzone * {
    box-sizing: border-box;
}

.hide-div {
    display: none!important;
}
</style>