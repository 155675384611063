<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="page-title">{{pageData.title}}</h3>
          <ul class="breadcrumb">
            <li class="breadcrumb-item"><a>Settings</a></li>
            <li class="breadcrumb-item active">{{pageData.title}}</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /Page Header -->
    <div class="row">
        <div class="col-xl-7">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">List</h4>
                    <p class="card-title-desc">
                    </p>
                    <div id="datatable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer"><div class="row"><div class="col-sm-12 col-md-6"><div class="dataTables_length" id="datatable_length"><label>Show <select style="min-width: 50px" name="datatable_length" aria-controls="datatable" class="custom-select custom-select-sm form-control form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label></div></div><div class="col-sm-12 col-md-6"><div id="datatable_filter" class="dataTables_filter"><label>Search:<input type="search" class="form-control form-control-sm" placeholder="" aria-controls="datatable"></label></div></div></div><div class="row"><div class="col-sm-12"><table id="datatable" class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px; width: 100%;" role="grid" aria-describedby="datatable_info">
                        <thead>
                            <tr role="row"><th scope="col" class="sorting_asc" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 428.2px;" aria-sort="ascending" aria-label="Item Name: activate to sort column descending">Item Name</th><th scope="col" style="width: 19.2px;" class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label=": activate to sort column ascending"></th></tr>
                        </thead>
                        <tbody>
                          <tr v-for="data, index in pageData.list" :key="index" role="row" class="odd">
                                <td class="sorting_1 dtr-control" tabindex="0">{{data.name}}</td>
                                <td>
                                    <div class="btn-group dropup">
                                      <a class="font-size-18 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                      </a>
                                      <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                        <a @click="editData(data.name, index)" class="dropdown-item" data-toggle="modal" data-target="#myModal" style="cursor : pointer;">
                                           <i class="fa fa-edit"> </i>
                                          Edit
                                        </a>
                                        <a @click="$emit('deleteEmit', data.id)" class="dropdown-item text-danger" href="#" style="cursor : pointer;">
                                          <i class="fa fa-trash"> </i>
                                          Delete
                                        </a>
                                      </div>
                                    </div>
                                </td>
                          </tr>
                        </tbody>
                    </table></div></div><div class="row"><div class="col-sm-12 col-md-5"><div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing 1 to {{pageData.list.length}} of {{pageData.list.length}} entries</div></div><div class="col-sm-12 col-md-7"><div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate"><ul class="pagination"><li class="paginate_button page-item previous disabled" id="datatable_previous"><a href="#" aria-controls="datatable" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="datatable" data-dt-idx="1" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item next disabled" id="datatable_next"><a href="#" aria-controls="datatable" data-dt-idx="2" tabindex="0" class="page-link">Next</a></li></ul></div></div></div></div>

                </div>
            </div>
        </div> <!-- end col -->

        <div class="col-xl-5">
            <div class="card">
                <div class="card-body">

                    <h4 class="card-title">Add {{pageData.title}}</h4>
                    <p class="card-title-desc">
                    </p>

                    <div class="form-group">
                        <label>{{pageData.title}} Name <span style="color : red"> *</span></label>
                        <div>
                            <input v-model.trim="inputData" type="text" class="form-control" required="" placeholder="Enter Item Name">
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <div class="text-right">
                            <button @click="cancelSave" type="reset" class="btn btn-light waves-effect mr-1">
                                Cancel
                            </button>
                            <button @click="handleSave('add')" type="submit" class="btn btn-primary waves-effect waves-light">
                                Save
                            </button>

                        </div>
                    </div>

                </div>
            </div>
        </div> <!-- end col -->
    </div>
    <!--Start Modal -->
    <div class="modal right fade" id="myModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
        <div class="modal-content" style="background-color: #eeeeee;">
        <span style="cursor: pointer;" class="closeModal" data-dismiss="modal"  > </span>

            <div class="modal-header mb-0" style="background-color: #eeeeee;">
                    <div class=" ml-4 mr-0 mt-3">
                <h5> Update {{pageData.title}}</h5>
                </div>
            </div>
                <div class="modal-body">
                    <div  class="element-wrapper" >
                        <div class="element-box">			 						 
                <form @submit.prevent="editedName && handleSave('edit')" class="p-3">
                    <div class="form-group">
                        <label class="form-control-label">Checklist item</label>
                        <input class="form-control" type="text" v-model.trim="editedName">
                    </div>
                    <div class="form-group mb-0">
                        <button class="btn btn-lg btn-block btn-primary" :disabled="isUpdating">
                            <pulse-loader v-if="isUpdating" :loading="isUpdating" color="white" size="10px" style="display: inline-block"></pulse-loader>
                            {{isUpdating ? 'Updating' : 'Update'}} 
                        </button>
                    </div>
                </form>
                        </div>
            </div>
            </div>

        </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import emitter from "@/utils/emitter";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

interface ConfigProps {
  title: string,
  list: Record<string, never>[],
}

const Configtemplate = defineComponent({
  components: {
    PulseLoader
  },
  props: {
    pageData: {
      type: Object as PropType<ConfigProps>,
      required: true
    },
    hasUpdated : {
      type: Boolean,
      required : true
    },
    editedInput : {
      type: String,
      required : true
    }
  },
  emits: ['additionEmit', 'updateEmit', 'deleteEmit'],
  data() {
    return {
      inputData: '',
      editId: '',
      isUpdating: this.$props.hasUpdated,
      editedName: this.$props.editedInput,
    }
  },
  methods: {
    editData: function (name : string, index : number) {
      this.editedName = name;
      this.editId = this.$props.pageData.list[index].id;
    },
    handleSave: function (btnType : string) {
      if(btnType === 'edit' && this.editId && this.editedName) {
        this.isUpdating = true;
        emitter.emit('updateEmit', {dataId : this.editId, dataUpdate: {name : this.editedName}})
      } else if(btnType === 'add' && this.inputData){
        emitter.emit('additionEmit', {name : this.inputData});
        this.inputData = '';
      }
    },
    cancelSave: function() {
      this.inputData = '';
      this.editId = '';
    }
  },
  watch: {
    hasUpdated(newValue) {
      this.isUpdating = newValue;
    },
    editedInput(newValue) {
      this.editedName = newValue;
    }
  }
});

export default Configtemplate;
</script>

<style scoped>
  .closeModal {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .closeModal:hover {
    opacity: 1;
  }
  .closeModal:before, .closeModal:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .closeModal:before {
    transform: rotate(45deg);
  }
  .closeModal:after {
    transform: rotate(-45deg);
  }
</style>