
import { Options, Vue } from 'vue-class-component';
import { getDocumentTemplates, addDocumentTemplate, updateDocumentTemplate, deleteDocumentTemplate, getProducts } from '../store/api/usermgt';
import VueUploadComponent from 'vue-upload-component';
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import { DropZone } from 'dropzone-vue';
import 'dropzone-vue/dist/dropzone-vue.common.css';
@Options({
  components: {
    DropZone
  },
})
export default class DocumentTemplate extends Vue {
private Products: any = [];
private DocTemplates = [];
private uploadUrl = '';
private isLoading = false;
private loadingIconColor = '#00b8d0';
private uploadDataId = '';
private switchCase = true;
private prodObj = '';
private productDocs: any = {}


created(): void {
    this.getProductList();
}
private getTemplates() {
    getDocumentTemplates().subscribe((response: any) => {
        this.DocTemplates = response.data.templates;
        // console.log(response.data, 'doc response', this.DocTemplates);
        const templates = response.data.templates;
        this.Products.map((prod: any) => {
            templates.map((temp: any) => {
                if(prod.id === temp.product_id) {
                    prod.documents.push(temp);
                }
            })
        });
        // console.log(this.Products, 'produtsss');
    });
}
private getProductList() {
	this.isLoading = true;
    getProducts().subscribe((response: any) => {
        this.Products = response.data.products.map((product: any) => {
            return {...product, documents: [] }
        });
        // console.log(this.Products, 'product response');
        this.getTemplates()
		this.isLoading = false;
    });
}

private manageDocs(product: any) {
    this.prodObj = product;
    this.productDocs = product.documents;
    this.uploadDataId = product.id;
    this.switchCase = false;
    console.log(product,'manage documents');   
}

// private manageDoc(product: any) {
//     this.prodObj = product.name
//     this.productDocs = product.documents;
//     // this.uploadDataId = dd.target.value
//     this.switchCase = false;
//     console.log(product,'manage documents');   
// }
private addDocDiv() {
    this.switchCase = true;
    // console.log(product,'manage documents');
    
}
 
 private addDocs(id: any, file: any) {
     const fileObj = {
         product_id : this.uploadDataId,
         template : file
     }
      console.log('add nhere' ,fileObj, file );
     addDocumentTemplate(fileObj).subscribe((response: any) => {
			if(response.data.status == "success") {
            this.getProductList();
			Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 3000, overlay: false,id: 'toastAuthy', zindex: 99,
							title: response.data.message,
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-check',   
							iconColor: 'white',
						});
			}  
			else if(response.data.status == "error") {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#B22222', timeout: 3000, overlay: false,id: 'toastAuthy', zindex: 99,
							title: response.data.message,
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-times',   
							iconColor: 'white',
						});
			}  
	})
 }
 private errorDocs(files: any, error: any) {
     console.log('file not compt', files, error);
     Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#B22222', timeout: 3000, overlay: false,id: 'toastAuthy', zindex: 99,
							title: 'File type/size is not compatible',
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-times',   
							iconColor: 'white',
						});
     
 }
 private onErrorUpload() { 
     console.log('EronErrorUpload');
     
 }
 private onFileRemove() { 
     console.log(' file removed');
     
 }

 private popSelect(dd: any) {
	// console.log(dd.target.value, 'selecInput');
	this.uploadDataId = dd.target.value
    // console.log( 'then id', this.uploadDataId );
}
}
