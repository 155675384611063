
import { Options, Vue } from 'vue-class-component';
import {logOut } from '../store/api/usermgt'
@Options({
  props: {
    msg: String,
	showPeopleOptions: Boolean,
  }
})
export default class Header extends Vue {
  msg!: string


private userLogOut() {
	console.log('logout');
	
logOut().subscribe((res) => {
	console.log(res);
	this.$router.push('/login');
	localStorage.clear();
	
})
}
}

