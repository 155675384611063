<template>
  <loading v-model:active="isLoading"  :can-cancel="true"   :is-full-page="true"></loading> 
  <div style="display : flex; padding-right : 0px" class="content container-fluid">
    <div style="flex : 1">
      <div>
        <div class="info-div">
          <img style="width : 100px; height : 100px; border-radius : 50%;" src="../assets/img/icons/company.png" alt="card-img">
          <div class="info-body">
            <span style="font-size : 18px">{{CompanyData.name}}</span>
            <span>{{CompanyData.industry.name}}</span>
            <span>{{CompanyData.email}}</span>
          </div>
        </div>
        <div class="contacts-div">
          <div class="icon-card">
            <div class="contacts-icon">
              <i data-toggle="modal" data-target="#noteModal" class="fas fa-edit"></i>
            </div>
            <span>Note</span>
          </div>
          <div class="icon-card">
            <div class="contacts-icon">
              <i class="fas fa-envelope"></i>
            </div>
            <span>Email</span>
          </div>
          <div class="icon-card">
            <div class="contacts-icon">
              <i class="fas fa-phone-alt"></i>
            </div>
            <span>Call</span>
          </div>
          <div class="icon-card">
            <div class="contacts-icon">
              <i class="fas fa-plus"></i>
            </div>
            <span>Log</span>
          </div>
          <div class="icon-card">
            <div class="contacts-icon">
              <i data-toggle="modal" data-target="#myModal" class="fas fa-calendar"></i>
            </div>
            <span>Task</span>
          </div>
          <div class="icon-card">
            <div class="contacts-icon">
              <i class="fas fa-calendar-alt"></i>
            </div>
            <span>Meet</span>
          </div>
        </div>
        <hr style="margin-bottom : 1rem!important" class="mb-5"/>
        <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
          <!-- Accordion card -->
          <div style="background-color : transparent; box-shadow : none" class="card">
            <!-- Card header -->
            <a style="background-color : transparent; border-bottom: 1px solid rgba(0,0,0,.125); padding: .75rem 1.25rem" class="card-header" role="tab" id="headingOne1" data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1" aria-expanded="true"
               aria-controls="collapseOne1">
              <h5 class="mb-0">
                <i style="color : #377dff; margin-right : 15px" class="fas fa-angle-down rotate-icon"></i>
                Company Information
              </h5>
            </a>

            <!-- Card body -->
            <div style="background-color : transparent" id="collapseOne1" class="collapse" role="tabpanel" aria-labelledby="headingOne1"
                 data-parent="#accordionEx">
              <div class="card-body">
                <div class="container-fliud">
                  <div class="about-company row">
                    <div style="padding : 10px" class="col-4">
                      <span>Company domain name</span>
                      <p>nextCounsel.com</p>
                    </div>
                    <div style="padding : 10px" class="col-4">
                      <span>Industry</span>
                      <p>{{CompanyData.industry.name}}</p>
                    </div>
                    <div style="padding : 10px" class="col-4">
                      <span>Company owner</span>
                      <p></p>
                    </div>
                    <div style="padding : 10px" class="col-4">
                      <span>State/Region</span>
                      <p>{{CompanyData.country.country}}</p>
                    </div>
                    <div style="padding : 10px" class="col-4">
                      <span>Postal code</span>
                      <p>02141</p>
                    </div>
                    <div style="padding : 10px" class="col-4">
                      <span>Number of employees</span>
                      <p>5,000</p>
                    </div>
                    <div style="padding : 10px" class="col-4">
                      <span>Annual Revenue</span>
                      <p>$250,000,000</p>
                    </div>
                    <div style="padding : 10px" class="col-4">
                      <span>Time zone</span>
                      <p>West Africa</p>
                    </div>
                    <div style="padding : 10px" class="col-4">
                      <span>Company's linkedIn page</span>
                      <p>https://www.linkedin.com/company/hubspot</p>
                    </div>
                    <div style="padding : 10px" class="col-4">
                      <span>Description</span>
                      <p>
                        Next Counsel is a tech company that provides softwares for the legal sector,
                        to help them keep track of their internal processes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Accordion card -->
        </div>
        <!-- Accordion wrapper -->
      </div>

      <div style="padding-bottom : 30px">
        <ul class="nav nav-tabs nav-tabs-bottom">
          <li><a class="nav-link active" href="#activity" data-toggle="tab">Activity</a></li>
          <li><a class="nav-link" href="#notes" data-toggle="tab">Notes</a></li>
          <li><a class="nav-link" href="#emails" data-toggle="tab">Emails</a></li>
          <li><a class="nav-link" href="#tasks" data-toggle="tab">Tasks</a></li>
          <li><a class="nav-link" href="#meetings" data-toggle="tab">Meetings</a></li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="activity" role="tabpanel" aria-labelledby="home-tab">Anything should go here A</div>
          <!-- Notes Tab content - Start-->
          <div class="tab-pane fade" id="notes" role="tabpanel" aria-labelledby="notes-tab">
            <div style="display : flex; justify-content : space-between; align-items : center; margin-bottom : 15px; padding : 10px 0px;">
              <p style="font-size : 18px; margin : 0px">Notes</p>
              <button data-toggle="modal" data-target="#noteModal" class="btn btn-primary" style="padding-top : 7px; padding-bottom : 7px">Create Note</button>
            </div>
            <div>
              <div id="datatable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12">
                      
                      <div class="card" v-for="data, index in companyNotes" :key="index">
                              <div class="card-body " style="background-color: #c7c9bb; border-radius:5px;">
                                <span  v-html="data.note"></span>
                                      <div class="float-right btn-group dropup">
                                             <a @click="setNoteEdit(data, index)" class=" m-2" data-toggle="modal" data-target="#noteModal" style="cursor : pointer;">
                                         <i class="fa fa-edit"> </i>
                                        
                                      </a>
                                      <a @click="_deleteNote(data.id, index)" class=" m-2 text-danger" href="#" style="cursor : pointer;">
                                        <i class="fa fa-trash"> </i>
                                        
                                      </a>
                                </div>
                              </div>
                            </div>
                           <div v-if="companyNotes.length == 0" class="mt-4 text-center">
                           <span colspan="5" class="mt-5 justify-around"> 
                             No Recorded Notes Yet, <span style="cursor:pointer;" class="text-success" data-toggle="modal" data-target="#noteModal"> Create a Note <i class="fa fa-sticky-note"> </i> </span>
                           </span>
                      </div>
                      <!-- <table id="datatable" class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px; width: 100%;" role="grid" aria-describedby="datatable_info">
                    <thead>
                        <tr role="row">
                          <th class="sorting_asc" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 163.2px;" aria-sort="ascending" aria-label="Task Title: activate to sort column descending">Note</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 118.2px;" aria-label="Task Owner: activate to sort column ascending">Note Source</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 102.2px;" aria-label="Status: activate to sort column ascending">Source Name</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 20.2px;" aria-label=": activate to sort column ascending"></th>
                        </tr>
                    </thead>
                    <tbody>                    
                      <tr v-for="data, index in companyNotes" :key="index" role="row" class="odd">
                            <td class="sorting_1 dtr-control">{{data.note}}</td>
                            <td>{{data.note_source}}</td>
                            <td>{{data.company.name}}</td>
                            <td>
                                <div class="btn-group dropup">
                                    <a class="font-size-18 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                    </a>
                                    <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                      <router-link :to="{name : 'view_company'}" class="dropdown-item text-info" style="cursor : pointer;"><i class="fa fa-eye"></i>
                                        View
                                      </router-link>
                                      <a @click="setNoteEdit(data, index)" class="dropdown-item" data-toggle="modal" data-target="#noteModal" style="cursor : pointer;">
                                         <i class="fa fa-edit"> </i>
                                        Edit
                                      </a>
                                      <a @click="_deleteNote(data.id, index)" class="dropdown-item text-danger" href="#" style="cursor : pointer;">
                                        <i class="fa fa-trash"> </i>
                                        Delete
                                      </a>
                                    </div>
                                </div>
                            </td>
                      </tr>
                    </tbody>
                </table> -->
                </div>
                  </div>
                  <div class="row" style="margin-top : 15px">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing 1 to {{companyNotes.length}} of {{companyNotes.length}}</div>
                  </div>
                  <div style="display: flex; justify-content: flex-end;" class="col-sm-12 col-md-7"><div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                    <ul class="pagination"><li class="paginate_button page-item previous disabled" id="datatable_previous"><a href="#" aria-controls="datatable" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="datatable" data-dt-idx="1" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item next" id="datatable_next"><a href="#" aria-controls="datatable" data-dt-idx="4" tabindex="0" class="page-link">Next</a></li></ul>
                  </div>
                  </div>
                  </div>
              </div>
            </div>
          </div>
          <!-- Notes Tab content - End-->
          <div class="tab-pane fade" id="emails" role="tabpanel" aria-labelledby="contact-tab">Anything should go here C</div>
          <div class="tab-pane fade" id="tasks" role="tabpanel" aria-labelledby="tasks-tab">
            <div style="display : flex; justify-content : space-between; align-items : center; margin-bottom : 15px; padding : 10px 0px;">
              <p style="font-size : 18px; margin : 0px">Task Details</p>
              <button data-toggle="modal" data-target="#myModal" class="btn btn-primary" style="padding-top : 7px; padding-bottom : 7px">Create Task</button>
            </div>
            <div>
              <div id="datatable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12">
                      <table id="datatable" class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px; width: 100%;" role="grid" aria-describedby="datatable_info">
                    <thead>
                        <tr role="row">
                          <th class="sorting_asc" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 163.2px;" aria-sort="ascending" aria-label="Task Title: activate to sort column descending">Task Title</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 118.2px;" aria-label="Task Owner: activate to sort column ascending">Task Owner</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 102.2px;" aria-label="Status: activate to sort column ascending">Status</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 75.2px;" aria-label="Priority: activate to sort column ascending">Priority</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 75.2px;" aria-label="Date: activate to sort column ascending">Set Date</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 20.2px;" aria-label=": activate to sort column ascending"></th>
                        </tr>
                    </thead>
                    <tbody>                    
                      <tr v-for="data, index in companyTasks" :key="index" role="row" class="odd">
                            <td class="sorting_1 dtr-control">{{data.task_title}}</td>
                            <td>{{`${data.task_owner.first_name} ${data.task_owner.last_name}`}}</td>
                            <td>{{data.status}}</td>
                            <td>{{data.priority}}</td>
                            <td>{{new Date(data.start_date).toLocaleString()}}</td>
                            <td>
                                <div class="btn-group dropup">
                                    <a class="font-size-18 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                    </a>
                                    <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                      <a @click="setCurrentTask(data, index)" data-toggle="modal" data-target="#viewModal" class="dropdown-item text-info" style="cursor : pointer;">
                                        <i class="fa fa-eye"></i>
                                        View
                                      </a>
                                      <a @click="setEdit(data, index)" class="dropdown-item" data-toggle="modal" data-target="#myModal" style="cursor : pointer;">
                                        <i class="fa fa-edit"> </i>
                                        Edit
                                      </a>
                                      <a @click="_deleteTask(data.id, index)" class="dropdown-item text-danger" style="cursor : pointer;">
                                        <i class="fa fa-trash"> </i>
                                        Delete
                                      </a>
                                    </div>
                                </div>
                            </td>
                      </tr>
                    </tbody>
                </table>
                </div>
                  </div>
                  <div class="row" style="margin-top : 15px">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing 1 to {{companyTasks.length}} of {{companyTasks.length}}</div>
                  </div>
                  <div style="display: flex; justify-content: flex-end;" class="col-sm-12 col-md-7"><div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                    <ul class="pagination"><li class="paginate_button page-item previous disabled" id="datatable_previous"><a href="#" aria-controls="datatable" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="datatable" data-dt-idx="1" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item next" id="datatable_next"><a href="#" aria-controls="datatable" data-dt-idx="4" tabindex="0" class="page-link">Next</a></li></ul>
                  </div>
                  </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="meetings" role="tabpanel" aria-labelledby="meetings-tab">Anything should go here E</div>
        </div>
      </div>
    </div>

    <div style="margin-top : 20px; margin-left : 20px; min-height : 70vh; background-color : #fff; border-radius : 5px 0px 0px 5px; padding : 0px" class="col-md-4">
      <div class="accordion md-accordion" id="accordion2" role="tablist" aria-multiselectable="true">
        <!-- Accordion card -->
        <div style="border-bottom: 1px solid rgba(0,0,0,.125); box-shadow : none; border-radius : 0px; margin : 0px" class="card">
          <!-- Card header -->
          <a class="card-header" role="tab" id="heading1" data-toggle="collapse" data-parent="#accordion2" href="#collapse1" aria-expanded="true"
             aria-controls="collapse1">
            <h6 class="mb-0">
              <i style="color : #377dff; margin-right : 15px" class="fas fa-angle-down"></i>
              Contacts
            </h6>
          </a>

          <!-- Card body -->
          <div id="collapse1" class="collapse" role="tabpanel" aria-labelledby="heading1" data-parent="#accordion2">
            <div class="card-body">
              Contact details go here
            </div>
          </div>
        </div>
        <div style="border-bottom: 1px solid rgba(0,0,0,.125); box-shadow : none; border-radius : 0px; margin : 0px" class="card">
          <!-- Card header -->
          <a class="card-header" role="tab" id="heading2" data-toggle="collapse" data-parent="#accordion2" href="#collapse2" aria-expanded="true"
             aria-controls="collapse2">
            <h6 class="mb-0">
              <i style="color : #377dff; margin-right : 15px" class="fas fa-angle-down"></i>
              Opportunities(0)
            </h6>
          </a>

          <!-- Card body -->
          <div id="collapse2" class="collapse" role="tabpanel" aria-labelledby="heading2" data-parent="#accordion2">
            <div class="card-body">
              Company opportunities go here.
            </div>
          </div>
        </div>
        
        <div style="border-bottom: 1px solid rgba(0,0,0,.125); box-shadow : none; border-radius : 0px; margin : 0px" class="card">
          <!-- Card header -->
          <a class="card-header" role="tab" id="heading3" data-toggle="collapse" data-parent="#accordion2" href="#collapse3" aria-expanded="true"
             aria-controls="collapse3">
            <h6 class="mb-0">
              <i style="color : #377dff; margin-right : 15px" class="fas fa-angle-down"></i>
              Issues(0)
            </h6>
          </a>

          <!-- Card body -->
          <div id="collapse3" class="collapse" role="tabpanel" aria-labelledby="heading3" data-parent="#accordion2">
            <div class="card-body">
              Company file attachments appear here, and would move into multiple lines as the need arises
            </div>
          </div>
        </div>
        <!-- Accordion card -->
      </div>
    </div>
    <!--Start Task Modal -->
    <div class="modal right fade" id="myModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="background-color: #eeeeee;">
        <span @click="clearData" style="cursor: pointer;" class="closeModal"> </span>

            <div class="modal-header mb-0" style="background-color: #eeeeee;">
                    <div class=" ml-4 mr-0 mt-3">
                <h5>Task</h5>
                </div>
            </div>

          <form @submit.prevent="saveData">
            <div class="modal-body" data-select2-id="59">
                <!--<div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Company <span style="color : red;">*</span></label>
                            <select required v-model.trim="currentData.company_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                              <option value="" disabled >--Choose an option--</option>
                              <option v-for="data, index in companyList" :key="index" :value="data.id">{{data.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="float-right">
                                <a href="#" data-toggle="modal" data-target="#modalIndustry">
                                    <span>Add New</span>
                                </a>
                            </div>
                            <label>Opportunity (not required for converted client) <span style="color : red;">*</span></label>
                            <select v-model.trim="currentData.opportunity_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                              <option value="" disabled >--Choose an option--</option>
                              <option v-for="data, index in opportunityList" :key="index" :value="data.id">{{data.product.name}}</option>
                            </select>
                        </div>
                    </div>
                </div> -->
                <div class="form-group">
                    <label>Task Title <span style="color : red;">*</span></label>
                    <input required v-model.trim="currentData.task_title" type="text" class="form-control" placeholder="Enter Title">
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Task Type <span style="color : red;">*</span></label>
                            <select required v-model.trim="currentData.task_type_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                              <option value="" disabled >--Choose an option--</option>
                              <option v-for="data, index in taskTypes" :key="index" :value="data.id">{{data.type}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Due date</label>
                            <input v-model.trim="currentData.end_date" type="date" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Task Priority <span style="color : red;">*</span></label>
                            <select required v-model.trim="currentData.priority" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                              <option value="" disabled >--Choose an option--</option>
                              <option value="Low">Low</option>
                              <option value="Medium">Medium</option>
                              <option value="High">High</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                          <label>Status <span style="color : red;">*</span></label>
                          <select required v-model.trim="currentData.status" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                            <option value="" disabled >--Choose an option--</option>
                            <option value="Not Started">Not Started</option>
                            <option value="In Progress">In Progress</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Abandoned">Abandoned</option>
                            <option value="Completed">Completed</option>
                          </select>
                      </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Task Owner</label>
                    <select required v-model="currentData.task_owner" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="22" tabindex="-1" aria-hidden="true">
                      <option value="" disabled >--Choose an option--</option>
                      <option v-for="data, index in usersList" :key="index" :value="data.id">{{data.first_name}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Task Description</label>
                    <div>
                        <textarea required v-model.trim="currentData.task_description" class="form-control" rows="3" placeholder="Enter Description"></textarea>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button @click="clearData" type="button" class="btn btn-light waves-effect">Discard</button>
                <button type="submit" class="btn btn-primary waves-effect waves-light">Save</button>
            </div>
          </form>
        </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- end Task modal -->

    <!--Start Task View Modal -->
    <div class="modal right fade" id="viewModal" data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div  class="modal-content" style="background-color: #eeeeee; width : 700px;">
        <span data-toggle="modal" data-target="#viewModal" style="cursor: pointer;" class="closeModal"> </span>

            <div class="modal-header mb-0" style="background-color: #eeeeee;">
                    <div class=" ml-4 mr-0 mt-3">
                <h5>Task Details</h5>
                </div>
            </div>

          <div class="card-body">
            <div class="container-fliud">
              <div class="about-company row" style="overflow : wrap">
                <div style="padding : 10px" class="col-6">
                  <span>Task Title</span>
                  <p>{{currentTask.task_title}}</p>
                </div>
                <div style="padding : 10px" class="col-6">
                  <span>Task Owner</span>
                  <p>{{currentTask.task_owner.first_name}}</p>
                </div>
                <div style="padding : 10px" class="col-6">
                  <span>Task Source</span>
                  <p>{{currentTask.task_source}}</p>
                </div>
                <div style="padding : 10px" class="col-6">
                  <span>Priority</span>
                  <p>{{currentTask.priority}}</p>
                </div>
                <div style="padding : 10px" class="col-6">
                  <span>Set Date</span>
                  <p>{{new Date(currentTask.start_date).toLocaleString()}}</p>
                </div>
                <div style="padding : 10px" class="col-6">
                  <span>Due Date</span>
                  <p>{{new Date(currentTask.end_date).toLocaleString() || ""}}</p>
                </div>
                <div style="padding : 10px" class="col-6">
                  <span>Task Type</span>
                  <p>{{currentTask.task_type.type}}</p>
                </div>
                <div style="padding : 10px" class="col-6">
                  <span>Task Description</span>
                  <p>{{currentTask.task_description}}</p>
                </div>
                <div style="padding : 10px" class="col-6">
                  <div class="form-group">
                      <label>Task Status <span style="color : red;">*</span></label>
                      <select required v-model.trim="currentTask.status" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                        <option value="" disabled >--Choose an option--</option>
                        <option value="Not Started">Not Started</option>
                        <option value="In Progress">In Progress</option>
                        <option value="On Hold">On Hold</option>
                        <option value="Abandoned">Abandoned</option>
                        <option value="Completed">Completed</option>
                      </select>
                  </div>
                  <div class="col-md-6">
                </div>
                
              </div>
              <div style="padding : 10px; display : flex; align-items : center" class="col-6">
                <button @click="updateStatus(currentTask)" type="submit" class="btn btn-primary waves-effect waves-light">Update Status</button>
              </div>
            </div>
          </div>
        </div><!-- modal-content -->
      </div><!-- modal-dialog -->
    </div>
    </div>
    <!-- end Task View modal -->
    

    <!--Start Note Modal -->
    <div class="modal right fade" id="noteModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content" style="background-color: #eeeeee;">
          <span @click="clearNoteData" style="cursor: pointer;" class="closeModal"> </span>

              <div class="modal-header mb-0" style="background-color: #eeeeee;">
                      <div class=" ml-2 mr-0 mt-3">
                  <h5> <i class="fa fa-sticky-note mr-2"></i> Note</h5>
                  </div>
              </div>

            <form @submit.prevent="saveNoteData">
              <div class="modal-body" >
                  <!-- <div class="row">
                      <div class="col-md-12">
                          <div class="form-group">
                              <label>Company <span style="color : red;">*</span></label>
                              <select required v-model.trim="noteData.company_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                                <option value="" disabled >--Choose an option--</option>
                                <option v-for="data, index in companyList" :key="index" :value="data.id">{{data.name}}</option>
                              </select>
                          </div>
                      </div>
                  </div> -->
                  <!-- <div class="row">
                      <div class="col-md-12">
                          <div class="form-group">
                   
                              <label>Opportunity</label>
                              <select v-model.trim="noteData.opportunity_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                                <option value="" disabled >--Choose an option--</option>
                                <option v-for="data, index in opportunityList" :key="index" :value="data.id">{{data.product.name}}</option>
                              </select>
                          </div>
                      </div>
                  </div> -->
                  <div class="form-group">
                      <label>Note Description <span style="color : red;">*</span></label>
                      <div>
                          <!-- <textarea required v-model.trim="noteData.note" class="form-control" rows="1" placeholder="Enter Description"></textarea> -->
                           <vue-editor v-model="noteData.note" aria-placeholder="Enter Content Here"></vue-editor>
                      </div>
                  </div>
              </div>

              <div class="modal-footer">
                  <button @click="clearNoteData" type="button" class="btn btn-light waves-effect">Discard</button>
                  <button type="submit" class="btn btn-primary waves-effect waves-light">Save</button>
              </div>
            </form>
          </div><!-- modal-content -->
          </div><!-- modal-dialog -->
    </div>
    <!-- end Task modal -->
    
  </div>
</template>

<script lang="ts">
import {Vue, Options} from 'vue-class-component';
import {addTasks, deleteTask, getTasks, getTaskTypes, getUsers, TaskData, updateTask} from "@/store/api/tasksApi";
import {addNote, deleteNote, getNotes, NoteData, updateNote} from "@/store/api/notesApi";
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import $, {event} from "jquery";
import { getCompanies, getOpportunities } from '@/store/api/opportunityApi';
import { VueEditor } from "vue3-editor";
import { getCompany } from '@/store/api/company';


@Options({
    name: 'peopleInfoView',
    components :{
      Loading, VueEditor
    },
})


export default class ViewCompany extends Vue{
  private isLoading = false;

  private opportunityList : any[] = []; //eslint-disable-line
  private companyList : any[] = []; //eslint-disable-line
  private usersList : any[] = []; //eslint-disable-line
  private taskTypes : any[] = []; //eslint-disable-line

  private editId = "";
  private editIndex = -1;
  private opportunity = "";
  private company = "";
  private taskOwner = "";
  private taskType = "";

  private noteEditId = "";
  private noteEditIndex = -1;
  private noteOpportunity = "";
  private noteCompany = "";

  private CompanyData;

  private companyTasks : Record<string, unknown>[] = [];
  private companyNotes : Record<string, unknown>[] = [];
  // Refers to the current task being viewed. Not to be mistaken with currentData.
  private currentTask = {
    task_type : {},
    task_owner : {}
  };

  private currentData : TaskData = {
    task_owner : "",
    company_id : "",
    task_type_id : "",
    opportunity_id : "",
    task_title: "",
    task_description : "",
    priority: "",
    status: "Not Started",
    start_date : "",
    end_date : "",
    source_id : "",
    task_source : "Company",
  };

  private noteData : NoteData = {
    note : "",
    company_id : "",
    opportunity_id : "",
    source_id : "",
    note_source : "Company",
  }

  private subscriptionList : any[] = []; //eslint-disable-line

  created () : void {
    this.CompanyData = this.$route.params;
    this.company = this.CompanyData.name;
    this.currentData.company_id = this.CompanyData.id;
    this.noteData.company_id = this.CompanyData.id;

    console.log('The company data is : ', this.CompanyData);
    this._fetchCompany(this.$route.params.id as string);
    this._getTasks();
    this._getTaskTypes();
    this._getCompanies();
    this._getOpportunities();
    this._getUsers();
    this._getNotes();
  }

  beforeUnmount() : void { 
    this.subscriptionList.forEach((subscription) => subscription.unsubscribe())
    console.log('The unsubscription goes fine');
  }

  private setCurrentTask(data, index) {
    this.currentTask = data;
    this.editIndex = index;
    console.log("The current task is: ", this.currentTask);
  }

  private updateStatus(data) {
    this.setEdit(data, this.editIndex);
    this.saveData();
  }

  private setEdit(data, index) {
    const {task_title, task_description, task_owner, task_type_id, opportunity_id, 
      company_id, priority, status, start_date, end_date, source_id, task_source
    } = data;
    const editData = {task_title, task_description, task_owner : task_owner.id, task_type_id, opportunity_id,
      company_id, priority, status, start_date, end_date : new Date(end_date).toLocaleDateString(), source_id, task_source
    };
    this.currentData = editData;
    this.editId = data.id;
    this.editIndex = index;
    this.company = data.company?.name || "";
    this.opportunity = data.opportunity?.name || "";
    this.taskOwner = data.task_owner?.first_name || "";
    this.taskType = data.task_type?.type || ""
    console.log('The current edit is: ', data);
  }

  private setNoteEdit(data, index) {
    const {opportunity_id, company_id, note, source_id, note_source} = data;
    const editData = { opportunity_id, company_id, note, source_id, note_source};
    this.noteData = editData;
    this.noteEditId = data.id;
    this.noteEditIndex = index;
    this.noteCompany = data.company?.name || "";
    this.noteOpportunity = data.opportunity?.name || "";
    console.log('The current edit is: ', data);
  }

  private saveData() {
    // this runs, if the data is an edited data.
    if(this.editId && this.currentData.task_title) {
      
      this._updateTask();
    } else if(!this.editId && this.currentData.task_title) {

      this.currentData.start_date = new Date().toISOString();
      this.currentData.source_id = this.CompanyData.id;

      this._addTask();
    }
  }

  private saveNoteData() {
    // this runs, if the data is an edited data.
    if(this.noteEditId && this.noteData.note) {

      this._updateNote();
    } else if(!this.noteEditId && this.noteData.note) {

      this.noteData.source_id = this.CompanyData.id;
      this._addNote();
    }
  }

  private clearData() {
    $('#myModal').modal('hide');
    $('#viewModal').modal('hide');
    this.editId = "";
    this.editIndex = -1
    this.company = "";
    this.opportunity = "";
    this.taskOwner = "";
    this.taskType = "";
    this.currentData = {
      task_owner : "",
      company_id : this.CompanyData.id,
      task_type_id : "",
      opportunity_id : "",
      task_title: "",
      task_description : "",
      priority: "",
      status: "Not Started",
      start_date : "",
      end_date : "",
      source_id : "",
      task_source : "Company",
    };
  }

  private clearNoteData() {
    $('#noteModal').modal('hide');
    this.noteEditId = "";
    this.noteEditIndex = -1
    this.noteCompany = "";
    this.noteOpportunity = "";
    this.noteData = {
      note : "",
      company_id : this.CompanyData.id,
      opportunity_id : "",
      source_id : "",
      note_source : "Company",
    };
  }

  private _fetchCompany(dataId : string) {
    this.isLoading = true;
    const getSubscription = getCompany(dataId).subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('This company is fetched successfully');
        this.CompanyData = response.data.company;
        this.isLoading = false;
        console.log("The Company Data value is: ", this.CompanyData);
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _getCompanies() {
    const getSubscription = getCompanies().subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('Companies is fetched successfully');
        this.companyList = response.data.companies;
        console.log("The Company list value is: ", this.companyList);
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _getOpportunities() {
    const getSubscription = getOpportunities().subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('Get Opportunities is fetched successfully');
        this.opportunityList = response.data.opportunity;
        console.log("The Opportunity list value is: ", this.opportunityList);
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _getUsers() {
    const getSubscription = getUsers().subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('Get People is fetched successfully');
        this.usersList = response.data.users;
        console.log("The People list value is: ", this.usersList);
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _getTaskTypes() {
    const subscription = getTaskTypes().subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Data fetched successfully', response);
        let responseData : Record<string, unknown>[] = response.data.types;
        this.taskTypes = responseData;
      } else {
        console.log('Error occured while fetching products', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });

    this.subscriptionList.push(subscription);
  }

  private _getTasks() {
    const getSubscription = getTasks().subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('getTasks Data fetched successfully', response);
        let responseData : Record<string, unknown>[] = response.data.tasks;
        let taskList : any[] = []; //eslint-disable-line
        responseData.forEach((task) => {
          if(task.source_id === this.CompanyData.id) taskList.push(task);
        });
        this.companyTasks = taskList;
      } else {
        console.log('Error occured while fetching products', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });

    this.subscriptionList.push(getSubscription);
  }

  private _addTask() {
    this.isLoading = true;
    const subscription = addTasks(this.currentData).subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Task added successfully', response);
        this._getTasks();
        console.log('The company tasks value is: ', this.companyTasks);
        this.isLoading = false;
        this.clearData();

        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        console.log('Error occured while adding task', response);
        this.isLoading = false;
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while adding data', error);
    });

    this.subscriptionList.push(subscription);
  }

  private _updateTask() {
    this.isLoading = true;
    const getSubscription =  updateTask(this.editId, this.currentData).subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('update Task is successful');
        console.log('The update response is: ', response);
        this._getTasks();
        // this.companyTasks.splice(this.editIndex, 1, response.data.company);
        console.log("Company task list value is: ", this.companyTasks);
        this.isLoading = false;
        this.clearData();
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _deleteTask(taskId : string, index : number) {
    Toast.question({
      timeout: 20000,
      close: false,
      overlay: false,
      id: 'question',
      zindex: 1000,
      title: '',
      message: 'Are you sure ?',
      position: "topRight",
      buttons: [
          ['<button><b>YES</b></button>',  (instance, toast) => {
      instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        this.isLoading = true;
        const getSubscription = deleteTask(taskId).subscribe((response) => {
          if(response.data.status === 'success') {
            console.log('Company was deleted successfuly', response);
            this.companyTasks.splice(index, 1);
            Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-check',
              iconColor: 'white',
            });
          } else {
            console.log('Error occured while fetching data', response);
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-error',
              iconColor: 'white',
            });
          }

          this.isLoading = false;
        }, (error) => {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${error}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', error);
          this.isLoading = false;
        });
        this.subscriptionList.push(getSubscription);
      }, true],
      ['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
         }, event],
      ],
   
    });
  }

  private _getNotes() {
    const getSubscription = getNotes().subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Notes Data is fetched successfully', response);
        let responseData : Record<string, unknown>[] = response.data.notes;
        let taskList : any[] = []; //eslint-disable-line
        responseData.forEach((task) => {
          if(task.source_id === this.CompanyData.id) taskList.push(task);
        });
        this.companyNotes = taskList;
      } else {
        console.log('Error occured while fetching products', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });

    this.subscriptionList.push(getSubscription);
  }

  private _addNote() {
    this.isLoading = true;
    const subscription = addNote(this.noteData).subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Task added successfully', response);
        this._getNotes();
        console.log('The company tasks value is: ', this.companyTasks);
        this.isLoading = false;
        this.clearNoteData();

        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        console.log('Error occured while adding task', response);
        this.isLoading = false;
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while adding data', error);
    });

    this.subscriptionList.push(subscription);
  }

  private _updateNote() {
    this.isLoading = true;
    const getSubscription =  updateNote (this.noteEditId, this.noteData).subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('update Task is successful');
        console.log('The update response is: ', response);
        this._getNotes();
        // this.companyTasks.splice(this.editIndex, 1, response.data.company);
        console.log("Company task list value is: ", this.companyTasks);
        this.isLoading = false;
        this.clearNoteData();
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _deleteNote(noteId : string, index : number) {
    Toast.question({
      timeout: 20000,
      close: false,
      overlay: false,
      id: 'question',
      zindex: 1000,
      title: '',
      message: 'Are you sure ?',
      position: "topRight",
      buttons: [
          ['<button><b>YES</b></button>',  (instance, toast) => {
      instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        this.isLoading = true;
        const getSubscription = deleteNote(noteId).subscribe((response) => {
          if(response.data.status === 'success') {
            console.log('Company was deleted successfuly', response);
            this.companyNotes.splice(index, 1);
            Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-check',
              iconColor: 'white',
            });
          } else {
            console.log('Error occured while fetching data', response);
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-error',
              iconColor: 'white',
            });
          }

          this.isLoading = false;
        }, (error) => {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${error}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', error);
          this.isLoading = false;
        });
        this.subscriptionList.push(getSubscription);
      }, true],
      ['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
         }, event],
      ],
   
    });
  }
}
</script>

<style scoped>
  .info-div {
    display: flex;
    align-items: center;
    background-color: transparent;
  }

  .info-body {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contacts-div {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding : 0px 5px;
  }

  .contacts-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    padding : 10px;
    margin-bottom: 10px;
    background-color: #eaf0f6;
    border-color: #cbd6e2;
    color: #506e91;
    cursor: pointer;
  }

  .about-company span {
    font-size: 13px;
    color: #000000;
  }
</style>