
import {Vue, Options} from 'vue-class-component';
import {getPeople,getCompanies, addPeople, updatePeople, deletePeople} from '../store/api/people';
import { PersonData } from '../store/models/people';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import $ from "jquery";
import  { event } from "jquery";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { addCompany, getCountries } from '@/store/api/company';
import { getIndustries } from '@/store/api/industry';

@Options({
    components :{
        Loading,
        PulseLoader,
    },
    // props: {
    //     // showPeopleOptions: { type: Function}
    // }
})


export default class People extends Vue{
    private currentData = {
      name : "",
      phone : "",
      email : "",
      website : "",
      address : "",
      industry_id : "",
      country_id : "",
      status : false,
    };
    private industryList : any[] = []; //eslint-disable-line
    private countryList : any[] = []; //eslint-disable-line
    private industry = "";
    private country = "";

    private People = [];
    private Companies : any[] = []; //eslint-disable-line
    private companyNames = [];
    private personData: PersonData = {
        name: '',
        company_id : '',
        work_place : '',
        job_title : '',
        phone : '',
        email : '',
        address: '',
    }   
    private personID = '';
    private isLoading = false;
    private loadingIconColor = '#00b8d0';

    created () {
        this.getPeopleDetails();
        this.getIndustries();
        this.getCountries();
        this.addCompanies();
        // this.showPeopleOptions(true)
    }

    // unmounted () {
    //     // this.showPeopleOptions(false)
    // }

    private saveData() {
        if(this.currentData.name && this.industry) {
            for (let i = 0; i < this.countryList.length; i++) {
              const element = this.countryList[i];
              if(element.country === this.country) {
                this.currentData.country_id = element.id;
                break;
              }
            }

            for (let i = 0; i < this.industryList.length; i++) {
              const element = this.industryList[i];
              if(element.name === this.industry) {
                this.currentData.industry_id = element.id;
                break;
              }
            }

            this.addCompany();
          }
    }

    private clearData() {
      $('#modalCompany').modal('hide');
      this.country = "";
      this.industry = "";
      this.currentData = {
        name : "",
        phone : "",
        email : "",
        website : "",
        address : "",
        industry_id : "",
        country_id : "",
        status : false,
      };
    }

    private addCompany() {
      this.isLoading = true;
      addCompany(this.currentData).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          console.log('Add company is successful');
          this.Companies.push(response.data.company);
          this.personData.company_id = response.data.company.name;
          console.log("The prospects list value is: ", this.Companies);
          this.isLoading = false;
          this.clearData();
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
    }

    private getCountries() {
      getCountries().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          console.log('Get Prospects is fetched successfully');
          this.countryList = response.data.countries;
          console.log("The Countries list value is: ", this.countryList);
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', error);
      });
    }

    private getIndustries() {
      getIndustries().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          console.log('Get Industries is fetched successfully');
          this.industryList = response.data.industries;
          console.log("The Countries list value is: ", this.industryList);
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', error);
      });
    }



    private getPeopleDetails(){
        this.isLoading = true;
         getPeople().subscribe((response: any) =>{
            this.People = response.data.people
            this.isLoading = false;         
        })
    }


    private addCompanies(){
        getCompanies().subscribe((response:any)=>{
            this.Companies = response.data.companies;
            // console.log(this.Companies[0]);
            if (response.data.companies) {
                this.companyNames = response.data.companies.map(company => company.name);
            }
        })
    }

    // private beforeAddPeople(){
    //     this.personData = {name: '', company_id : '', work_place : '', job_title : '', phone : '', email : '',address: '',};
    // }
    private addNewPeople() {
        let company:any = this.Companies.filter((company: any) => company.name === this.personData.company_id)[0]
        let companyID = company.id;
        
        const userdata = {
            name: this.personData.name,
            company_id : companyID,
            work_place : this.personData.work_place,
            job_title : this.personData.job_title,
            phone : this.personData.phone,
            email : this.personData.email,
            address: this.personData.address,
        }
        
        this.isLoading = true;
        addPeople(userdata).subscribe((response: any) => {
            if(response.data.status == "success") {
            this.isLoading= false;
            $('#modalPeople').modal('hide');
            this.personData = {name: '', company_id : '', work_place : '', job_title : '', phone : '', email : '',address: '',};
                this.getPeopleDetails();
            Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: 'Person added successfully.',
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',
            });
            } else if (response.data.status == "error") {
                console.log(response.data);   
            this.isLoading = false;
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: 'Error! Please try again',
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }
	})
    }



    private updatePerson() {
        console.log(this.Companies);
        
        let company:any = this.Companies.filter((company: any) => company.name === this.personData.company_id)[0]
        let companyID = company.id;

        const userdata = {
            name: this.personData.name,
            company_id : companyID,
            work_place : this.personData.work_place,
            job_title : this.personData.job_title,
            phone : this.personData.phone,
            email : this.personData.email,
            address: this.personData.address,
        };

            this.isLoading = true;
            updatePeople(userdata,this.personID).subscribe((response: any) => {
            if(response.data.status == "success") {
                this.isLoading = false;
                $('#updatePersonModal').modal('hide')
                this.personData = {name: '', company_id : '', work_place : '', job_title : '', phone : '', email : '',address: '',};
                this.getPeopleDetails();
                Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                    id: 'toastAuthy', zindex: 99,  title: 'Details updated successfully.',
                    titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                    icon: 'fa fa-check',
                    iconColor: 'white', });
            } else if (response.data.status == "error") {
                this.isLoading = false;
                Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: "Details could not be updated",
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }
        })
    }

    private setPersonID (id: string, user: any) {
            this.personID = id;
            const { name,company_id,work_place,job_title,phone,email,address} = user;
            this.personData = { name,company_id,work_place,job_title,phone,email,address};
        }

    
    private removePerson(personid: any){
        Toast.question({
        timeout: 20000,
        close: false,
        overlay: false,
        id: 'question',
        zindex: 1000,
        title: '',
        message: 'Are you sure ?',
        position: 'center',
        buttons: [
            ['<button><b>YES</b></button>',  (instance, toast) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            
            this.isLoading = true
            deletePeople(personid).subscribe((response:any) =>{
                if (response.data.status =="success"){
                    this.isLoading = false;
                    this.getPeopleDetails();
                    Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                    id: 'toastAuthy', zindex: 99,  title: 'Person deleted!',
                    titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                    icon: 'fa fa-check',   
                    iconColor: 'white',});
                }else if(response.data.status=="error"){
                    this.isLoading = false;
                    Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                    id: 'toastAuthy', zindex: 99,  title: response.data.message,
                    titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                    icon: 'fa fa-check',   
                    iconColor: 'white',});
                }
            }) 
        }, true],
        ['<button>NO</button>', (instance: any, toast: any) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        }, event],
        ],
    });    
    }


}
