<template>
<div class="header pt-2">
     <!-- Logo -->
				<div class="header-left">
					<a href="javascript:;" class="logo">
						<img src="../assets/img/logo.png" alt="Logo">
					</a>
					<a href="index.html" class="logo logo-small">
						<img src="../assets/img/logo-small.png" alt="Logo" width="30" height="30">
					</a>
				</div>
				<!-- /Logo -->
				
				<!-- Sidebar Toggle -->
				<a href="javascript:void(0);" id="toggle_btn">
					<i class="fas fa-bars"></i>
				</a>
				<!-- /Sidebar Toggle -->
				
				<!-- Search -->
				<div class="top-nav-search">
					<form>
						<input type="text" class="form-control" placeholder="Search here">
						<button class="btn" type="submit"><i class="fas fa-search"></i></button>
					</form>
				</div>
				<!-- /Search -->
				
				<!-- Mobile Menu Toggle -->
				<a class="mobile_btn" id="mobile_btn">
					<i class="fas fa-bars"></i>
				</a>
				<!-- /Mobile Menu Toggle -->
				
				<!-- Header Menu -->
				<ul class="nav user-menu">
				
					<!-- Flag -->
					<!-- <li class="nav-item dropdown has-arrow flag-nav">
						<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button">
							<img src="../assets/img/flags/us.png" alt="" height="20"> <span>English</span>
						</a>
						<div class="dropdown-menu dropdown-menu-right">
							<a href="javascript:void(0);" class="dropdown-item">
								<img src="../assets/img/flags/us.png" alt="" height="16"> English
							</a>
							<a href="javascript:void(0);" class="dropdown-item">
								<img src="../assets/img/flags/fr.png" alt="" height="16"> French
							</a>
							<a href="javascript:void(0);" class="dropdown-item">
								<img src="../assets/img/flags/es.png" alt="" height="16"> Spanish
							</a>
							<a href="javascript:void(0);" class="dropdown-item">
								<img src="../assets/img/flags/de.png" alt="" height="16"> German
							</a>
						</div>
					</li> -->
					<!-- /Flag -->
					<div class="d-flex">
						<div class="dropdown d-inline-block d-lg-none ml-2">
							<button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i data-feather="grid"></i>
							</button>
							<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" aria-labelledby="page-header-search-dropdown">

								<form class="p-3">
									<div class="form-group m-0">
										<div class="input-group">
											<input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
											<div class="input-group-append">
												<button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div class="dropdown d-none d-lg-inline-block ml-1">
							<button type="button" class="btn header-item noti-icon waves-effect mt-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i data-feather="grid"></i>
							</button>
							<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="">
								<div class="px-lg-2">
									<div class="row no-gutters">
										<div class="col">
											<a class="dropdown-icon-item" href="#" data-toggle="modal" data-target="#modalLead">
												<img src="../assets/img/icons/lead.png" alt="Opportunity">
												<span>Opportunity</span>
											</a>
										</div>
										<div class="col">
											<a class="dropdown-icon-item" href="#" data-toggle="modal" data-target="#modalCompany">
												<img src="../assets/img/icons/company.png" alt="Company">
												<span>Company</span>
											</a>
										</div>
										<div class="col">
											<a class="dropdown-icon-item" href="#" data-toggle="modal" data-target="#modalPeople">
												<img src="../assets/img/icons/people.png" alt="People">
												<span>People</span>
											</a>
										</div>
									</div>
									<div class="row no-gutters">
										<div class="col">
											<a class="dropdown-icon-item" href="#" data-toggle="modal" data-target="#modalTask">
												<img src="../assets/img/icons/event.png" alt="Task">
												<span>Task</span>
											</a>
										</div>
										<div class="col">
											<a class="dropdown-icon-item" href="#" data-toggle="modal" data-target="#modalNote">
												<img src="../assets/img/icons/note.png" alt="Note">
												<span>Note</span>
											</a>
										</div>
										<div class="col">
											<a class="dropdown-icon-item" href="#" data-toggle="modal" data-target="#modalIssue">
												<img src="../assets/img/icons/issue.png" alt="Issue Log">
												<span>Issue Log</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="dropdown d-none d-lg-inline-block ml-1">
							<button type="button" class="btn header-item noti-icon waves-effect" data-toggle="fullscreen">
								<i data-feather="copy"></i>
							</button>
						</div> -->

					</div>

				
					<!-- Notifications -->
					<li class="nav-item dropdown">
						<a href="javascript:void(0);" class="dropdown-toggle nav-link mt-3" data-toggle="dropdown">
							<i data-feather="bell"></i> 
							<!-- <i class="fas fa-bell"></i>  -->
              <span class="badge badge-pill">5</span>
						</a>
						<div class="dropdown-menu notifications">
							<div class="topnav-dropdown-header">
								<span class="notification-title">Notifications</span>
								<a href="javascript:void(0)" class="clear-noti"> Clear All</a>
							</div>
							<div class="noti-content">
								<ul class="notification-list">
									<li class="notification-message">
										<a href="javascript:void(0);">
											<div class="media">
												<span class="avatar avatar-sm">
													<!-- <img class="avatar-img rounded-circle" alt="" src="../assets/img/profiles/avatar-02.jpg">
													<i data-feather="user" class="text-success"> </i> -->
															<div class="avatar avatar-sm">
													<span class="avatar-title rounded-circle bg-primary-light"><i class="far fa-user"></i></span>
												</div>
												</span>
												<div class="media-body">
													<p class="noti-details"><span class="noti-title">Brian Johnson</span> paid the invoice <span class="noti-title">#DF65485</span></p>
													<p class="noti-time"><span class="notification-time">4 mins ago</span></p>
												</div>
											</div>
										</a>
									</li>
									<li class="notification-message">
										<a href="javascript:void(0);">
											<div class="media">
												<span class="avatar avatar-sm">
													<!-- <img class="avatar-img rounded-circle" alt="" src="../assets/img/profiles/avatar-03.jpg"> -->
															<div class="avatar avatar-sm">
													<span class="avatar-title rounded-circle bg-primary-light"><i class="far fa-user"></i></span>
												</div>
													<!-- <i data-feather="user" class="text-success"> </i> -->
												</span>
												<div class="media-body">
													<p class="noti-details"><span class="noti-title">Marie Canales</span> has accepted your estimate <span class="noti-title">#GTR458789</span></p>
													<p class="noti-time"><span class="notification-time">6 mins ago</span></p>
												</div>
											</div>
										</a>
									</li>
									<li class="notification-message">
										<a href="javascript:void(0);">
											<div class="media">
												<div class="avatar avatar-sm">
													<span class="avatar-title rounded-circle bg-primary-light"><i class="far fa-user"></i></span>
												</div>
												<div class="media-body">
													<p class="noti-details"><span class="noti-title">New user registered</span></p>
													<p class="noti-time"><span class="notification-time">8 mins ago</span></p>
												</div>
											</div>
										</a>
									</li>
									<li class="notification-message">
										<a href="javascript:void(0);">
											<div class="media">
												<span class="avatar avatar-sm">
													<!-- <img class="avatar-img rounded-circle" alt="" src="../assets/img/profiles/avatar-04.jpg"> -->
													<!-- <i data-feather="user" class="text-success"> </i> -->
															<div class="avatar avatar-sm">
													<span class="avatar-title rounded-circle bg-success-light"><i class="far fa-user"></i></span>
												</div>
												</span>
												<div class="media-body">
													<p class="noti-details"><span class="noti-title">Barbara Moore</span> declined the invoice <span class="noti-title">#RDW026896</span></p>
													<p class="noti-time"><span class="notification-time">12 mins ago</span></p>
												</div>
											</div>
										</a>
									</li>
									<li class="notification-message">
										<a href="javascript:void(0);">
											<div class="media">
												<div class="avatar avatar-sm">
													<span class="avatar-title rounded-circle bg-info-light"><i class="far fa-comment"></i></span>
												</div>
												<div class="media-body">
													<p class="noti-details"><span class="noti-title">You have received a new message</span></p>
													<p class="noti-time"><span class="notification-time">2 days ago</span></p>
												</div>
											</div>
										</a>
									</li>
								</ul>
							</div>
							<div class="topnav-dropdown-footer">
								<a href="javascript:void(0);">View all Notifications</a>
							</div>
						</div>
					</li>
					<!-- /Notifications -->
					
					<!-- User Menu -->
					<li class="nav-item dropdown has-arrow main-drop">
						<a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
							<span class="user-img">
								<!-- <img src="../assets/img/profiles/avatar-01.jpg" alt=""> -->
								<i data-feather="user" class="text-info"> </i>
								<span class="status online"></span>
							</span>
							<span> Admin</span>
						</a>
						<div class="dropdown-menu">
							<a class="dropdown-item" href="javascript:void(0);"><i data-feather="user" class="mr-1"></i> Profile</a>
							<a class="dropdown-item" href="javascript:void(0);"><i data-feather="settings" class="mr-1"></i> Settings</a>
							<a class="dropdown-item" href="javascript:void(0);"  @click.prevent="userLogOut"><i data-feather="log-out" class="mr-1"></i> Logout</a>
						</div>
					</li>
					<!-- /User Menu -->
					
				</ul>
				<!-- /Header Menu -->
				
			</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {logOut } from '../store/api/usermgt'
@Options({
  props: {
    msg: String,
	showPeopleOptions: Boolean,
  }
})
export default class Header extends Vue {
  msg!: string


private userLogOut() {
	console.log('logout');
	
logOut().subscribe((res) => {
	console.log(res);
	this.$router.push('/login');
	localStorage.clear();
	
})
}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 
</style>
