<template>
    <div class="sidebar" id="sidebar" >
        <div class="sidebar-inner " style="overflow-y: scroll;" >
					<div class="sidebar-menu">
						<ul class="accordion" id="sidenavAccordion">
							<li class="menu-title"><span>OVERVIEW</span></li>
							<li>
								<router-link to="/app/" exact-active-class="active"><i data-feather="layers"></i><span>Dashboard</span></router-link>
							</li>
							<li >
								<router-link to="/app/" exact-active-class="active"><i data-feather="calendar"></i><span>Calendaring</span></router-link>
							</li>
							<li class="menu-title"> 
								<span>MAIN</span>
							</li>
							<li>
								<a  href="javascript:;" id="headingOne" data-toggle="collapse" data-target="#collapseOne">
									<i data-feather="filter"></i> 
									<span> Opportuinities</span> 
									<span class="menu-arrow"></span>
								</a>
								<div id="collapseOne" class="collapse" aria-labelledby="#headingOne" data-parent="#sidenavAccordion">
									<router-link to="/app/opportunity" exact-active-class="active"> Opportunity List </router-link>
									<router-link to="/app/pipeline" exact-active-class="active"> Sales Pipeline </router-link>
								</div>
							</li>
							<li>
								<router-link to="/app/people" exact-active-class="active"><i data-feather="users"></i> <span>People</span></router-link>
							</li>
							<li>
								<a  href="#" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo">
									<i data-feather="bar-chart-2"></i> 
									<span>Companies</span> 
									<span class="menu-arrow"></span>
								</a>
								<div id="collapseTwo" class="collapse" aria-labelledby="#headingTwo" data-parent="#sidenavAccordion">
									<router-link to="/app/prospects" exact-active-class="active"> Prospect List </router-link>
									<router-link to="/app/converted" exact-active-class="active"> Converted List </router-link>
								</div>
							</li>
							<li>
								<a href="javascript:;"><i data-feather="hexagon" class="text-danger"></i> <span>Support Manager</span></a>
							</li>
							<li>
								<a  href="#" id="headingThree" data-toggle="collapse" data-target="#collapseThree">
									<i data-feather="file-text"></i> 
									<span>Reports</span> 
									<span class="menu-arrow"></span>
								</a>
								<div id="collapseThree" class="collapse" aria-labelledby="#headingThree" data-parent="#sidenavAccordion">
									<a href="javascript:;">Advanced Report</a>
									<a href="javascript:;">Summary By Owner</a>
									<a href="javascript:;">Task Report</a>
									<a href="javascript:;">Note Report</a>
									<a href="javascript:;">Issue Log Report</a>
								</div>
							</li>
							<li class="menu-title"> 
								<span>SETTINGS</span>
							</li>
							<li>
								<a  href="#" id="headingFour" data-toggle="collapse" data-target="#collapseFour">
									<i data-feather="settings"></i> 
									<span>Configuration</span> 
									<span class="menu-arrow"></span>
								</a>
								<div id="collapseFour" class="collapse" aria-labelledby="#headingFour" data-parent="#sidenavAccordion">
									<router-link to="/app/products" exact-active-class="active"> Product </router-link>
									<router-link to="/app/productmodule" exact-active-class="active"> Product Module </router-link>
									<router-link to="/app/stages" exact-active-class="active"> Stages </router-link>
									<router-link to="/app/industry" exact-active-class="active">Industry</router-link>
									<router-link to="/app/lead-source" exact-active-class="active">Lead Source</router-link>
									<router-link to="/app/loss-reason" exact-active-class="active">Loss Reason</router-link>
									<router-link to="/app/onboarding-checklist" exact-active-class="active">Onboarding Checklist</router-link>
									<router-link to="/app/doc-template" exact-active-class="active"> Document Templates </router-link>
									<router-link to="/app/mail-template" exact-active-class="active"> Email Templates </router-link>
									<router-link to="/app/notification-group" exact-active-class="active"> Notification Group  </router-link>
								</div>
							</li>
							<li>
								<a  href="#" id="headingFive" data-toggle="collapse" data-target="#collapseFive">
									<i data-feather="user-plus"></i> 
									<span>User Management</span> 
									<span class="menu-arrow"></span>
								</a>
								<div id="collapseFive" class="collapse" aria-labelledby="#headingFive" data-parent="#sidenavAccordion">
									<router-link to="/app/usergroup" exact-active-class="active">User Group</router-link>
									<router-link to="/app/roles" exact-active-class="active">Roles & Permission</router-link>
									<router-link to="/app/users" exact-active-class="active"> Users</router-link>
									<a href="javascript:;">Audit Trail </a>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
// vue. use(VueSlimScroll);

@Options({
  props: {
    msg: String
  },
  directives: {
  }
})
export default class Sidenav extends Vue {
  msg!: string
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.sidebar-menu>ul>li>div>a {
		display: block;
		font-size: 15px;
		padding: 7px 10px 7px 45px;
		position: relative;
	}
</style>
