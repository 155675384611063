<template>
    <div class="content container-fluid">
 <loading v-model:active="isLoading"  :can-cancel="true"   :is-full-page="true"></loading> 
					<!-- Page Header -->
					<div class="page-header">
						<div class="row align-items-center">
							<div class="col">
								<!-- <h3 class="page-title">About this person</h3> -->
								<ul class="breadcrumb">
									<li class="breadcrumb-item"><a> Opportunity List </a></li>
									<li class="breadcrumb-item active"> Opportunity </li>
								</ul>
							</div>
						</div>
					</div>


                    <!-- <div class="col-sm-12"> -->
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="card">
                                    <div class="card-header px-2">
                                        <div class="container-fluid">
                                                <div class="info-div">
                                                        <img style="width : 100px; height : 100px; border-radius : 50%;" src="../assets/img/icons/agreement.png" alt="card-img">
                                                        <div class="info-body">
                                                            <span style="font-size : 18px">{{ oppData2?.company.name}}</span>
                                                            <span>{{oppData2?.product.name}}</span>
                                                            <!-- <span>{{CompanyData.email}}</span> -->
                                                        </div>
                                                        </div>
                                            <!-- <div class="d-flex align-items-start">
                                                <div class="profile-img d-flex align-items-center justify-content-center rounded-circle bg-secondary profile-box">
                                                    <h3 class="text-white m-0 p-0"> 
                                                        vvvvvvvvvvvvv 
                                                    </h3>
                                                </div>
                                                <div class="profile-details px-3">
                                                    <h3 class="profile-name fw-light">
                                                        {{ oppData2?.company.name }}
                                                    </h3>
                                                     <p class="work-title">
                                                        Title
                                                    </p> 
                                                    <p class="email">
                                                        {{ oppData2?.product.name }}
                                                    </p>
                                                </div>
                                                 
                                                 <div class="btn-toolbar" style="margin-left: auto">
                                                    <div class="btn-group btn-group-sm">
                                                        <button type="button" class="btn btn-outline-secondary">Expand All</button>
                                                        <button type="button" class="btn btn-outline-secondary">Collapse All</button>
                                                    </div>
                                                </div>  
                                            </div> -->
                                        </div>
                                        <div class="container-fluid">
                                            <div class="actions d-flex justify-content-center">
                                                <!-- Note -->
                                                <div class="action m-3 d-flex justify-content-center align-items-center flex-column">
                                                    <div class="contacts-icon">
                                                        <i class="fas fa-edit"></i>
                                                    </div>
                                                    <small>Note</small>
                                                </div>
                                                <!-- Email -->
                                                <div class="action m-3 d-flex justify-content-center align-items-center flex-column">
                                                    <div class="contacts-icon">
                                                        <i class="fas fa-envelope"></i>
                                                    </div>
                                                    <small>Email</small>
                                                </div>
                                                <!-- Call -->
                                                <div class="action m-3 d-flex justify-content-center align-items-center flex-column">
                                                    <div class="contacts-icon">
                                                        <i class="fas fa-phone-alt"></i>
                                                    </div>
                                                    <small>Call</small>
                                                </div>
                                                <!-- Log -->
                                                <div class="action m-3 d-flex justify-content-center align-items-center flex-column">
                                                    <div class="contacts-icon">
                                                        <i class="fas fa-plus"></i>
                                                    </div>
                                                    <small>Log</small>
                                                </div>
                                                <!-- Task -->
                                                <div class="action m-3 d-flex justify-content-center align-items-center flex-column">
                                                    <div class="contacts-icon">
                                                        <i class="fas fa-calendar"></i>
                                                    </div>
                                                    <small>Task</small>
                                                </div>
                                                <!-- Meet -->
                                                <div class="action m-3 d-flex justify-content-center align-items-center flex-column">
                                                    <div class="contacts-icon">
                                                        <i class="fas fa-calendar-alt"></i>
                                                    </div>
                                                    <small>Meet</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="container-fluid">
                                            <h5 class="my-2 text-dark" >Opportunity Details</h5>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="my-2">
                                                        <p> Opportunity Stage </p>
                                                        <p class="text-dark">{{ oppData2?.stage.name }}</p>
                                                    </div>
                                                    <div class="my-2">
                                                        <p>Opportunity Owner</p>
                                                        <p class="text-dark">{{ oppData2?.owner.first_name}} {{oppData2?.owner.last_name }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="my-2">
                                                        <p>Priority</p>
                                                        <p class="text-dark">{{ oppData?.priority }}</p>
                                                    </div>
                                                    <div class="my-2">
                                                        <p>Due Date</p>
                                                        <p class="text-dark">{{ oppData?.close_date.split('T')[0] }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="my-2">
                                                        <p>License Type </p> 
                                                        <p class="text-dark">{{ oppData?.license_type }}</p>
                                                    </div>
                                                    <div class="my-2">
                                                        <p>License Value</p>
                                                        <p class="text-dark"> &#x20A6;{{numberWithCommas(oppData?.license_value) }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="my-2">
                                                        <p>Priority</p>
                                                        <p class="text-dark">{{ oppData?.priority }}</p>
                                                    </div>
                                                    <div class="my-2">
                                                        <p>No of Users</p>
                                                        <p class="text-dark">{{ oppData?.number_of_users }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="my-2">
                                                        <p>Description</p>
                                                        <p class="text-dark">{{ oppData?.description}}</p>
                                                    </div>
                                           
                                                </div>
                                       
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="card bg-white shadow-none">
										<div class="card-body">
											<ul class="nav nav-tabs nav-tabs-bottom">
												<li class="nav-item"><a class="nav-link active" href="#bottom-tab1" data-toggle="tab">Activity</a></li>
												<li class="nav-item"><a class="nav-link" href="#notes" data-toggle="tab">Notes</a></li>
												<li class="nav-item"><a class="nav-link" href="#bottom-tab3" data-toggle="tab">Emails</a></li>
												<li class="nav-item"><a class="nav-link" href="#bottom-tab4" data-toggle="tab">Calls</a></li>
												<li class="nav-item"><a class="nav-link" href="#tasks" data-toggle="tab">Tasks</a></li>
												<li class="nav-item"><a class="nav-link" href="#bottom-tab6" data-toggle="tab">Meetings</a></li>
											</ul>

											<div class="tab-content">
												<div class="tab-pane show active" id="bottom-tab1">
													Activity Content
												</div>
                        <!-- notes tab -->
                           <div class="tab-pane fade" id="notes" role="tabpanel" aria-labelledby="notes-tab">
            <div style="display : flex; justify-content : space-between; align-items : center; margin-bottom : 15px; padding : 10px 0px;">
              <p style="font-size : 18px; margin : 0px">Notes</p>
              <button data-toggle="modal" data-target="#noteModal" class="btn btn-primary" style="padding-top : 7px; padding-bottom : 7px">Create Note</button>
            </div>
            <div>
              <div id="datatable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12">

                      <div class="card" v-for="data, index in oppNotes" :key="index">
                              <div class="card-body " style="background-color: #c7c9bb; border-radius:5px;">
                                <span  v-html="data.note"></span>
                                      <div class="float-right btn-group dropup">
                                             <a @click="setNoteEdit(data, index)" class=" m-2" data-toggle="modal" data-target="#noteModal" style="cursor : pointer;">
                                         <i class="fa fa-edit"> </i>
                                        
                                      </a>
                                      <a @click="_deleteNote(data.id, index)" class=" m-2 text-danger" href="#" style="cursor : pointer;">
                                        <i class="fa fa-trash"> </i>
                                        
                                      </a>
                                </div>
                              </div>
                            </div>
                           <div v-if="oppNotes.length == 0" class="mt-4 text-center">
                           <span colspan="5" class="mt-5 justify-around"> 
                             No Recorded Notes Yet, <span style="cursor:pointer;" class="text-success" data-toggle="modal" data-target="#noteModal"> Create a Note <i class="fa fa-sticky-note"> </i> </span>
                           </span>
                      </div>
                    <!-- <table id="datatable" class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px; width: 100%;" role="grid" aria-describedby="datatable_info">
                    <thead>
                        <tr role="row">
                          <th class="sorting_asc" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 163.2px;" aria-sort="ascending" aria-label="Task Title: activate to sort column descending">Note</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 118.2px;" aria-label="Task Owner: activate to sort column ascending">Note Source</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 102.2px;" aria-label="Status: activate to sort column ascending">Source Name</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 20.2px;" aria-label=": activate to sort column ascending"></th>
                        </tr>
                    </thead>
                    <tbody>                    
                      <tr v-for="data, index in oppNotes" :key="index" role="row" class="odd">
                            <td class="sorting_1 dtr-control">{{data.note}}</td>
                            <td>{{data.note_source}}</td>
                            <td>{{data.company.name}}</td>
                            <td>
                                <div class="btn-group dropup">
                                    <a class="font-size-18 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                    </a>
                                    <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                      <router-link :to="{name : 'opportunityInfo'}" class="dropdown-item text-info" style="cursor : pointer;"><i class="fa fa-eye"></i>
                                        View
                                      </router-link>
                                      <a @click="setNoteEdit(data, index)" class="dropdown-item" data-toggle="modal" data-target="#noteModal" style="cursor : pointer;">
                                         <i class="fa fa-edit"> </i>
                                        Edit
                                      </a>
                                      <a @click="_deleteNote(data.id, index)" class="dropdown-item text-danger" href="#" style="cursor : pointer;">
                                        <i class="fa fa-trash"> </i>
                                        Delete
                                      </a>
                                    </div>
                                </div>
                            </td>
                      </tr>
                              <tr v-if="oppNotes.length == 0" class="mt-4 text-center">
                           <td colspan="5" class="mt-5 justify-around"> 
                             No Recorded Notes Yet, <span style="cursor:pointer;" class="text-success" data-toggle="modal" data-target="#noteModal"> Create a Note <i class="fa fa-sticky-note"> </i> </span>
                           </td>
                      </tr>
                    </tbody>
                </table> -->
                </div>
                  </div>
                  <div class="row" style="margin-top : 15px">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing 1 to {{oppNotes.length}} of {{oppNotes.length}}</div>
                  </div>
                  <div style="display: flex; justify-content: flex-end;" class="col-sm-12 col-md-7"><div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                    <ul class="pagination"><li class="paginate_button page-item previous disabled" id="datatable_previous"><a href="#" aria-controls="datatable" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="datatable" data-dt-idx="1" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item next" id="datatable_next"><a href="#" aria-controls="datatable" data-dt-idx="4" tabindex="0" class="page-link">Next</a></li></ul>
                  </div>
                  </div>
                  </div>
              </div>
            </div>
          </div>
          <!-- notes end -->
												<div class="tab-pane" id="bottom-tab3">
													<p>Send Emails to a contact from this record</p>
												</div>
												<div class="tab-pane" id="bottom-tab4">
													Calls Content
												</div>
                            <div class="tab-pane fade" id="tasks" role="tabpanel" aria-labelledby="tasks-tab">
            <div style="display : flex; justify-content : space-between; align-items : center; margin-bottom : 15px; padding : 10px 0px;">
              <p style="font-size : 18px; margin : 0px">Opportunity Tasks </p>
              <button data-toggle="modal" data-target="#myModal" class="btn btn-primary" style="padding-top : 7px; padding-bottom : 7px">Create Task</button>
            </div>
            <div>
              <div id="datatable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12">
                      <table id="datatable" class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px; width: 100%;" role="grid" aria-describedby="datatable_info">
                    <thead>
                        <tr role="row">
                          <th class="sorting_asc" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 163.2px;" aria-sort="ascending" aria-label="Task Title: activate to sort column descending">Task Title</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 118.2px;" aria-label="Task Owner: activate to sort column ascending">Task Owner</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 102.2px;" aria-label="Status: activate to sort column ascending">Status</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 75.2px;" aria-label="Priority: activate to sort column ascending">Priority</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 75.2px;" aria-label="Date: activate to sort column ascending">Set Date</th>
                          <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 20.2px;" aria-label=": activate to sort column ascending"></th>
                        </tr>
                    </thead>
                    <tbody>                    
                      <tr v-for="data, index in oppTasks" :key="index" role="row" class="odd">
                            <td class="sorting_1 dtr-control">{{data.task_title}}</td>
                            <td>{{`${data.task_owner.first_name} ${data.task_owner.last_name}`}}</td>
                            <td>{{data.status}}</td>
                            <td>{{data.priority}}</td>
                            <td>{{new Date(data.start_date).toLocaleString()}}</td>
                            <td>
                                <div class="btn-group dropup">
                                    <a class="font-size-18 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                    </a>
                                    <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                      <router-link :to="{name : 'opportunityInfo'}" class="dropdown-item text-info" style="cursor : pointer;"><i class="fa fa-eye"></i>
                                        View
                                      </router-link>
                                      <a @click="setEdit(data, index)" class="dropdown-item" data-toggle="modal" data-target="#myModal" style="cursor : pointer;">
                                         <i class="fa fa-edit"> </i>
                                        Edit
                                      </a>
                                      <a @click="_deleteTask(data.id, index)" class="dropdown-item text-danger" href="javascript:;" style="cursor : pointer;">
                                        <i class="fa fa-trash"> </i>
                                        Delete
                                      </a>
                                    </div>
                                </div>
                            </td>
                      </tr>
                      <tr v-if="oppTasks.length == 0" class="pt-5 text-center">
                           <td colspan="5" class="mt-5 justify-around"> 
                             No Tasks Scheduled Yet, <span style="cursor:pointer;" class="text-success" data-toggle="modal" data-target="#myModal"> Create a New Task <i class="fa fa-rocket"> </i> </span>
                           </td>
                      </tr>
                    </tbody>
                </table>
                </div>
                  </div>
                  <div class="row" style="margin-top : 15px">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing 1 to {{oppTasks.length}} of {{oppTasks.length}}</div>
                  </div>
                  <div style="display: flex; justify-content: flex-end;" class="col-sm-12 col-md-7"><div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                    <ul class="pagination"><li class="paginate_button page-item previous disabled" id="datatable_previous"><a href="#" aria-controls="datatable" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="datatable" data-dt-idx="1" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item next" id="datatable_next"><a href="#" aria-controls="datatable" data-dt-idx="4" tabindex="0" class="page-link">Next</a></li></ul>
                  </div>
                  </div>
                  </div>
              </div>
            </div>
          </div>
												<div class="tab-pane" id="bottom-tab6">
                          <Calendar is-expanded :attributes="attrs"  />
                          <!-- <DatePicker v-model="dateInput" /> -->
												</div>
											</div>
										</div>
									</div>
                                    </div>
                                </div>
                            </div>
                            <!-- Side Panel -->
                            <div class="col-md-4">
                                <div class="card" style="min-height: 70%">
                                    <div class="card-body px-0">
                                    <div class="accordion" id="accordionSideBar">
                                        <div class="shadow-none border border-left-0 border-right-0 border-light">
                                            <div id="option1">
                                            <h2 class="mb-0">
                                                <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                                    <i class="fa fa-angle-right"></i>
                                                 Companies
                                                </button>
                                            </h2>
                                            </div>

                                            <div id="collapse1" class="collapse p-3" aria-labelledby="option1" data-parent="#accordionSideBar">
                                                <p>
                                                   
                                                </p>
                                            </div>
                                        </div>
                                        <div class="shadow-none border border-left-0 border-right-0 border-light">
                                            <div id="option2">
                                            <h2 class="mb-0">
                                                <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                                                    <i class="fa fa-angle-right"></i>
                                                People 
                                                </button>
                                            </h2>
                                            </div>

                                            <div id="collapse2" class="collapse p-3 " aria-labelledby="option2" data-parent="#accordionSideBar">
                                             <div >
                                                           <h4>
                                                  <span class="fa fa-user text-muted mx-2"></span>  {{oppData2?.contact.name}}
                                                </h4>
                                                <h5>
                                                   <span class="fa fa-envelope text-muted mx-2"></span>   {{oppData2?.contact.email}}
                                                </h5>
                                                <h5>
                                                   <span class="fa fa-phone-square text-muted mx-2"></span>   {{oppData2?.contact.phone}}
                                                </h5>
                                             </div>
                                    
                                            </div>
                                        </div>
                                        <div class="shadow-none border border-left-0 border-right-0 border-light">
                                            <div id="option3">
                                            <h2 class="mb-0">
                                                <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                                                    <i class="fa fa-angle-right"></i>
                                                 Issues
                                                </button>
                                            </h2>
                                            </div>

                                            <div id="collapse3" class="collapse p-3" aria-labelledby="option3" data-parent="#accordionSideBar">
                                                <p>
                                                    Some info for issues.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- </div> -->
                        <!--Start Task Modal -->
    <div class="modal right fade" id="myModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="background-color: #eeeeee;">
        <span @click="clearData" style="cursor: pointer;" class="closeModal"> </span>

            <div class="modal-header mb-0" style="background-color: #eeeeee;">
                    <div class=" ml-2 mr-0 mt-3">
                <h3> <i class="fa fa-tasks mr-2 "></i> Task</h3>
                </div>
            </div>
          <form @submit.prevent="saveData2">
            <div class="modal-body" data-select2-id="59">
                <!-- <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Company <span style="color : red;">*</span></label>
                            <select required v-model.trim="currentData.company_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                              <option value="" disabled >--Choose an option--</option>
                              <option v-for="data, index in companyList" :key="index" :value="data.id" >{{data.name}}</option>
                            </select>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                   
                            <label>Opportunity (not required for converted client) <span style="color : red;">*</span></label>
                            <select v-model.trim="currentData.opportunity_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                              <option value="" disabled >--Choose an option--</option>
                              <option v-for="data, index in opportunityList" :key="index" :value="data.id" >{{data.product.name}} ({{data.company.name}})</option>
                            </select>
                        </div>
                    </div>
                </div> -->
                <div class="form-group">
                    <label>Task Title <span style="color : red;">*</span></label>
                    <input required v-model.trim="currentData.task_title" type="text" class="form-control" placeholder="Enter Title">
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Task Type <span style="color : red;">*</span></label>
                            <select required v-model.trim="currentData.task_type_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                              <option value="" disabled >-- Select Task Type --</option>
                              <option v-for="data, index in taskTypes" :key="index" :value="data.id" >{{data.type}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Due date</label>
                            <input v-model.trim="currentData.end_date" type="date" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Task Priority <span style="color : red;">*</span></label>
                            <select required v-model.trim="currentData.priority" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                              <option value="" disabled >-- Set Task Priority --</option>
                              <option value="Low">Low</option>
                              <option value="Medium">Medium</option>
                              <option value="High">High</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                          <label>Status <span style="color : red;">*</span></label>
                          <select required v-model.trim="currentData.status" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                            <option value="" disabled >-- Choose an option --</option>
                            <option value="Not Started">Not Started</option>
                            <option value="In Progress">In Progress</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Abandoned">Abandoned</option>
                            <option value="Completed">Completed</option>
                          </select>
                      </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Task Owner</label>
                    <select required v-model="currentData.task_owner" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="22" tabindex="-1" aria-hidden="true">
                      <option value="" disabled >--Choose an option--</option>
                      <option v-for="data, index in usersList" :key="index" :value="data.id">{{data.first_name}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Task Description</label>
                    <div>
                        <textarea required v-model.trim="currentData.task_description" class="form-control" rows="2" placeholder="Enter Description"></textarea>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button @click="clearData" type="button" class="btn btn-light waves-effect">Discard</button>
                <button type="submit" class="btn btn-primary waves-effect waves-light">Save</button>
            </div>
          </form>
        </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- end Task modal -->

    <!--Start Note Modal -->
    <div class="modal right fade" id="noteModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="background-color: #eeeeee;">
        <span @click="clearNoteData" style="cursor: pointer;" class="closeModal"> </span>

            <div class="modal-header mb-0" style="background-color: #eeeeee;">
                    <div class=" ml-2 mr-0 mt-3">
                <h5> <i class="fa fa-sticky-note mr-2"></i> Note</h5>
                </div>
            </div>

          <form @submit.prevent="saveNoteData">
            <div class="modal-body" >
                <!-- <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Company <span style="color : red;">*</span></label>
                            <select required v-model.trim="noteData.company_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                              <option value="" disabled >--Choose an option--</option>
                              <option v-for="data, index in companyList" :key="index" :value="data.id">{{data.name}}</option>
                            </select>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                   
                            <label>Opportunity</label>
                            <select v-model.trim="noteData.opportunity_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                              <option value="" disabled >--Choose an option--</option>
                              <option v-for="data, index in opportunityList" :key="index" :value="data.id">{{data.product.name}}</option>
                            </select>
                        </div>
                    </div>
                </div> -->
                <div class="form-group">
                    <label>Note Description <span style="color : red;">*</span></label>
                    <div>
                        <!-- <textarea required v-model.trim="noteData.note" class="form-control" rows="1" placeholder="Enter Description"></textarea> -->
                         <vue-editor v-model="noteData.note" aria-placeholder="Enter Content Here"></vue-editor>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button @click="clearNoteData" type="button" class="btn btn-light waves-effect">Discard</button>
                <button type="submit" class="btn btn-primary waves-effect waves-light">Save</button>
            </div>
          </form>
        </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- end Task modal -->
    </div>
</template>


<script lang="ts">
import {Vue, Options} from 'vue-class-component';
import * as feather from 'feather-icons';
import {   getOpportunity, updateOpportunity, getCompanies, getOpportunities } from "@/store/api/opportunityApi";
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {addTasks, deleteTask, getTasks, getTaskTypes, getUsers, TaskData, updateTask} from "@/store/api/tasksApi";
import {addNote, deleteNote, getNotes, NoteData, updateNote} from "@/store/api/notesApi";
import $, {event} from "jquery";
import { VueEditor } from "vue3-editor";
import { Calendar, DatePicker } from 'v-calendar';

@Options({
    components :{ Loading, VueEditor, Calendar, DatePicker
    },
})


export default class OpportunityInfo extends Vue {
private oppData;
private dateInput = new Date();
private oppData2;
private isLoading = false;
private opportunityList : any[] = []; //eslint-disable-line
private companyList : any[] = []; //eslint-disable-line
private usersList : any[] = []; //eslint-disable-line
private taskTypes : any[] = []; //eslint-disable-line

  private editId = "";
  private editIndex = -1;
  private opportunity = "";
  private company = "";
  private taskOwner = "";
  private taskType = "";

  private noteEditId = "";
  private noteEditIndex = -1;
  private noteOpportunity = "";
  private noteCompany = "";

private oppTasks : Record<string, unknown>[] = [];
private oppNotes : Record<string, unknown>[] = [];

private currentData : TaskData = {
    task_owner : "",
    company_id : "",
    task_type_id : "",
    opportunity_id : "",
    task_title: "",
    task_description : "",
    priority: "",
    status: "Not Started",
    start_date : "",
    end_date : "",
    source_id : "",
    task_source : "Opportunity",
  };

  private noteData : NoteData = {
    note : "",
    company_id : "",
    opportunity_id : "",
    source_id : "",
    note_source : "Opportunity",
  }

private subscriptionList : any[] = [];
 private date = new Date();
 private year = this.date.getFullYear();
 private month = this.date.getMonth();
private attrs = [
  
      {
        key: 'today',
        highlight: {
          color: 'purple',
          fillMode: 'solid',
          contentClass: 'italic',
        },
        dates: new Date(this.year, this.month, 12),
      },
      {
        highlight: {
          color: 'purple',
          fillMode: 'light',
        },
        dates: new Date(this.year, this.month, 13),
      },
      {
        highlight: {
          color: 'purple',
          fillMode: 'outline',
        },
        dates: new Date(this.year, this.month, 14),
      },
    ];


    created():void {
        this.oppData = this.$route.params;
        this.getOpp(this.$route.params.id)
        feather.replace();
            this.getTasks();
            this.getTaskTypes();
            this.getCompanies();
            this.getOpportunities();
            this.getUsers();
            this.getNotes();
    }

    
    beforeUnmount() : void { 
      this.subscriptionList.forEach((subscription) => subscription.unsubscribe());
      // console.log('The unsubscription goes fine');
    }

private numberWithCommas(x: any) {
    if (!x) return 0;
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }


private setEdit(data, index) {
    const {task_title, task_description, task_owner, task_type_id, opportunity_id, 
      company_id, priority, status, start_date, end_date, source_id, task_source
    } = data;
    const editData = {task_title, task_description, task_owner : task_owner.id, task_type_id, opportunity_id,
      company_id, priority, status, start_date, end_date : new Date(end_date).toLocaleDateString(), source_id, task_source
    };
    this.currentData = editData;
    this.editId = data.id;
    this.editIndex = index;
    this.company = data.company.name || "";
    this.opportunity = data.opportunity.name || "";
    this.taskOwner = data.task_owner.first_name || "";
    this.taskType = data.task_type.type || ""
    console.log('The current edit is: ', data);
  }

  private setNoteEdit(data, index) {
    const {opportunity_id, company_id, note, source_id, note_source} = data;
    const editData = { opportunity_id, company_id, note, source_id, note_source};
    this.noteData = editData;
    this.noteEditId = data.id;
    this.noteEditIndex = index;
    this.noteCompany = data.company.name || "";
    this.noteOpportunity = data.opportunity.name || "";
    console.log('The current edit is: ', data);
  }

  private saveData2() {
    if(this.editId && this.currentData.task_title) {
      this._updateTask();
      // console.log('ciurr data UPPDATE', this.currentData);
    } else if(!this.editId && this.currentData.task_title) {
      this.currentData.start_date = new Date().toISOString();
      this.currentData.source_id = this.oppData.id;
      this.currentData.opportunity_id = this.oppData.id;
      this.currentData.company_id = this.oppData.company_id;

      this._addTask();
      // console.log('ciurr data ADD', this.currentData);
      
    }
  }

  private saveNoteData() {
    // this runs, if the data is an edited data.
    if(this.noteEditId && this.noteData.note) {
      
      this._updateNote();
    } else if(!this.noteEditId && this.noteData.note) {

      this.noteData.source_id = this.oppData.id;
      this.noteData.opportunity_id = this.oppData.id;
      this.noteData.company_id = this.oppData.company_id;

      this._addNote();
    }
  }


private getOpp(id: any){
      this.isLoading = true;
      const getSubscription = getOpportunity(id).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.oppData2 = response.data.opportunity;
          this.isLoading = false;
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

      private getCompanies() {
    const getSubscription = getCompanies().subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        this.companyList = response.data.companies;
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private getOpportunities() {
    const getSubscription = getOpportunities().subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        this.opportunityList = response.data.opportunity;
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', response.message);
      }
    });
    this.subscriptionList.push(getSubscription);
  }

  private getUsers() {
    const getSubscription = getUsers().subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('Get People is fetched successfully');
        this.usersList = response.data.users;
        console.log("The People list value is: ", this.usersList);
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private getTaskTypes() {
    const subscription = getTaskTypes().subscribe((response) => {
      if(response.data.status === 'success') {
        let responseData : Record<string, unknown>[] = response.data.types;
        this.taskTypes = responseData;
      } else {
        console.log('Error occured while fetching products', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });

    this.subscriptionList.push(subscription);
  }

  private getTasks() {
    const getSubscription = getTasks().subscribe((response) => {
      if(response.data.status === 'success') {
        let responseData : Record<string, unknown>[] = response.data.tasks;
        let taskList : any[] = []; //eslint-disable-line
        responseData.forEach((task) => {
          if(task.source_id === this.oppData.id) taskList.push(task);
        });
        this.oppTasks = taskList;
      } else {
        console.log('Error occured while fetching products', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });

    this.subscriptionList.push(getSubscription);
  }

    private _addTask() {
    this.isLoading = true;
    const subscription = addTasks(this.currentData).subscribe((response) => {
      if(response.data.status === 'success') {
        this.getTasks();
        this.isLoading = false;
        this.clearData();

        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        console.log('Error occured while adding task', response);
        this.isLoading = false;
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while adding data', error);
    });

    this.subscriptionList.push(subscription);
  }
    private _updateTask() {
    this.isLoading = true;
    const getSubscription =  updateTask(this.editId, this.currentData).subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        this.getTasks();
        // this.companyTasks.splice(this.editIndex, 1, response.data.company);
        this.isLoading = false;
        this.clearData();
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _deleteTask(taskId : string, index : number) {
    Toast.question({
      timeout: 20000,
      close: false,
      overlay: false,
      id: 'question',
      zindex: 1000,
      title: '',
      message: 'Are you sure ?',
      position: "topRight",
      buttons: [
          ['<button><b>YES</b></button>',  (instance, toast) => {
      instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        this.isLoading = true;
        const getSubscription = deleteTask(taskId).subscribe((response) => {
          if(response.data.status === 'success') {
            console.log('Company was deleted successfuly', response);
            this.oppTasks.splice(index, 1);
            Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-check',
              iconColor: 'white',
            });
          } else {
            console.log('Error occured while fetching data', response);
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-error',
              iconColor: 'white',
            });
          }

          this.isLoading = false;
        }, (error) => {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${error}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', error);
          this.isLoading = false;
        });
        this.subscriptionList.push(getSubscription);
      }, true],
      ['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
         }, event],
      ],
   
    });
  }
    private getNotes() {
    const getSubscription = getNotes().subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Notes Data is fetched successfully', response);
        let responseData : Record<string, unknown>[] = response.data.notes;
        let taskList : any[] = []; //eslint-disable-line
        responseData.forEach((task) => {
          if(task.source_id === this.oppData.id) taskList.push(task);
        });
        this.oppNotes = taskList;
      } else {
        console.log('Error occured while fetching products', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });

    this.subscriptionList.push(getSubscription);
  }

    private _addNote() {
    this.isLoading = true;
    const subscription = addNote(this.noteData).subscribe((response) => {
      if(response.data.status === 'success') {
        this.getNotes();
        this.isLoading = false;
        this.clearNoteData();

        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        console.log('Error occured while adding task', response);
        this.isLoading = false;
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while adding data', error);
    });

    this.subscriptionList.push(subscription);
  }

  private _updateNote() {
    this.isLoading = true;
    const getSubscription =  updateNote (this.noteEditId, this.noteData).subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        // console.log('update Task is successful');
        // console.log('The update response is: ', response);
        this.getNotes();
        this.isLoading = false;
        this.clearNoteData();
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _deleteNote(noteId : string, index : number) {
    Toast.question({
      timeout: 20000,
      close: false,
      overlay: false,
      id: 'question',
      zindex: 1000,
      title: '',
      message: 'Are you sure ?',
      position: "topRight",
      buttons: [
          ['<button><b>YES</b></button>',  (instance, toast) => {
      instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        this.isLoading = true;
        const getSubscription = deleteNote(noteId).subscribe((response) => {
          if(response.data.status === 'success') {
            console.log('Company was deleted successfuly', response);
            this.oppNotes.splice(index, 1);
            Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-check',
              iconColor: 'white',
            });
          } else {
            console.log('Error occured while fetching data', response);
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-error',
              iconColor: 'white',
            });
          }

          this.isLoading = false;
        }, (error) => {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${error}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', error);
          this.isLoading = false;
        });
        this.subscriptionList.push(getSubscription);
      }, true],
      ['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
         }, event],
      ],
   
    });
  }
    private clearData() {
    $('#myModal').modal('hide');
    this.editId = "";
    this.editIndex = -1
    // this.company = "";
    // this.opportunity = "";
    // this.taskOwner = "";
    // this.taskType = "";
    this.currentData = {
      task_owner : "",
      company_id : "",
      task_type_id : "",
      opportunity_id : "",
      task_title: "",
      task_description : "",
      priority: "",
      status: "Not Started",
      start_date : "",
      end_date : "",
      source_id : "",
      task_source : "Opportunity",
    };
  }

  private clearNoteData() {
    $('#noteModal').modal('hide');
    this.noteEditId = "";
    this.noteEditIndex = -1
    this.noteCompany = "";
    this.noteOpportunity = "";
    this.noteData = {
      note : "",
      company_id : "",
      opportunity_id : "",
      source_id : "",
      note_source : "Opportunity",
    };
  }
}
</script>

<style scoped>
    .profile-box{
        display: block;
        width: 50px;
        height: 50px;
    }

    .profile-box h3 {
        font-size: 120%;
    }
    .info-div {
    display: flex;
    align-items: center;
    background-color: transparent;
  }

  
  .info-body {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


    .contacts-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid;
        display: flex;
        align-items: center;
        justify-content: center;
        padding : 10px;
        margin-bottom: 10px;
        background-color: #eaf0f6;
        border-color: #cbd6e2;
        color: #506e91;
        cursor: pointer;
    }
</style>