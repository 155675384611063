<template>

<div class="content container-fluid">
  <loading v-model:active="isLoading"  :can-cancel="true"  ></loading> 
          <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="page-title">Opportunities Pipeline</h3>
          <ul class="breadcrumb">
            <li class="breadcrumb-item"><a>Main</a></li>
            <li class="breadcrumb-item active">Opportunity Pipeline</li>
          </ul>
        </div>
      </div>
    </div>

        <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                              <div id="app">
                                <div v-show="updateState" class="float-right spinner-border spinner-border-sm text-success" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                    <div class="flex justify-center">
                    
                      <div class="min-h-screen flex overflow-x-scroll py-12">
          
                        <div
                          v-for="stage in stagePipeline"
                          :key="stage.stageGroup.order_no"
                          class="bg-gray-300 rounded-lg px-2 py-3 column-width rounded mr-4"
                        >
                          <div class="d-flex justify-between" style="border-bottom: 1px solid grey;">
                          <div class="text-gray-900 font-semibold font-sans tracking-wide ">{{stage.stageGroup.name.toUpperCase()}} 
                            
                          </div>
                          <div class="text-muted badge badge-sm text-grey ">Drop Here</div>
                           <!-- badge badge-sm   badge-light -->
                          <div class=" badge badge-md py-2 badge-dark">&#x20A6;{{numberWithCommas(stage.totalTime)}}</div>
                        
                        </div>
                          <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
                          <draggable :list="stage.opportunityList" :animation="200" ghost-class="ghost-card" group="tasks"  @change="pipeUpdate(stage, $event)">
                            <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
                            <task-card
                              v-for="(opp) in stage.opportunityList"
                              :key="opp.id"
                              :opportunity="opp"
                              class="mt-3 cursor-move"
                            ></task-card>
                            <!-- </transition-group> -->
                          </draggable>


                        </div>
                      </div>
                    </div>

                    </div>
                </div>
            </div>
        </div> <!-- end col -->
        <!-- end col -->
    </div>
  
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { VueDraggableNext } from 'vue-draggable-next'
import TaskCard from "../components/TaskCard.vue";
import { getOpportunities, getCompanies, addOpportunity, deleteOpportunity, updateOpportunity } from "@/store/api/opportunityApi";
import { GetAllProducts, getStage  } from "@/store/api/configsettings";
import Toast from 'izitoast';
import Loading from 'vue-loading-overlay';

 @Options({
    components: {
    TaskCard,
    draggable: VueDraggableNext, Loading
    }
  })
  export default class SalesPipeline extends Vue {
private columns = [
        {
          title: "New",
          tasks: [
            {
              id: 1,
              title: "Add discount code to checkout page",
              date: "Sep 14",
              type: "High"
            },
            {
              id: 3,
              title: "Design shopping cart dropdown",
              date: "Sep 9",
              type: "Medium"
            },
            {
              id: 5,
              title: "Test checkout flow",
              date: "Sep 15",
              type: "Highiest"
            }
          ]
        },
        {
          title: "Engaged",
          tasks: [
            {
              id: 6,
              title: "Design shopping cart dropdown",
              date: "Sep 9",
              type: "Medium"
            },
            {
              id: 7,
              title: "Add discount code to checkout page",
              date: "Sep 14",
              type: "High"
            },
            {
              id: 8,
              title: "Provide documentation on integrations",
              date: "Sep 12",
              type: "Low"
            }
          ]
        },
        {
          title: "Won",
          tasks: [
            {
              id: 10,
              title: "Design shopping cart dropdown",
              date: "Sep 9",
              type: "Medium"
            },
            {
              id: 11,
              title: "Add discount code to checkout page",
              date: "Sep 14",
              type: "High"
            },
          ]
        },
        {
          title: "Lost",
          tasks: [
            {
              id: 14,
              title: "Add discount code to checkout page and more options for user coupons",
              date: "Sep 14",
              type: "High"
            },
            {
              id: 15,
              title: "Design shopping cart dropdown",
              date: "Sep 9",
              type: "Medium"
            }
          ]
        }
      ]
private isLoading = false;
private updateState = false;
private opportunityList: any[] = [];
private subscriptionList: any[] = [];
private stages: any[] = [];
private stagePipeline: any[] = [];

created() { //eslint-disable-line
      // this.getAllOpportunities();
      // this.getCountries();
      // this.getIndustries();
      // this.getAllProducts();
      // this.getCompanies();
      this.getStages();
      // this.getContacts();
      // this.getUsers();
    }
    private getAllOpportunities() {
      const getSubscription = getOpportunities().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.opportunityList = response.data.opportunity;
            this.stagePipeline.map((group: any) => {
            this.opportunityList.filter((act: any) => {
            if( act.stage.name === group.stageGroup.name) {
              group.opportunityList.push(act);
              const TotalTime = group.opportunityList.map((value: any) => {
                                          return value.license_value * 1
                                          }).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
                   group.totalTime = TotalTime        
                } 
                })
              })
          this.isLoading = false;
          // console.log(response.data, 'opportune', this.stagePipeline);   
        } 
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

      private getStages() {
      this.isLoading = true;
      const getSubscription = getStage().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.stages = response.data.stages;
          const stagePipeline: any = [];
         const checkMap = response.data.stages.map((app: string) => {
              const group = {
                  stageGroup: app,
                  totalTime: 0,
                  opportunityList: []
              }
              stagePipeline.push(group);  
          });
           this.stagePipeline = stagePipeline;  
           this.getAllOpportunities();
        }
      });
      this.subscriptionList.push(getSubscription);
    }


      private pipeUpdate(x: any, y: any) {
      // console.log(x, 'eeeeeeee,', y);
            this.stagePipeline.map((group: any) => {
       
            if( x.stageGroup.name === group.stageGroup.name) {
              // group.opportunityList.push(act);
              console.log(x ,'------', group);
              if( y.added) {
                // console.log(x, 'to be updated', y.added.element);
                this.updateOpportunityStage(x.stageGroup.id, y.added.element )
              }
              
              
              const TotalTime = group.opportunityList.map((value: any) => {
                                          return value.license_value * 1
                                          }).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
                   group.totalTime = TotalTime        
                } 
             
              })
            // this.stagePipeline.map((group: any) => {
            // this.opportunityList.filter((act: any) => {
            // if( act.stage.name === group.stageGroup.name) {
            //   group.opportunityList.push(act);
            //   const TotalTime = group.opportunityList.map((value: any) => {
            //                               return value.license_value * 1
            //                               }).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
            //        group.totalTime = TotalTime        
            //     } 
            //     })
            //   })
      
    }

        private numberWithCommas(x: any) {
    if (!x) return 0;
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

      private updateOpportunityStage(stageId: any, data: any) {
      // console.log( stageId, '--nnnn-', data);
      // const stageId = this.stages.find((stage) => {
      //     if(stage.name === stageName) {
      //       return stage.id
      //     }
      // });
      // console.log(stageId, 'stageId');
      let license_typeCode = 0;
      if(data.license_type == "Per User License" || data.license_type == '1') {
        license_typeCode = 1;
      } else {
        license_typeCode = 2;
      }
         const opportunityUpdate = {
         product_id: data.product_id,
        company_id: data.company_id,
        main_contact: data.main_contact,
        stage_id: stageId,
        opportunity_owner: data.opportunity_owner,
        close_date: data.close_date,
        priority: data.priority,
        deal_date: data.deal_date,
        license_type: license_typeCode,
        number_of_users: data.number_of_users,
        license_value: data.license_value,
        description: data.description,
    };
      console.log(opportunityUpdate, "OppUpdate");
      
        this.updateState = true;
      const getSubscription =  updateOpportunity(data.id, opportunityUpdate).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
        getOpportunities().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.opportunityList = response.data.opportunity;
        }})
         this.updateState = false;
 
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.updateState = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.updateState = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

}
</script>

<style scoped>
.column-width {
  min-width: 320px;
  width: 320px;
}
/* Unfortunately @apply cannot be setup in codesandbox, 
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}
</style>