import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import MainApp from '../views/mainapp.vue'
import AddUser from '../views/adduser.vue'
import Users from '../views/users.vue'
import UserGroup from '../views/usergroup.vue'
import Roles from '../views/roles.vue';
import MailTemplate from '../views/mailTemplate.vue';
import DocumentTemplate from '../views/docTemplate.vue';
import NotificationGroup from '../views/notificationGrp.vue';
import Industry from '../views/industry.vue';
import LeadSource from '../views/leadSource.vue';
import LossReason from '../views/lossReason.vue';
import OnboardingCL from '../views/onboarding.vue';
import Products from '../views/products.vue';
import ProductModule from '../views/productmodule.vue';
import Stages from '../views/stages.vue';
import Login from '../views/login.vue';
import People from '../views/people.vue';
import ProspectList from '../views/prospectList.vue';
import ConvertedList from '../views/convertedList.vue';
import OpportunityList from '../views/opportunityList.vue';
import ViewCompany from '../views/view_company.vue';
import peopleInfoView from '../views/peopleInfoView.vue';
import SalesPipeline from  '../views/salesPipeline.vue'
import OpportunityInfo from  '../views/opportunityInfo.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/app',
    name: 'Main',
    component: MainApp,
    children: [ 
        { path: '/app' , component: Products},
           { path: '/app/adduser', component:  AddUser},
           { path: '/app/usergroup', component:  UserGroup},
           { path: '/app/users', component:  Users},
           { path: '/app/roles', component:  Roles},
           { path: '/app/mail-template', component:  MailTemplate},
           { path: '/app/doc-template', component:  DocumentTemplate},
           { path: '/app/notification-group', component:  NotificationGroup},
           { path: '/app/industry', component:  Industry},
           { path: '/app/lead-source', component:  LeadSource},
           { path: '/app/loss-reason', component:  LossReason},
           { path: '/app/onboarding-checklist', component:  OnboardingCL},
           { path: '/app/products', component:  Products},
           { path: '/app/productmodule', component: ProductModule},
           { path: '/app/stages', component: Stages},
           { path: '/app/people', component: People},
           { path: '/app/prospects', component: ProspectList},
           { path: '/app/converted', component: ConvertedList},
           { path: '/app/opportunity', component: OpportunityList},
           { path: '/app/pipeline', component: SalesPipeline},
           { path: 'peopleInfoView', name: 'peopleInfoView',  component: peopleInfoView },
           { path: '/app/view_company', name : 'view_company', component: ViewCompany},
           { path: '/app/opportunityInfo', name : 'opportunityInfo', component: OpportunityInfo},
           { 
            path: 'peopleInfoView', 
            name: 'peopleInfoView', 
            component: peopleInfoView 
          },
    ]
  },
  { 
      path: '/login', 
      name: 'login', 
      component: Login 
  },
  { path: "/:catchAll(.*)", redirect: '/app' }
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
