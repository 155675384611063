import { ApiConfigService } from "./apiconfig";

export interface TaskData {
  company_id : string,
  opportunity_id ? : string,
  task_type_id : string,
  task_owner : string,
  task_title : string,
  start_date : string | Date,
  end_date? : string | Date,
  task_description : string,
  task_source : string,
  source_id : string,
  priority : string,
  status : string,
}

const API = new ApiConfigService();

export function getUsers() {
  return API.$Request().get(`/users/fetch`);
}

export function getTasks() {
  return API.$Request().get(`/tasks`);
}

export function getTaskTypes() {
  return API.$Request().get(`/task-types`);
}

export function addTasks(newData : TaskData) {
  return API.$Request().post(`/tasks/add-task`, newData);
}

export function getTask(dataId : string) {
  return API.$Request().get(`/tasks/view/${dataId}`);
}

export function updateTask(dataId : string, newData : TaskData) {
  return API.$Request().post(`/tasks/update/${dataId}`, newData);
}

export function deleteTask(dataId : string) {
  return API.$Request().delete(`/tasks/delete/${dataId}`);
}