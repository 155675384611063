<template>
	<loading v-model:active="isLoading"  :can-cancel="true"   :is-full-page="true"></loading> 
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="page-title">Onboarding Checklist</h3>
          <ul class="breadcrumb">
            <li class="breadcrumb-item"><a>Settings</a></li>
            <li class="breadcrumb-item active">Onboarding Checklist</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /Page Header -->
    <div class="row">
        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">List</h4>
                    <p class="card-title-desc">
                    </p>
                    <div id="datatable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer"><div class="row"><div class="col-sm-12 col-md-6"><div class="dataTables_length" id="datatable_length"><label>Show <select style="min-width: 50px" name="datatable_length" aria-controls="datatable" class="custom-select custom-select-sm form-control form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label></div></div><div class="col-sm-12 col-md-6"><div id="datatable_filter" class="dataTables_filter"><label>Search:<input type="search" class="form-control form-control-sm" placeholder="" aria-controls="datatable"></label></div></div></div><div class="row"><div class="col-sm-12"><table id="datatable" class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px; width: 100%;" role="grid" aria-describedby="datatable_info">
                        <thead>
                            <tr role="row"><th scope="col" class="sorting_asc" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 331.2px;" aria-label="Product: activate to sort column descending" aria-sort="ascending">Product</th><th scope="col" style="width: 19.2px;" class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label=": activate to sort column ascending"></th></tr>
                        </thead>
                        <tbody>
													<tr v-for="data, index in dataList" :key="index" role="row" class="odd">
																<td class="sorting_1 dtr-control" tabindex="0">{{data.name}}</td>
																<td>
																	<div class="btn-group dropup">
																		<button class="btn btn-light" @click="selectProduct(data)" >
																			view
																		</button>
																	</div>
																</td>
													</tr>
												</tbody>
                    </table></div></div><div class="row"><div class="col-sm-12 col-md-5"><div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing 1 to {{dataList.length}} of {{dataList.length}}</div></div><div class="col-sm-12 col-md-7"><div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate"><ul class="pagination"><li class="paginate_button page-item previous disabled" id="datatable_previous"><a href="#" aria-controls="datatable" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="datatable" data-dt-idx="1" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item next disabled" id="datatable_next"><a href="#" aria-controls="datatable" data-dt-idx="2" tabindex="0" class="page-link">Next</a></li></ul></div></div></div></div>

                </div>
            </div>
        </div> <!-- end col -->

        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">

                    <h4 class="card-title">Update Product</h4>
                    <p class="card-title-desc">
                    </p>
                    <div class="card bg-primary">
                        <div class="card-body">
                            <div class="col-sm-12">
                                <span class="text-white font-size-18"><b>{{currentProduct.name ? currentProduct.name : 'Select a Product'}}</b></span>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-bordered dt-responsive nowrap">
                            <thead>
                                <tr>
                                    <th style="width: 30px;">#</th>
                                    <th style="width: 100%;">Checklist Items</th>
                                    <th style="width: 10px;"></th>
                                </tr>
                            </thead>
                            <tbody>
															<tr v-for="item, index in currentProduct.checklist" :key="index">
																	<td>{{index + 1}}</td>
																	<td>
																		<a style="white-space: pre-line" href="#" id="inline-username" data-type="textarea" data-pk="1" data-placeholder="Field cannot be empty" data-title="Enter Item" class="editable editable-pre-wrapped editable-click">{{item.checklist}}</a>
																	</td>
																	<td>
																		<a @click="editData(item.checklist, item.id, index)" href="javascript:void(0); " data-toggle="modal" data-target="#myModal" class="text-info" style="margin-right: 5px; cursor : pointer">
																			<i class="fa fa-edit"> </i>
																		</a>
																		<a @click="deleteChecklist(item.id, item.product_id, index)" class="text-danger" style="margin-left: 5px; cursor : pointer">
																			<i class="fa fa-trash"> </i>
																		</a>
																	</td>
															</tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="padding-top: 10px" class="form-group">
                        <label>Checklist Name <span style="color : red"> *</span></label>
                        <div>
                            <input v-model.trim="inputData" type="text" class="form-control" required="" placeholder="Enter Checklist Name">
                        </div>
                        <br>
                        <button @click="handleSave" style="background-color: rgba(80,165,241,.25);
                          border-color: rgba(80,165,241,.25);
                          color: #50a5f1" type="button" class="btn btn-soft-info w-xs"
                        >Add to List</button>
                        <hr>
                    </div>


                    <div class="form-group mb-0">
                        <div class="text-right">
                            <button type="reset" class="btn btn-light waves-effect mr-1">
                                Cancel
                            </button>
                            <button @click="handleSave('add')" type="submit" class="btn btn-primary waves-effect waves-light">
                                Save
                            </button>

                        </div>
                    </div>

                </div>
            </div>
        </div> <!-- end col -->
    </div>
		<!--Start Modal -->
		<div class="modal right fade" id="myModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
				<div class="modal-dialog" role="document">
				<div class="modal-content" style="background-color: #eeeeee;">
				<span class="closeModal" data-dismiss="modal"  > </span>

						<div class="modal-header mb-0" style="background-color: #eeeeee;">
										<div class=" ml-4 mr-0 mt-3">
								<h5> Update Checklist</h5>
								</div>
						</div>
								<div class="modal-body">
										<div  class="element-wrapper" >
												<div class="element-box">			 						 
								<form @submit.prevent="editedName && handleSave('edit')" class="p-3">
										<div class="form-group">
												<label class="form-control-label">Checklist item</label>
												<input class="form-control" type="text" v-model.trim="editedName">
										</div>
										<div class="form-group mb-0">
												<button class="btn btn-lg btn-block btn-primary" :disabled="isUpdating">
														<pulse-loader v-if="isUpdating" :loading="isUpdating" color="white" size="10px" style="display: inline-block"></pulse-loader>
														{{isUpdating ? 'Updating' : 'Update'}} 
												</button>
										</div>
								</form>
												</div>
						</div>
						</div>

				</div><!-- modal-content -->
				</div><!-- modal-dialog -->
		</div>
  </div>
</template>

<script lang="ts">
import {Vue, Options} from 'vue-class-component';
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {addChecklist, deleteChecklist, getChecklists, getProducts, updateChecklist} from '@/store/api/onboardingApi';
import $, {event} from 'jquery';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

@Options({
	components: {
		Loading,
		PulseLoader
	}
})
/* eslint-disable */
export default class OnboardingCL extends Vue {
	private isLoading = false;
	private isUpdating = false;
	private subscriptionList : any[] = [];
	private dataList : Record<string, any>[] = [];
	private checklistData = []
	private inputData = '';
	private editedName = '';
	private editId = '';
	private editIndex = 0;
	private currentProduct : any = {};

	created() { //eslint-disable-line
		this.getProducts()
		console.log('lifecycle hook is called');
	}

	beforeUnmount() : void { 
		this.subscriptionList.forEach((subscription) => subscription.unsubscribe())
		console.log('The unsubscription goes fine');
	}

	private selectProduct(product : {checklist: {}}) {
		this.currentProduct = product;
		this.currentProduct.checklist = product.checklist;
		console.log('Select Product fn is called');
	}

	private editData (name : string, dataId : string, index : number) {
		this.editedName = name;
		this.editId = dataId;
		this.editIndex = index;
	}

	private handleSave(btnType : string) {
		if(btnType === 'edit' && this.editId && this.editedName) {
			this.isUpdating = true
			this.updateChecklist({dataId : this.editId, dataUpdate: {product_id : this.currentProduct.id, checklist : this.editedName}});
		} else if(btnType === 'add' && this.inputData && this.currentProduct.id){
			this.addChecklist({product_id : this.currentProduct.id, checklist : this.inputData});
		}
	}

	private getProducts() {
		this.isLoading = true;
		const getSubscription = getProducts().subscribe((response) => {
			if(response.data.status === 'success') {
				console.log('Data was fetched successfuly', response);
				let responseData : Record<string, unknown>[] = response.data.products;
				const newResponse = responseData.map((data) => {
					const updateData = {...data, checklist : []};
					return updateData;
				})

				console.log('The edited response data is', responseData);
				this.dataList = newResponse;
				console.log('Products list is: ', this.dataList);
				this.getChecklists()
			} else {
				console.log('Error occured while fetching products', response);
				Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-error',
					iconColor: 'white',
				});
			}

			this.isLoading = false;
		}, (error) => {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
				id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
				titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
				icon: 'fa fa-error',
				iconColor: 'white',
			});
			console.log('Error occured while fetching data', error);
			this.isLoading = false;
		});
		this.subscriptionList.push(getSubscription)
	}

	private getChecklists() {
		this.isLoading = true;
		const getSubscription = getChecklists().subscribe((response) => {
			if(response.data.status === 'success') {
				console.log('Data was fetched successfuly', response);
				
				const checklists = response.data.checklists;
				for (let i = 0; i < checklists.length; i++) {
					const item = checklists[i];
					for (let i = 0; i < this.dataList.length; i++) {
						const productItem = this.dataList[i];
						if(productItem.id === item.product_id) {
							const productChecklist : Record<string, unknown>[] = this.dataList[i].checklist as []
							productChecklist.push(item);
							break;
						}
					}
				}
				console.log('Checklist list value is: ', this.dataList);
			} else {
				console.log('Error occured while fetching checklists', response);
				Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-error',
					iconColor: 'white',
				});
			}

			this.isLoading = false;
		}, (error) => {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
				id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
				titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
				icon: 'fa fa-error',
				iconColor: 'white',
			});
			console.log('Error occured while fetching data', error);
			this.isLoading = false;
		});
		this.subscriptionList.push(getSubscription)
	}

	private addChecklist(newData : {product_id : string, checklist: string}) {
		this.isLoading = true;
		const getSubscription = addChecklist(newData).subscribe((response) => {
			if(response.data.status === 'success') {
				console.log('Add Data was fetched successfuly', response);
				
				for (let i = 0; i < this.dataList.length; i++) {
					const product = this.dataList[i] as {checklist : [], id : string};
					if (product.id === newData.product_id) {
						this.dataList[i].checklist.push(response.data.checklist);
						break;
					}
				}
				this.inputData = '';
				Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-check',
					iconColor: 'white',
				});
			} else {
				console.log('Error occured while adding data', response);
				Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-error',
					iconColor: 'white',
				});
			}

			this.isLoading = false;
		}, (error) => {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
				id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
				titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
				icon: 'fa fa-error',
				iconColor: 'white',
			});
			console.log('Error occured while fetching data', error);
			this.isLoading = false;
		})
		this.subscriptionList.push(getSubscription);
	}

	private updateChecklist(updateData : {dataId : string, dataUpdate : {product_id : string, checklist : string}}) {
		const {dataId, dataUpdate} = updateData;
		const getSubscription = updateChecklist(dataId, dataUpdate).subscribe((response) => {
			if(response.data.status === 'success') {
				console.log('Data was updated successfuly', response);

				for (let i = 0; i < this.dataList.length; i++) {
					const product = this.dataList[i] as {checklist : [], id : string};
					if (product.id === dataUpdate.product_id) {
						this.dataList[i].checklist.splice(this.editIndex, 1, response.data.checklist);
						break;
					}
				}
				$('#myModal').modal('hide');
				this.editedName = '';
				this.editId = '';
				Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-check',
					iconColor: 'white',
				});
			} else {
				console.log('Error occured while updating data', response);
				Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-error',
					iconColor: 'white',
				});
			}
			
			this.isUpdating = false;
		}, (error) => {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
				id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
				titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
				icon: 'fa fa-error',
				iconColor: 'white',
			});
			console.log('Error occured while fetching data', error);
			this.isUpdating = false;
		});
		this.subscriptionList.push(getSubscription);
	}

	private deleteChecklist(dataId : string, product_id: string, index : number) {
		Toast.question({
			timeout: 20000,
			close: false,
			overlay: false,
			id: 'question',
			zindex: 1000,
			title: '',
			message: 'Are you sure ?',
			position: "topRight",
			buttons: [
					['<button><b>YES</b></button>',  (instance, toast) => {
			instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
				this.isLoading = true;
				const getSubscription = deleteChecklist(dataId).subscribe((response) => {
					if(response.data.status === 'success') {
						console.log('Data was deleted successfuly', response);

						for (let i = 0; i < this.dataList.length; i++) {
							const product = this.dataList[i] as {checklist : [], id : string};
							if (product.id === product_id) {
								this.dataList[i].checklist.splice(index, 1);
								break;
							}
						}
						Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
							id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-check',
							iconColor: 'white',
						});
					} else {
						console.log('Error occured while deleting data', response);
						Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
							id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-error',
							iconColor: 'white',
						});
					}
					this.isLoading = false;
				}, (error) => {
					Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
						id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
						titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
						icon: 'fa fa-error',
						iconColor: 'white',
					});
					console.log('Error occured while fetching data', error);
					this.isLoading = false;
				});
		
				this.subscriptionList.push(getSubscription);
			}, true],
			['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
							instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
				 }, event],
			],
   
		});
	}
}
</script>

<style scoped>
.closeModal {
	position: absolute;
	right: 32px;
	top: 32px;
	width: 32px;
	height: 32px;
	opacity: 0.3;
}
.closeModal:hover {
	opacity: 1;
}
.closeModal:before, .closeModal:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 33px;
	width: 2px;
	background-color: #333;
}
.closeModal:before {
	transform: rotate(45deg);
}
.closeModal:after {
	transform: rotate(-45deg);
}
</style>
