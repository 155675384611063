
import { Vue, Options } from "vue-class-component";
import { addPermissionsToRole, addRole, deleteRole, editRole, getPermissions, getRoles } from "@/store/api/rolesApi";
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import $, {event} from 'jquery';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

@Options({
  components: {
    Loading,
    PulseLoader
  }
})

export default class Roles extends Vue {
  private permissionsList : string[] = [];
  private rolesList : string[] = [];
  private isLoading = false;
  private inputData = '';
  private isUpdating = false;
  private editedName = '';
  private subscriptionList : any[] = []; //eslint-disable-line
  private approvedList = {} //eslint-disable-line
  private editId  = '';
  private editIndex = 0;

  created() { //eslint-disable-line
    this.getPermissionsList()
  }

  beforeUnmount() { //eslint-disable-line
    this.subscriptionList.forEach((subscription) => subscription.unsubscribe());
  }

  private editData(name, roleId, index) {
    this.editedName = name;
    this.editId = roleId;
    this.editIndex = index;
  }

  private handleSubmit(roleId : string, roleName : string) {
    console.log('Handle submit is called:', roleId, roleName);
    this.addRolePermissions(roleId, this.approvedList[`${roleId}`]);
  }

  private handleSave(btnType : string) {
    if(btnType === 'edit' && this.editId && this.editedName) {
      this.isUpdating = true;
      this.updateRole(this.editId, this.editedName, this.editIndex)
      console.log('Is updating? ', this.isUpdating);
    } else if(btnType === 'add' && this.inputData) {
      this.addRole(this.inputData);
    }
  }

  private cancelSave() {
    this.inputData = '';
    this.editId = '';
    this.editIndex = 0;
  }

  private getPermissionsList() {
    this.isLoading = true;
    const getSubscription = getPermissions().subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('Get permissions is fetched successfully');
        const responseData = response.data.permissions as Record<string, string>[];
        const listData = responseData.map((data) => data.name)
        this.permissionsList = listData;
        console.log('The permissions list value is: ', listData);
        this.getRoles();
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', response.message);
      }

    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Fetch Permissions error: ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
      this.isLoading = false;
    });
    this.subscriptionList.push(getSubscription);
  }

  private getRoles() {
    this.isLoading = true;
    const getSubscription = getRoles().subscribe((response) => {
      if(response && response.data.status === 'success') {
        console.log('Get Data was fetched successfuly', response);
        let updatedList : string[] = [];
        for (let i = 0; i < response.data.roles.length; i++) {
          const element = response.data.roles[i];
          if(element.name === 'Admin') {
            continue;
          } else {
            updatedList.push(element);
            const permissionsList = element.permissions.map((permission) => {
              return permission.name;
            })
            console.log('Permissions list is: ', permissionsList);
            this.approvedList[`${element.id}`] = permissionsList ? permissionsList : [];
          }
        }
        this.rolesList = updatedList;
        
        console.log('Roles list value is: ', this.rolesList);
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching getIndustries', response);
      }

      this.isLoading = false;
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
      this.isLoading = false;
    });
    this.subscriptionList.push(getSubscription);
  }
  
  private addRole(name : string) {
    this.isLoading = true;
    console.log(name);
    const getSubscription = addRole({name}).subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Add Data was fetched successfuly', response);
        this.rolesList.push(response.data.role)
        this.approvedList[`${response.data.role.id}`] = [];
        this.inputData = '';
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        console.log('Error occured while fetching data', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }

      this.isLoading = false;
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
      this.isLoading = false;
    });
    this.subscriptionList.push(getSubscription);
  }

  private updateRole(roleId : string, newInput : string, index : number) {
    const getSubscription = editRole(roleId, {name : newInput}).subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Role was updated successfuly', response);
        this.rolesList.splice(index, 1, response.data.role);
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
        $('#myModal').modal('hide');
        this.isUpdating = false;
        this.editedName = '';
        this.editId = '';
      } else {
        console.log('Error occured while fetching data', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
      this.isUpdating = false;
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
      this.isUpdating = false;
    });
    this.subscriptionList.push(getSubscription);
  }

  private deleteRole(roleId : string, index : number) {
    Toast.question({
      timeout: 20000,
      close: false,
      overlay: false,
      id: 'question',
      zindex: 1000,
      title: '',
      message: 'Are you sure ?',
      position: "topRight",
      buttons: [
          ['<button><b>YES</b></button>',  (instance, toast) => {
      instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        this.isLoading = true;
        const getSubscription = deleteRole(roleId).subscribe((response) => {
          if(response.data.status === 'success') {
            console.log('Role was deleted successfuly', response);
            this.rolesList.splice(index, 1);
            Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-check',
              iconColor: 'white',
            });
          } else {
            console.log('Error occured while fetching data', response);
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-error',
              iconColor: 'white',
            });
          }

          this.isLoading = false;
        }, (error) => {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${error}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', error);
          this.isLoading = false;
        });
        this.subscriptionList.push(getSubscription);
      }, true],
      ['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
         }, event],
      ],
   
    });
  }

  private addRolePermissions(role : string, permissions : string[]) {
    this.isLoading = true;
    console.log('The permisions and role Id are', role, permissions);
    const getSubscription = addPermissionsToRole(role, permissions).subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Permissions were added successfuly', response);
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        console.log('Error occured while adding permissions to role', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }

      this.isLoading = false;
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
      this.isLoading = false;
    });
    this.subscriptionList.push(getSubscription);

  }
}
