<template>
  <div>

  <router-view/>
  </div>
</template>

<script lang="ts">
import * as $ from 'jquery';
import { Options, Vue } from 'vue-class-component';
import { VueEasyJwt } from 'vue-easy-jwt';
// import SettingsBar from '@/components/SettingsBar.vue';
// import NavBar from '@/components/NavBar.vue';
// import user from './store/modules/user';
@Options({
  components: {
    // Header, Sidenav
  }
})
export default class MainApp extends Vue {
  private userLogin = false;
  private token = localStorage.getItem('token');
  private user = localStorage.getItem('user');
  private jwt = new VueEasyJwt();
  // private created() {
  //     const UserLogin = user.userLogin;
  //     if (UserLogin !== true) {
  //       return this.$router.push('/login');
  //   }
  // }

   mounted(): void {
      this.IsActive();
  }

  private IsActive() {
      const token: any = this.token;
      if (token) {
        const exp = this.jwt.decodeToken(token);
        const today = Math.floor(new Date().getTime() / 1000);
        // if ( exp < today) {
        // user.emptyStoreUserToken();
        // this.$router.push('/login');
        // }
      } else {
         this.$router.push('/login');
         localStorage.clear();
      }
  }
}
</script>
