import axios from 'axios';
import Axios from 'axios-observable';

const auth = 'Authorization';

export class ApiConfigService {

    public $Request(): any {
        return Axios.create({
            baseURL: this.baseUrl(),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
                // `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9jaWtpYXBpLTNtbmFrLm9uZGlnaXRhbG9jZWFuLmFwcFwvYXBpXC9hdXRoXC9sb2dpbiIsImlhdCI6MTYyNDU0MzYzNCwibmJmIjoxNjI0NTQzNjM0LCJqdGkiOiJUWnNHeGVOWGoxaHAwOWNxIiwic3ViIjoiNjkxYWY2NWEtMzU5NS00NGYzLTg2NDQtYjUzNzhiYzEzZjhmIiwicHJ2IjoiODdlMGFmMWVmOWZkMTU4MTJmZGVjOTcxNTNhMTRlMGIwNDc1NDZhYSJ9.mwWnez-twQea3-eLwzYpK5o8xK_ANstebFr2-KszqGA`
            }
        });
    }
    // public $Request2() {
    //     return Axios.create({
    //         baseURL: this.baseUrl(),
    //         headers: this.setHeaderAuthorization(),
    //     });
    // }

    public Request() {
        return axios.create({
            baseURL: this.baseUrl(),
            headers: this.setHeaderAuthorization(),
        });
    }

    public LoginRequest() {
        return axios.create({
            baseURL: this.baseUrl(),
        });
    }

    public $LoginRequest() {
        return Axios.create({
            baseURL: this.baseUrl(),
        });
    }

    public setToken() {
        this.Request().defaults.headers.common[auth] = this.bearerToken();
    }

    public $setToken() {
        this.$Request().defaults.headers.common[auth] = this.bearerToken();
    }

    public deleteToken() {
        delete this.Request().defaults.headers.common[auth];
    }

    public $deleteToken() {
        delete this.$Request().defaults.headers.common[auth];
    }

    private baseUrl() {
        return 'https://cikiapi-3mnak.ondigitalocean.app/api';
    }

    private setHeaderAuthorization() {
        return { 
            Accept: 'application/json',
            ContentType: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}` 
        };
    }

    private bearerToken() {
        return `Bearer ${localStorage.getItem('token')}`;
    }
}