import {ApiConfigService} from './apiconfig';

const API = new ApiConfigService();

export function getTaskType(){
    return API.$Request().get('/task-types');
}

export function getTasks(){
    return API.$Request().get('/tasks');
}

export function addTask(task: any){
    return API.$Request().post('/tasks/add-task', task);
}

export function updateTask(task: any, taskid:string){
    return API.$Request().post(`/tasks/update/${taskid}`, task);
}

export function deleteTask(taskid:string){
    return API.$Request().delete(`/tasks/delete/${taskid}`);
}