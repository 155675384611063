import { ApiConfigService } from "./apiconfig";

export interface NoteData {
  company_id : string,
  opportunity_id ? : string,
  note : string,
  note_source : string,
  source_id : string,
}

const API = new ApiConfigService();

export function getNotes() {
  return API.$Request().get(`/notes`);
}

export function addNote(newData : NoteData) {
  return API.$Request().post(`/notes/add-note`, newData);
}

export function updateNote(dataId : string, newData : NoteData) {
  return API.$Request().post(`/notes/update/${dataId}`, newData);
}

export function deleteNote(dataId : string) {
  return API.$Request().delete(`/notes/delete/${dataId}`);
}