<template>
    <loading :active="isLoading"  :can-cancel="true"   :is-full-page="true"></loading>
    <div class="content container-fluid">
    <!-- start page title -->
        <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="page-title">Product Module Setting</h3>
          <ul class="breadcrumb">
            <li class="breadcrumb-item"><a>Settings</a></li>
            <li class="breadcrumb-item active">Product Module</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-7">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">List</h4>
                    <p class="card-title-desc">
                    </p>
                    <div id="datatable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer"><div class="row"><div class="col-sm-12 col-md-6"><div class="dataTables_length" id="datatable_length"><label>Show <select name="datatable_length" aria-controls="datatable" class="custom-select custom-select-sm form-control form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label></div></div><div class="col-sm-12 col-md-6"><div id="datatable_filter" class="dataTables_filter"><label>Search:<input type="search" class="form-control form-control-sm" placeholder="" aria-controls="datatable"></label></div></div></div><div class="row"><div class="col-sm-12"><table id="datatable" class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px; width: 100%;" role="grid" aria-describedby="datatable_info">
                        <thead>
                            <tr role="row"><th scope="col" class="sorting_asc" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 424.2px;" aria-sort="ascending" aria-label="Product Module: activate to sort column descending">Product Module</th><th scope="col" class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 294.2px;" aria-label="Product: activate to sort column ascending">Product</th><th scope="col" style="width: 20.2px;" class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label=": activate to sort column ascending"></th></tr>
                        </thead>
                        <tbody>
                 
                        <tr v-for="(productModule, i) in productModules" :key="i" role="row" class="odd">
                                <td class="sorting_1 dtr-control">{{productModule.module}}</td>
                                <td> {{productModule.product.name}}</td>
                                <td>
                                    <div class="btn-group dropup">
                                         <a class="font-size-18 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                        </a>
                                        <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                            <a @click="setProductModuleID(productModule.id, productModule.module)" href="javascript:void(0); " data-toggle="modal" data-target="#myModal" class="dropdown-item"><i class="fa fa-edit" data-v-f0ceeb32=""></i>Edit</a>
                                            <a @click="removeProductmodules(productModule.id)" class="dropdown-item text-danger" href="#"><i class="fa fa-trash" data-v-f0ceeb32=""></i>Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                    </table></div></div><div class="row"><div class="col-sm-12 col-md-5"><div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing 1 to 10 of 27 entries</div></div><div class="col-sm-12 col-md-7"><div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate"><ul class="pagination"><li class="paginate_button page-item previous disabled" id="datatable_previous"><a href="#" aria-controls="datatable" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="datatable" data-dt-idx="1" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item "><a href="#" aria-controls="datatable" data-dt-idx="2" tabindex="0" class="page-link">2</a></li><li class="paginate_button page-item "><a href="#" aria-controls="datatable" data-dt-idx="3" tabindex="0" class="page-link">3</a></li><li class="paginate_button page-item next" id="datatable_next"><a href="#" aria-controls="datatable" data-dt-idx="4" tabindex="0" class="page-link">Next</a></li></ul></div></div></div></div>

                </div>
            </div>
        </div> <!-- end col -->

        <div class="col-xl-5">
            <div class="card">
                <div class="card-body">

                    <h4 class="card-title">Add Module</h4>
                    <p class="card-title-desc">
                    </p>
                    <div class="form-group">
                        <label class="required">Product</label>
                        <div>
                            <vue-select v-model="selectedOption" :options="productNames" close-on-select required></vue-select> 
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="required">Product Module </label>
                        <div>
                            <input v-model.trim="newProductModuleName" type="text" class="form-control" required placeholder="Enter Product Module">
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <div class="text-right">
                            <button type="reset" class="btn btn-light waves-effect mr-1">
                                Cancel
                            </button>
                            <button @click="addProductModules(newProductModuleName, selectedProductID)" type="submit" class="btn btn-primary waves-effect waves-light">
                                Save
                            </button>

                        </div>
                    </div>

                </div>
            </div>
        </div> <!-- end col -->
    </div>
    <!-- Modal -->
        <div class="modal right fade" id="myModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
            <div class="modal-content" style="background-color: #eeeeee;">
            <span class="closeModal" data-dismiss="modal"  > </span>

                <div class="modal-header mb-0" style="background-color: #eeeeee;">
                        <div class=" ml-4 mr-0 mt-3">
                    <h5> Update Module</h5>
                    </div>
                </div>
                <div class="modal-body">
                    <div  class="element-wrapper" >
                        <div class="element-box">			 						 
                    <form @submit.prevent="editProductModule(editedModuleName,editedID,selectedProductID)" class="p-3">
                        <div class="form-group">
                            <label class="form-control-label required">Product Name</label>
                            <vue-select v-model="selectedOption" :options="productNames" close-on-select  required></vue-select>
                        </div>
                        <div class="form-group">
                            <label class="required">Product Module</label>
                            <div>
                                <input v-model.trim="editedModuleName" type="text" class="form-control" required placeholder="Enter Product Module">
                            </div>
                        </div>
                        <div class="form-group mb-0">
                            <button class="btn btn-lg btn-block btn-primary" >Update </button>
                        </div>
                    </form>
                        </div>
                    </div>
                </div>

            </div><!-- modal-content -->
            </div><!-- modal-dialog -->
        </div>
    </div> 
</template>

<script lang="ts">
import {Vue, Options} from 'vue-class-component'
import $ from 'jquery'
import  { event } from "jquery";
import VueSelect from 'vue-next-select'
import 'vue-next-select/dist/index.min.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import { GetAllProducts, getProductModules, createProductModules, updateProductModules, deleteProductModule} from '../store/api/configsettings'

@Options({
  components: {
		VueSelect, 
        Loading
  },
})

export default class ProductModule extends Vue{
    private productModules = [];
    private products =[];
    private productNames = [];
    private newProductModuleName ='';
    private options = [];
    private isLoading = false;
    private loadingIconColor = '#00b8d0';
    private selectedOption = 'NextCounsel System';
    private editedModuleName = '';
    private editedID = '';

    created() {
        this.getModules();
        this.addProducts();
    }

    private getModules(){
        this.isLoading = true;
        getProductModules().subscribe((response: any)=>{
            this.productModules = response.data.modules;
            this.isLoading = false;
        })
    }

    private addProducts(){
        GetAllProducts().subscribe((response:any)=>{
            this.products = response.data.products;
            if (response.data.products) {
                this.productNames = response.data.products.map(product => product.name);
            }
        })
    }

    private addProductModules(productModule:any, id:any){
        const newProductModule= {
            product_id: id,
            module: productModule
        }
            this.isLoading = true;
        createProductModules( newProductModule).subscribe((response:any)=>{
            if (response.data.status === 'success'){
                this.isLoading = false;
                this.newProductModuleName = '';
                this.getModules();
                Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: response.data.message,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }else if(response.data.status=="error"){
                this.isLoading = false;
             Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: response.data.message,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }
        })    
    }


    private editProductModule(name: any, id: string, pid: string) {
        const editedModule={
            product_id: pid ,
            module: name
            }
    
        this.isLoading= true;
        updateProductModules(editedModule, id).subscribe((response: any) => {
            if (response.data.status === "success") {
                this.isLoading =false;
                $('#myModal').modal('hide');
                this.editedModuleName = "";
                this.getModules();
                Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                    id: 'toastAuthy', zindex: 99,  title: response.data.message,
                    titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                    icon: 'fa fa-check',   
                    iconColor: 'white',});
            }else if(response.data.status=="error"){
                this.isLoading = false;
             Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: response.data.message,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',   
                iconColor: 'white',});
            }
        })
    }

    private setProductModuleID (id: string, name: string ,) {
        this.editedID = id;
        this.editedModuleName = name;
    }

    get selectedProductID() :string  {
        const selectedProduct = this.products.filter((product: {name, id}) => product.name === this.selectedOption);
        let id: string = selectedProduct[0]['id'];
        return id;
    }


    private removeProductmodules(moduleid: any){
        Toast.question({
        timeout: 20000,
        close: false,
        overlay: false,
        id: 'question',
        zindex: 1000,
        title: '',
        message: 'Are you sure ?',
        position: 'center',
        buttons: [
            ['<button><b>YES</b></button>',  (instance, toast) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            
            this.isLoading = true
            deleteProductModule(moduleid).subscribe((response:any) =>{
                if (response.data.status =="success"){
                    this.isLoading = false;
                    this.getModules();
                    Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                    id: 'toastAuthy', zindex: 99,  title: response.data.message,
                    titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                    icon: 'fa fa-user-check',   
                    iconColor: 'white',});
                }else if(response.data.status=="error"){
                    this.isLoading = false;
                Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#f83f37', timeout: 6000, overlay: false,
                    id: 'toastAuthy', zindex: 99,  title: response.data.message,
                    titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                    icon: 'fa fa-user-check',   
                    iconColor: 'white',});
                }
            }) 
        }, true],
        ['<button>NO</button>', (instance: any, toast: any) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        }, event],
        ],
    });    
}

}
</script>

<style>
    .vue-select {
        width: 100%;
        padding: 5px;
        border-color: #dee2e6;
    }

    .vue-dropdown-item:hover, .vue-dropdown-item.highlighted {
        background-color: #eee !important;
        color: #777;
    }

    .closeModal {
        position: absolute;
        right: 32px;
        top: 32px;
        width: 32px;
        height: 32px;
        opacity: 0.3;
    }

    .closeModal:hover {
        opacity: 1;
    }

    .closeModal:before, .closeModal:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #333;
    }

    .closeModal:before {
        transform: rotate(45deg);
    }

    .closeModal:after {
        transform: rotate(-45deg);
    }

    .required:after{
        content: "*";
        color:#f83f37;
        margin-left: 2px;
    }

</style>
