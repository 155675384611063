<template>
  <div class="main-wrapper">
    <Header />
    <Sidenav />
   <div class="page-wrapper">

    <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Header from '@/components/header.vue';  
import Sidenav from '@/components/sidenav.vue';  
import $ from "jquery";
import * as feather from 'feather-icons';
// import * as DataTable from 'datatables';
// import { event } from 'jquery'; 

@Options({
  components: {
    Header, Sidenav
  },
//   methods: {
// 	setPeople: function (val) {
// 		this.showPeopleOption = val;
// 	}
//   },
  data () {
	return {
		}
  }
})
export default class MainApp extends Vue {
	
	// private showPeopleOption = false;
	mounted() { 
 console.log('mainapp');
 
    const $wrapper = $('.main-wrapper');
	// const $pageWrapper = $('.page-wrapper');
	const $slimScrolls: any = $('.slimscroll');

	feather.replace();
		
	// Sidebar
	const Sidemenu = function (this: any) {
		const $menuItem = '';
		this.$menuItem = $('#sidebar-menu a');
	};

	const init = () => {
        const $this = Sidemenu;
        // console.log(   "aftr",  $('#sidebar-menu a') )
		$('#sidebar-menu a').on('click',  (event: any) => {
			// console.log($(this),'77777', $(this)[0].Target , '888', Object.keys($(this)[0]));
			
			if ($('#sidebar-menu a').parent().hasClass('submenu')) {
                // console.log( 'sub2'   )
				event.preventDefault();
			}
			if (!$('#sidebar-menu a').hasClass('subdrop')) {
				// console.log( 'sub3'   )
				$('ul', $('#sidebar-menu a').parents('ul:first')).slideUp(350);
				$('a', $('#sidebar-menu a').parents('ul:first')).removeClass('subdrop');
				$('#sidebar-menu a').next('ul').slideDown(350);
				$('#sidebar-menu a').addClass('subdrop');
			} else if ($('#sidebar-menu a').hasClass('subdrop')) {
				// console.log( 'sub4'   )
				$('#sidebar-menu a').removeClass('subdrop');
				$('#sidebar-menu a').next('ul').slideUp(350);
			}
		});
		$('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');
	}
	
	// Sidebar Initiate
	init();
	
	// Mobile menu sidebar overlay
	$('body').append('<div class="sidebar-overlay"></div>');
	$(document).on('click', '#mobile_btn', function () {
		$wrapper.toggleClass('slide-nav');
		$('.sidebar-overlay').toggleClass('opened');
		$('html').addClass('menu-opened');
		return false;
	});
	
	// Sidebar overlay
	$(".sidebar-overlay").on("click", function () {
		$wrapper.removeClass('slide-nav');
		$(".sidebar-overlay").removeClass("opened");
		$('html').removeClass('menu-opened');
	});
	
	// Page Content Height
	if ($('.page-wrapper').length > 0) {
		const height = $(window).height();
		$(".page-wrapper").css("min-height", height);
	}
	
	// Page Content Height Resize
	$(window).resize(function () {
		if ($('.page-wrapper').length > 0) {
			const height = $(window).height();
			$(".page-wrapper").css("min-height", height);
		}
	});
	
	// Select 2
	// if ($('.select').length > 0) {
	// 	$('.select').select2({
	// 		minimumResultsForSearch: -1,
	// 		width: '100%'
	// 	});
	// }
	
	// Datetimepicker
	
	// if($('.datetimepicker').length > 0 ){
	// 	$('.datetimepicker').datetimepicker({
	// 		format: 'DD-MM-YYYY',
	// 		icons: {
	// 			up: "fas fa-angle-up",
	// 			down: "fas fa-angle-down",
	// 			next: 'fas fa-angle-right',
	// 			previous: 'fas fa-angle-left'
	// 		}
	// 	});
	// }
	
	// Tooltip
	if ($('[data-toggle="tooltip"]').length > 0) {
		$('[data-toggle="tooltip"]').tooltip();
	}
	
	// Datatable
	// if ($('.datatable').length > 0) {
	// 	$('.datatable').DataTable({
	// 		"bFilter": false,
	// 	});
	// }
	
	// // Sidebar Slimscroll
	// if ($slimScrolls.length > 0) {
    //     // console.log('slimscroll', $slimScrolls);
        
	// 	$slimScrolls.slimScroll({
	// 		height: 'auto',
	// 		width: '100%',
	// 		position: 'right',
	// 		size: '7px',
	// 		color: '#ccc',
	// 		allowPageScroll: false,
	// 		wheelStep: 10,
	// 		touchScrollStep: 100
	// 	});
	// 	const wHeight = $(window).height() - 60;
    //     console.log(wHeight, 'winHeight');
        
	// 	$slimScrolls.height(wHeight);
	// 	$('.sidebar .slimScrollDiv').height(wHeight);
	// 	$(window).resize(function () {
	// 		const rHeight = $(window).height() - 60;
	// 		$slimScrolls.height(rHeight);
	// 		$('.sidebar .slimScrollDiv').height(rHeight);
	// 	});
	// }
	
	// Password Show
	
	// if($('.toggle-password').length > 0) {
	// 	$(document).on('click', '.toggle-password', function() {
	// 		$(this).toggleClass("fa-eye fa-eye-slash");
	// 		const input = $(".pass-input");
	// 		if (input.attr("type") == "password") {
	// 			input.attr("type", "text");
	// 		} else {
	// 			input.attr("type", "password");
	// 		}
	// 	});
	// }

	// Check all email
	
	// $(document).on('click', '#check_all', function() {
	// 	$('.checkmail').click();
	// 	return false;
	// });
	// if($('.checkmail').length > 0) {
	// 	$('.checkmail').each(function() {
	// 		$(this).on('click', function() {
	// 			if($(this).closest('tr').hasClass('checked')) {
	// 				$(this).closest('tr').removeClass('checked');
	// 			} else {
	// 				$(this).closest('tr').addClass('checked');
	// 			}
	// 		});
	// 	});
	// }
	
	// Mail important
	
	// $(document).on('click', '.mail-important', function() {
	// 	$(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
	// });
	
	// Small Sidebar
	$(document).on('click', '#toggle_btn', function () {
		if ($('body').hasClass('mini-sidebar')) {
			$('body').removeClass('mini-sidebar');
			$('.subdrop + ul').slideDown();
		} else {
			$('body').addClass('mini-sidebar');
			$('.subdrop + ul').slideUp();
		}
		setTimeout(function () {
			// mA.redraw();
			// mL.redraw();
		}, 300);
		return false;
	});
	
	$(document).on('mouseover', function (e: any) {
		e.stopPropagation();
		if ($('body').hasClass('mini-sidebar') && $('#toggle_btn').is(':visible')) {
			const targ = $(e.target).closest('.sidebar').length;
			if (targ) {
				$('body').addClass('expand-menu');
				$('.subdrop + ul').slideDown();
			} else {
				$('body').removeClass('expand-menu');
				$('.subdrop + ul').slideUp();
			}
			return false;
		}
	});
	
	$(document).on('click', '#filter_search', function() {
		$('#filter_inputs').slideToggle("slow");
	});
	
	// Chat

// 	const chatAppTarget = $('.chat-window');
// 	(function() {
// 		if ($(window).width() > 991)
// 			chatAppTarget.removeClass('chat-slide');
		
// 		$(document).on("click",".chat-window .chat-users-list a.media",function () {
// 			if ($(window).width() <= 991) {
// 				chatAppTarget.addClass('chat-slide');
// 			}
// 			return false;
// 		});
// 		$(document).on("click","#back_user_list",function () {
// 			if ($(window).width() <= 991) {
// 				chatAppTarget.removeClass('chat-slide');
// 			}	
// 			return false;
// 		});
// 	})();
}
    
}
</script>

 
