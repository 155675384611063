<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="page-title">Notification Group Setting</h3>
          <ul class="breadcrumb">
            <li class="breadcrumb-item"><a>Settings</a></li>
            <li class="breadcrumb-item active">Notification Group</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Document List -->
    <div class="row">
        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">List</h4>
                    <p class="card-title-desc">
                    </p>
                    <div id="datatable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length"><label>Show <select style="min-width: 50px" name="datatable_length" aria-controls="datatable" class="custom-select custom-select-sm form-control form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label></div></div><div class="col-sm-12 col-md-6"><div id="datatable_filter" class="dataTables_filter"><label>Search:<input type="search" class="form-control form-control-sm" placeholder="" aria-controls="datatable"></label>
                                </div></div></div><div class="row"><div class="col-sm-12"><table id="datatable" class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px; width: 100%;" role="grid" aria-describedby="datatable_info">
                        <thead>
                            <tr role="row"><th scope="col" class="sorting_asc" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 331.2px;" aria-label="Product: activate to sort column descending" aria-sort="ascending">Product</th><th scope="col" style="width: 19.2px;" class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label=": activate to sort column ascending"></th></tr>
                        </thead>
                        <tbody> 
                        <tr role="row" class="odd">
                                <td class="dtr-control sorting_1" tabindex="0">NC Lite</td>
                                <td>
                                    <div class="btn-group dropup">
                                        <a class="font-size-18 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i data-feather="menu"></i></a>
                                        <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                          <a class="dropdown-item small" href="#"><i data-feather="edit-2"></i> Manage Checklist</a>
                                          <a class="dropdown-item text-danger small" href="#"><i data-feather="trash-2"></i> Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr><tr role="row" class="even">
                                <td class="dtr-control sorting_1" tabindex="0">NC TimeApp</td>
                                <td>
                                    <div class="btn-group dropup">
                                        <a class="font-size-18 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i data-feather="menu"></i></a>
                                        <div class="dropdown-menu " style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                          <a class="dropdown-item small " href="#"><i data-feather="edit-2"></i> Manage Checklist</a>
                                          <a class="dropdown-item text-danger small" href="#"><i data-feather="trash-2"></i> Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr><tr role="row" class="odd">
                                <td class="dtr-control sorting_1" tabindex="0">NextCounsel System</td>
                                <td>
                                    <div class="btn-group dropup">
                                        <a class="font-size-18 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i data-feather="menu"></i></a>
                                        <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                          <a class="dropdown-item small" href="#"><i data-feather="edit-2"></i> Manage Checklist</a>
                                          <a class="dropdown-item text-danger small" href="#"><i data-feather="trash-2"></i> Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr><tr role="row" class="even">
                                <td class="dtr-control sorting_1" tabindex="0">NextPLOS</td>
                                <td>
                                    <div class="btn-group dropup">
                                        <a class="font-size-18 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i data-feather="menu"></i></a>
                                        <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                          <a class="dropdown-item small" href="#"><i data-feather="edit-2"></i> Manage Checklist</a>
                                          <a class="dropdown-item text-danger small" href="#"><i data-feather="trash-2"></i> Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr></tbody>
                    </table></div></div><div class="row"><div class="col-sm-12 col-md-5"><div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing 1 to 4 of 4 entries</div></div><div class="col-sm-12 col-md-7"><div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate"><ul class="pagination"><li class="paginate_button page-item previous disabled" id="datatable_previous"><a href="#" aria-controls="datatable" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="datatable" data-dt-idx="1" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item next disabled" id="datatable_next"><a href="#" aria-controls="datatable" data-dt-idx="2" tabindex="0" class="page-link">Next</a></li></ul></div></div></div></div>

                </div>
            </div>
        </div> <!-- end col -->

        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">

                    <h4 class="card-title">Setting</h4>
                    <p class="card-title-desc">
                    </p>
                    <div class="card bg-primary">
                        <div class="card-body">
                            <div class="col-sm-12">
                                <span class="text-white font-size-18"><b>NextCounsel System</b></span>
                            </div>
                        </div>
                    </div>
                   <div class="form-group">
					<div class="table-responsive mb-4">
						<table class="table table-centered table-nowrap mb-0">
							<thead>
								<tr>
									<th scope="col" style="width: 50px;">
										<!-- <div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="contacusercheck">
											<label class="custom-control-label" for="contacusercheck"></label>
										</div> -->
									</th>
									<th scope="col">Name</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="contacusercheck1">
											<label class="custom-control-label" for="contacusercheck1"></label>
										</div>
									</th>
									<td>
										<a href="#" class="text-body">Simon Ryles</a>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="contacusercheck2">
											<label class="custom-control-label" for="contacusercheck2"></label>
										</div>
									</th>
									<td>
										<a href="#" class="text-body">Marion Walker</a>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="contacusercheck3">
											<label class="custom-control-label" for="contacusercheck3"></label>
										</div>
									</th>
									<td>
										<a href="#" class="text-body">Frederick White</a>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="contacusercheck4">
											<label class="custom-control-label" for="contacusercheck4"></label>
										</div>
									</th>
									<td>
										<a href="#" class="text-body">Shanon Marvin</a>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="contacusercheck5">
											<label class="custom-control-label" for="contacusercheck5"></label>
										</div>
									</th>
									<td>
										<a href="#" class="text-body">Mark Jones</a>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="contacusercheck6">
											<label class="custom-control-label" for="contacusercheck6"></label>
										</div>
									</th>
									<td>
										<a href="#" class="text-body">Janice Morgan</a>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="contacusercheck7">
											<label class="custom-control-label" for="contacusercheck7"></label>
										</div>
									</th>
									<td>
										<a href="#" class="text-body">Patrick Petty</a>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="contacusercheck8">
											<label class="custom-control-label" for="contacusercheck8"></label>
										</div>
									</th>
									<td>
										<a href="#" class="text-body">Marilyn Horton</a>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="contacusercheck9">
											<label class="custom-control-label" for="contacusercheck9"></label>
										</div>
									</th>
									<td>
										<a href="#" class="text-body">Neal Womack</a>
									</td>
								</tr>
								<tr>
									<th scope="row">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="contacusercheck10">
											<label class="custom-control-label" for="contacusercheck10"></label>
										</div>
									</th>
									<td>
										<a href="#" class="text-body">Steven Williams</a>
									</td>
								</tr>

							</tbody>
						</table>
					</div>
				</div>
                    <div class="form-group mb-0">
                        <div class="text-right">
                            <button type="reset" class="btn btn-light waves-effect mr-1">
                                Cancel
                            </button>
                            <button type="submit" class="btn btn-primary waves-effect waves-light">
                                Save
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- end col -->
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
@Options({
  components: {
//
  },
})
export default class NotificationGroup extends Vue {
    //
}
</script>


<style>
.req:after {
    content:" *";
    color: red;
  } 	
</style>