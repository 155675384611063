
import { Options, Vue } from 'vue-class-component';
// vue. use(VueSlimScroll);

@Options({
  props: {
    msg: String
  },
  directives: {
  }
})
export default class Sidenav extends Vue {
  msg!: string
}
