
import { Options, Vue } from 'vue-class-component';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {  userLogin } from '../store/api/usermgt';
import toast from '../components/toast';
@Options({
  components: {
		Loading
  },
})
export default class Login extends Vue {
private files = [];
private uploadUrl = '';
private email = '';
private password = '';
private isLoading = false;
private loadingIconColor = '#00b8d0';


private loginUser()  {
    const postData = { email: this.email, password: this.password };
          this.isLoading = true;
          if (this.email !== '' && this.password) {
          userLogin(postData)
          .then(res => {
                if (res.status == "success") {
				toast.success('', 'Login Successful',5000 )
                console.log('then block', res)
                 this.isLoading = false;
				this.$router.push('/app');
                  }
                  else {
                     this.isLoading = false;
					toast.error('', 'Login Failed',5000 )
                     console.log('observed untruthtiness in then block', res);
                    // const alertComp: any = document.getElementById('alertDiv');
                    // this.alertText = 'Invalid Credentials';
                    // alertComp.classList.remove("hide-div");
                    // alertComp.classList.add("badge-danger");
                    // setTimeout(() => {
                    //   alertComp.classList.add("hide-div" );
                    //   alertComp.classList.remove("badge-warning", "badge-danger", "badge-success");
                    // }, 3000)
                   }
            
           
          })
          .catch(err => {
            console.log('then catched', Object.keys(err), err.message)

          })
          } else {
                  toast.error('', 'Enter valid credentials Error',5000 )
                    this.isLoading = false;
                  }
}
 
}
