
import {Vue, Options} from 'vue-class-component';
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {addChecklist, deleteChecklist, getChecklists, getProducts, updateChecklist} from '@/store/api/onboardingApi';
import $, {event} from 'jquery';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

@Options({
	components: {
		Loading,
		PulseLoader
	}
})
/* eslint-disable */
export default class OnboardingCL extends Vue {
	private isLoading = false;
	private isUpdating = false;
	private subscriptionList : any[] = [];
	private dataList : Record<string, any>[] = [];
	private checklistData = []
	private inputData = '';
	private editedName = '';
	private editId = '';
	private editIndex = 0;
	private currentProduct : any = {};

	created() { //eslint-disable-line
		this.getProducts()
		console.log('lifecycle hook is called');
	}

	beforeUnmount() : void { 
		this.subscriptionList.forEach((subscription) => subscription.unsubscribe())
		console.log('The unsubscription goes fine');
	}

	private selectProduct(product : {checklist: {}}) {
		this.currentProduct = product;
		this.currentProduct.checklist = product.checklist;
		console.log('Select Product fn is called');
	}

	private editData (name : string, dataId : string, index : number) {
		this.editedName = name;
		this.editId = dataId;
		this.editIndex = index;
	}

	private handleSave(btnType : string) {
		if(btnType === 'edit' && this.editId && this.editedName) {
			this.isUpdating = true
			this.updateChecklist({dataId : this.editId, dataUpdate: {product_id : this.currentProduct.id, checklist : this.editedName}});
		} else if(btnType === 'add' && this.inputData && this.currentProduct.id){
			this.addChecklist({product_id : this.currentProduct.id, checklist : this.inputData});
		}
	}

	private getProducts() {
		this.isLoading = true;
		const getSubscription = getProducts().subscribe((response) => {
			if(response.data.status === 'success') {
				console.log('Data was fetched successfuly', response);
				let responseData : Record<string, unknown>[] = response.data.products;
				const newResponse = responseData.map((data) => {
					const updateData = {...data, checklist : []};
					return updateData;
				})

				console.log('The edited response data is', responseData);
				this.dataList = newResponse;
				console.log('Products list is: ', this.dataList);
				this.getChecklists()
			} else {
				console.log('Error occured while fetching products', response);
				Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-error',
					iconColor: 'white',
				});
			}

			this.isLoading = false;
		}, (error) => {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
				id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
				titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
				icon: 'fa fa-error',
				iconColor: 'white',
			});
			console.log('Error occured while fetching data', error);
			this.isLoading = false;
		});
		this.subscriptionList.push(getSubscription)
	}

	private getChecklists() {
		this.isLoading = true;
		const getSubscription = getChecklists().subscribe((response) => {
			if(response.data.status === 'success') {
				console.log('Data was fetched successfuly', response);
				
				const checklists = response.data.checklists;
				for (let i = 0; i < checklists.length; i++) {
					const item = checklists[i];
					for (let i = 0; i < this.dataList.length; i++) {
						const productItem = this.dataList[i];
						if(productItem.id === item.product_id) {
							const productChecklist : Record<string, unknown>[] = this.dataList[i].checklist as []
							productChecklist.push(item);
							break;
						}
					}
				}
				console.log('Checklist list value is: ', this.dataList);
			} else {
				console.log('Error occured while fetching checklists', response);
				Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-error',
					iconColor: 'white',
				});
			}

			this.isLoading = false;
		}, (error) => {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
				id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
				titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
				icon: 'fa fa-error',
				iconColor: 'white',
			});
			console.log('Error occured while fetching data', error);
			this.isLoading = false;
		});
		this.subscriptionList.push(getSubscription)
	}

	private addChecklist(newData : {product_id : string, checklist: string}) {
		this.isLoading = true;
		const getSubscription = addChecklist(newData).subscribe((response) => {
			if(response.data.status === 'success') {
				console.log('Add Data was fetched successfuly', response);
				
				for (let i = 0; i < this.dataList.length; i++) {
					const product = this.dataList[i] as {checklist : [], id : string};
					if (product.id === newData.product_id) {
						this.dataList[i].checklist.push(response.data.checklist);
						break;
					}
				}
				this.inputData = '';
				Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-check',
					iconColor: 'white',
				});
			} else {
				console.log('Error occured while adding data', response);
				Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-error',
					iconColor: 'white',
				});
			}

			this.isLoading = false;
		}, (error) => {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
				id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
				titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
				icon: 'fa fa-error',
				iconColor: 'white',
			});
			console.log('Error occured while fetching data', error);
			this.isLoading = false;
		})
		this.subscriptionList.push(getSubscription);
	}

	private updateChecklist(updateData : {dataId : string, dataUpdate : {product_id : string, checklist : string}}) {
		const {dataId, dataUpdate} = updateData;
		const getSubscription = updateChecklist(dataId, dataUpdate).subscribe((response) => {
			if(response.data.status === 'success') {
				console.log('Data was updated successfuly', response);

				for (let i = 0; i < this.dataList.length; i++) {
					const product = this.dataList[i] as {checklist : [], id : string};
					if (product.id === dataUpdate.product_id) {
						this.dataList[i].checklist.splice(this.editIndex, 1, response.data.checklist);
						break;
					}
				}
				$('#myModal').modal('hide');
				this.editedName = '';
				this.editId = '';
				Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-check',
					iconColor: 'white',
				});
			} else {
				console.log('Error occured while updating data', response);
				Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-error',
					iconColor: 'white',
				});
			}
			
			this.isUpdating = false;
		}, (error) => {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
				id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
				titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
				icon: 'fa fa-error',
				iconColor: 'white',
			});
			console.log('Error occured while fetching data', error);
			this.isUpdating = false;
		});
		this.subscriptionList.push(getSubscription);
	}

	private deleteChecklist(dataId : string, product_id: string, index : number) {
		Toast.question({
			timeout: 20000,
			close: false,
			overlay: false,
			id: 'question',
			zindex: 1000,
			title: '',
			message: 'Are you sure ?',
			position: "topRight",
			buttons: [
					['<button><b>YES</b></button>',  (instance, toast) => {
			instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
				this.isLoading = true;
				const getSubscription = deleteChecklist(dataId).subscribe((response) => {
					if(response.data.status === 'success') {
						console.log('Data was deleted successfuly', response);

						for (let i = 0; i < this.dataList.length; i++) {
							const product = this.dataList[i] as {checklist : [], id : string};
							if (product.id === product_id) {
								this.dataList[i].checklist.splice(index, 1);
								break;
							}
						}
						Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
							id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-check',
							iconColor: 'white',
						});
					} else {
						console.log('Error occured while deleting data', response);
						Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
							id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-error',
							iconColor: 'white',
						});
					}
					this.isLoading = false;
				}, (error) => {
					Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
						id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
						titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
						icon: 'fa fa-error',
						iconColor: 'white',
					});
					console.log('Error occured while fetching data', error);
					this.isLoading = false;
				});
		
				this.subscriptionList.push(getSubscription);
			}, true],
			['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
							instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
				 }, event],
			],
   
		});
	}
}
