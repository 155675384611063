
import { Vue, Options } from 'vue-class-component';
import ConfigTemplate from "@/components/config-template.vue";
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {addLossReason, deleteLossReason, getLossReasons, updateLossReason} from '@/store/api/lossReasonApi';
import emitter from "@/utils/emitter";
import $, {event} from 'jquery';

@Options({
    components: {
      ConfigTemplate,
			Loading
    }
})

export default class LossReason extends Vue {
	private isLoading = false;
	private subscriptionList : any[] = []; // eslint-disable-line
	private dataList : Record<string, unknown>[] = [];
	private isUpdating = false;
	private editedName = '';
	private pageData = {
		title: 'Loss Reason',
		list: this.dataList,
	}

	created() { //eslint-disable-line
		this.getLossReasons()
		emitter.on('updateEmit', (emitData : {dataId : string, dataUpdate : {name : string}}) => {
			console.log('New updateEmit detected', emitData);
			this.updateLossReason(emitData);
		})

		emitter.on('additionEmit', (emitData : {name : string}) => {
			console.log('New addEmit detected', emitData);
			this.addLossReason(emitData);
		})
		console.log('lifecycle hook is called');
	}

	beforeUnmount() : void { 
		this.subscriptionList.forEach((subscription) => subscription.unsubscribe())
		emitter.off('updateEmit');
		emitter.off('additionEmit');
		console.log('The unsubscription goes fine');
	}

	private getLossReasons() {
		this.isLoading = true;
		const getSubscription = getLossReasons().subscribe((response) => {
			if(response.data.status === 'success') {
				console.log('Get Data was fetched successfuly', response);
				this.dataList = response.data.reasons;
				this.pageData.list = this.dataList;
				console.log('dataList list value is: ', this.dataList);
			} else {
				console.log('Error occured while fetching dataList', response);
				Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-error',
					iconColor: 'white',
				});
			}

			this.isLoading = false;
		}, (error) => {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
				id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
				titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
				icon: 'fa fa-error',
				iconColor: 'white',
			});
			console.log('Error occured while fetching data', error);
			this.isLoading = false;
		});
		this.subscriptionList.push(getSubscription)
		
	}

	private addLossReason(newData : {name : string}) {
		this.isLoading = true;
		addLossReason(newData).subscribe((response) => {
			if(response.data.status === 'success') {
				console.log('Add Data was fetched successfuly', response);
				this.dataList.push(response.data.reason)
				this.pageData.list = this.dataList;
				Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-check',
					iconColor: 'white',
				});
			} else {
				console.log('Error occured while fetching data', response);
				Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-error',
					iconColor: 'white',
				});
			}

			this.isLoading = false;
		}, (error) => {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
				id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
				titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
				icon: 'fa fa-error',
				iconColor: 'white',
			});
			console.log('Error occured while fetching data', error);
			this.isLoading = false;
		})
	}

	private updateLossReason(updateData : {dataId : string, dataUpdate : {name : string}}) {
		const {dataId, dataUpdate} = updateData;
		this.isUpdating = true;
		updateLossReason(dataId, dataUpdate).subscribe((response) => {
			if(response.data.status === 'success') {
				console.log('Data was updated successfuly', response);
				for (let i = 0; i < this.dataList.length; i++) {
					const element = this.dataList[i];
					if (element.id === dataId) {
						this.dataList.splice(i, 1, response.data.reason);
						this.pageData.list = this.dataList;
						break;
					}
				}
				$('#myModal').modal('hide');
				this.editedName = '';
				Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-check',
					iconColor: 'white',
				});
			} else {
				console.log('Error occured while fetching data', response);
				Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
					id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
					titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					icon: 'fa fa-error',
					iconColor: 'white',
				});
			}

			this.isUpdating = false;
		}, (error) => {
			Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
				id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
				titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
				icon: 'fa fa-error',
				iconColor: 'white',
			});
			console.log('Error occured while updating data', error);
			this.isUpdating = false;
		});
	}

	private deleteLossReason(dataId : string) {
		Toast.question({
			timeout: 20000,
			close: false,
			overlay: false,
			id: 'question',
			zindex: 1000,
			title: '',
			message: 'Are you sure ?',
			position: "topRight",
			buttons: [
					['<button><b>YES</b></button>',  (instance, toast) => {
			instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
				this.isLoading = true;
				const getSubscription = deleteLossReason(dataId).subscribe((response) => {
					if(response.data.status === 'success') {
						console.log('Data was deleted successfuly', response);
						for (let i = 0; i < this.dataList.length; i++) {
							const element = this.dataList[i];
							if (element.id === dataId) {
								this.dataList.splice(i, 1);
								this.pageData.list = this.dataList;
								break;
							}
						}
						Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
							id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-check',
							iconColor: 'white',
						});
					} else {
						console.log('Error occured while fetching data', response);
						Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
							id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-error',
							iconColor: 'white',
						});
					}

					this.isLoading = false;
				}, (error) => {
					Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
						id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
						titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
						icon: 'fa fa-error',
						iconColor: 'white',
					});
					console.log('Error occured while fetching data', error);
					this.isLoading = false;
				});
	
				this.subscriptionList.push(getSubscription);

			}, true],
			['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
							instance.hide({ transitionOut: 'fadeOut' }, toast, 'button'); 
				}, event],
			],
   
		});
	}
}
