<template>
  <loading v-model:active="isLoading"  :can-cancel="true"   :is-full-page="true"></loading> 
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="page-title">Prospect List</h3>
          <ul class="breadcrumb">
            <li class="breadcrumb-item"><a>Settings</a></li>
            <li class="breadcrumb-item active">Prospect List</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /Page Header -->
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div style="display : flex; align-items : center; justify-content : space-between; margin-bottom : 15px">
                      <div>
                        <h4 class="card-title">Prospect List</h4>
                        <p class="card-title-desc">
                            A Prospect Company is an organizational entity that you may be doing business with soon. 
                        </p>
                      </div>
                    </div>
                    <div id="datatable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                      <div class="row">
                        <div class="col-sm-12 col-md-4">
                          <div class="dataTables_length" id="datatable_length">
                            <label>Show <select name="datatable_length" aria-controls="datatable" class="custom-select custom-select-sm form-control form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div id="datatable_filter" class="dataTables_filter">
                            <label>Search:<input type="search" class="form-control form-control-sm" placeholder="" aria-controls="datatable"></label>
                          </div>
                        </div>
                        <div style="display : flex; align-items : center; justify-content : flex-end" class="col-sm-12 col-md-4">
                          <button style="float : right" data-toggle="modal" data-target="#myModal" type="button" class="btn btn-outline-primary w-xs " >Add New Company</button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <table id="datatable" class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px; width: 100%;" role="grid" aria-describedby="datatable_info">
                        <thead>
                            <tr role="row"><th class="sorting_asc" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 163.2px;" aria-sort="ascending" aria-label="Name: activate to sort column descending">Name</th><th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 118.2px;" aria-label="Industry: activate to sort column ascending">Industry</th><th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 102.2px;" aria-label="Email Address: activate to sort column ascending">Email Address</th><th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 75.2px;" aria-label="Phone: activate to sort column ascending">Phone</th><th style="width: 20.2px;" class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label=": activate to sort column ascending"></th></tr>
                        </thead>
                        <tbody>                    
                          <tr v-for="company, index in prospectList" :key="index" role="row" class="odd">
                                <td class="sorting_1 dtr-control">{{company.name}}</td>
                                <td>{{company.industry.name}}</td>
                                <td>{{company.email}}</td>
                                <td>{{company.phone}}</td>
                                <td>
                                    <div class="btn-group dropup">
                                        <a class="font-size-18 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                        </a>
                                        <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                          <router-link :to="{name : 'view_company', params : company}" class="dropdown-item text-info" style="cursor : pointer;"><i class="fa fa-eye"></i>
                                            View
                                          </router-link>
                                          <a @click="setEdit(company, index)" class="dropdown-item" data-toggle="modal" data-target="#myModal" style="cursor : pointer;">
                                             <i class="fa fa-edit"> </i>
                                            Edit
                                          </a>
                                          <a @click="deleteCompany(company.id, index)" class="dropdown-item text-danger" href="#" style="cursor : pointer;">
                                            <i class="fa fa-trash"> </i>
                                            Delete
                                          </a>
                                        </div>
                                    </div>
                                </td>
                          </tr>
                        </tbody>
                    </table>
                    </div>
                      </div>
                      <div class="row" style="margin-top : 15px">
                      <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing 1 to {{prospectList.length}} of {{prospectList.length}}</div>
                      </div>
                      <div style="display: flex; justify-content: flex-end;" class="col-sm-12 col-md-7"><div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                        <ul class="pagination"><li class="paginate_button page-item previous disabled" id="datatable_previous"><a href="#" aria-controls="datatable" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="datatable" data-dt-idx="1" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item next" id="datatable_next"><a href="#" aria-controls="datatable" data-dt-idx="4" tabindex="0" class="page-link">Next</a></li></ul>
                      </div>
                      </div>
                      </div>
                  </div>
                </div>
            </div>
        </div> <!-- end col -->
        <!-- end col -->
    </div>

    <!--Start Modal -->
    <div class="modal right fade" id="myModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="background-color: #eeeeee;">
        <span @click="clearData" style="cursor: pointer;" class="closeModal"> </span>

            <div class="modal-header mb-0" style="background-color: #eeeeee;">
                    <div class=" ml-4 mr-0 mt-3">
                <h5>{{editId ? "Update Company" : "Add Company"}}</h5>
                </div>
            </div>

          <div class="modal-body" data-select2-id="59">
              <div class="row">
                  <div class="col-md-12">
                      <div class="form-group">
                          <label>Company Name <span style="color : red;">*</span></label>
                          <input v-model.trim="currentData.name" type="text" class="form-control" placeholder="Enter Company Name">
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12">
                      <div class="form-group">
                          <!--<div class="float-right">
                              <a href="#" data-toggle="modal" data-target="#modalIndustry">
                                  <span>Add New</span>
                              </a>
                          </div>-->
                          <label>Industry <span style="color : red;">*</span></label>
                          <select v-model.trim="industry" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                              <option value="" disabled >--Choose an option--</option>
                              <option v-for="data, index in industryList" :key="index">{{data.name}}</option>
                          </select>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-6">
                      <div class="form-group">
                          <label>Phone <span style="color : red;">*</span></label>
                          <input v-model.trim="currentData.phone" class="form-control" type="text" placeholder="Enter Phone">
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <label>Email <span style="color : red;">*</span></label>
                          <input v-model.trim="currentData.email" type="text" class="form-control" placeholder="Enter Email">
                      </div>
                  </div>
              </div>
              <div class="form-group">
                  <label>Website</label>
                  <input v-model.trim="currentData.website" type="text" class="form-control" placeholder="Enter Website">
              </div>
              <div class="form-group">
                  <label>Address</label>
                  <div>
                      <textarea v-model.trim="currentData.address" class="form-control" rows="4" placeholder="Enter Address"></textarea>
                  </div>
              </div>
              <div class="form-group">
                  <label>Country</label>
                  <select v-model="country" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="22" tabindex="-1" aria-hidden="true">
                      <option value="" disabled >--Choose an option--</option>
                      <option v-for="data, index in countryList" :key="index">{{data.country}}</option>
                  </select>
              </div>
          </div>

          <div class="modal-footer">
              <button @click="clearData" type="button" class="btn btn-light waves-effect">Discard</button>
              <button @click="saveData" type="button" class="btn btn-primary waves-effect waves-light">Save</button>
          </div>
        </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- end modal -->
  </div>
</template>

<script lang="ts">
  import { Vue, Options } from "vue-class-component";
  import { addCompany, deleteCompany, getCountries, getProspects, updateCompany } from "@/store/api/company";
  import Toast from 'izitoast';
  import 'izitoast/dist/css/iziToast.css';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import $, {event} from "jquery";
import { getIndustries } from "@/store/api/industry";

  @Options({
    components: {
      Loading,
    }
  })

  export default class ProspectList extends Vue {
    private isLoading = false;
    private prospectList : any[] = []; //eslint-disable-line
    private industryList : any[] = []; //eslint-disable-line
    private countryList : any[] = []; //eslint-disable-line
    private industry = "";
    private country = "";
    private editId = "";
    private editIndex = -1;
    private currentData = {
      name : "",
      phone : "",
      email : "",
      website : "",
      address : "",
      industry_id : "",
      country_id : "",
      status : false,
    };

    private subscriptionList : any[] = []; //eslint-disable-line

    created() { //eslint-disable-line
      this.getProspects();
      this.getCountries();
      this.getIndustries();
    }

    beforeUnmount() : void { 
      this.subscriptionList.forEach((subscription) => subscription.unsubscribe());
      console.log('The unsubscription goes fine');
    }

    private setEdit(data, index) {
      this.currentData = data;
      this.editId = data.id;
      this.editIndex = index;
      this.country = data.country.country || "Choose Country";
      this.industry = data.industry.name || "Choose Industry";
      console.log('The current edit is: ', data);
    }

    private saveData() {
      // this runs, if the data is an edited data.
      if(this.editId && this.currentData.name) {
        for (let i = 0; i < this.countryList.length; i++) {
          const element = this.countryList[i];
          if(element.country === this.country) {
            this.currentData.country_id = element.id;
            break;
          }
        }

        for (let i = 0; i < this.industryList.length; i++) {
          const element = this.industryList[i];
          if(element.name === this.industry) {
            this.currentData.industry_id = element.id;
            break;
          }
        }

        this.updateCompany();
      } else if(!this.editId && this.currentData.name) {
        for (let i = 0; i < this.countryList.length; i++) {
          const element = this.countryList[i];
          if(element.country === this.country) {
            this.currentData.country_id = element.id;
            break;
          }
        }

        for (let i = 0; i < this.industryList.length; i++) {
          const element = this.industryList[i];
          if(element.name === this.industry) {
            this.currentData.industry_id = element.id;
            break;
          }
        }

        this.addCompany();
      }
    }

    private clearData() {
      $('#myModal').modal('hide');
      this.editId = "";
      this.editIndex = -1;
      this.country = "";
      this.industry = "";
      this.currentData = {
        name : "",
        phone : "",
        email : "",
        website : "",
        address : "",
        industry_id : "",
        country_id : "",
        status : false,
      };
    }

    private getCountries() {
      const getSubscription = getCountries().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          console.log('Get Prospects is fetched successfully');
          this.countryList = response.data.countries;
          console.log("The Countries list value is: ", this.countryList);
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private getIndustries() {
      const getSubscription = getIndustries().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          console.log('Get Industries is fetched successfully');
          this.industryList = response.data.industries;
          console.log("The Countries list value is: ", this.industryList);
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private getProspects() {
      this.isLoading = true;
      const getSubscription = getProspects().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          console.log('Get Prospects is fetched successfully');
          this.prospectList = response.data.companies;
          console.log("The prospects list value is: ", this.prospectList);
          this.isLoading = false;
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private addCompany() {
      this.isLoading = true;
      const getSubscription = addCompany(this.currentData).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          console.log('Add company is successful');
          this.prospectList.push(response.data.company);
          console.log("The prospects list value is: ", this.prospectList);
          this.isLoading = false;
          this.clearData();
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private updateCompany() {
      this.isLoading = true;
      const {name, phone, email, website, address, industry_id, country_id, status} = this.currentData
      const newData = {name, phone, email, website, address, industry_id, country_id, status};
      const getSubscription =  updateCompany(this.editId, newData).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          console.log('update company is successful');
          this.prospectList.splice(this.editIndex, 1, response.data.company);
          console.log("The prospects list value is: ", this.prospectList);
          this.isLoading = false;
          this.clearData();
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private deleteCompany(companyId : string, index : number) {
      Toast.question({
        timeout: 20000,
        close: false,
        overlay: false,
        id: 'question',
        zindex: 1000,
        title: '',
        message: 'Are you sure ?',
        position: "topRight",
        buttons: [
            ['<button><b>YES</b></button>',  (instance, toast) => {
        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          this.isLoading = true;
          const getSubscription = deleteCompany(companyId).subscribe((response) => {
            if(response.data.status === 'success') {
              console.log('Company was deleted successfuly', response);
              this.prospectList.splice(index, 1);
              Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',
                iconColor: 'white',
              });
            } else {
              console.log('Error occured while fetching data', response);
              Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-error',
                iconColor: 'white',
              });
            }

            this.isLoading = false;
          }, (error) => {
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title: `${error}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-error',
              iconColor: 'white',
            });
            console.log('Error occured while fetching data', error);
            this.isLoading = false;
          });
          this.subscriptionList.push(getSubscription);
        }, true],
        ['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
           }, event],
        ],
   
      });
    }
  }
</script>

<style scoped>
  .closeModal {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .closeModal:hover {
    opacity: 1;
  }
  .closeModal:before, .closeModal:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .closeModal:before {
    transform: rotate(45deg);
  }
  .closeModal:after {
    transform: rotate(-45deg);
  }
</style>