
import { Options, Vue } from 'vue-class-component';
import { getUsers, updateUserData, getUserRoles, addUser, deleteUser } from '../store/api/usermgt';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import $ from 'jquery';
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
// import vSelect from "vue-select-3";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { UserUpdate } from '../store/models/user';
import { event } from 'jquery';

@Options({
  components: {
		Loading, PulseLoader
  },
})

/* eslint-disable */
export default class Users extends Vue {
private Users = [];
private userUpdateData: UserUpdate = {
	id: '',
    first_name : '',
    last_name : '',
    email : '',
    telephone : '',
    role : '',
}
private roles = [];
private options2 = [{
      text: "name1",
      value: "value1"
    }, {
      text: "name2",
      value: "value2"
    }, {
      text: "name3",
      value: "value3"
    }]
private isLoading = false;
private isLoadingModal = false;
private loadingIconColor = '#00b8d0';
private alertText = '';
private modalAlert = false;
created() {
 this.getUsers();
}
private getUsers() {
	this.isLoading = true;
    getUsers().subscribe((response: any) => {
		// console.log(response, "userresponse");

		this.Users = response.data.data;
		this.isLoading = false;
    });
	getUserRoles().subscribe((response: any) => {
		// console.log(response.data.roles, 'roles');
		this.roles = response.data.roles;
    });
}
private userRoles(roles: any) {
return roles.map((role: any) => {
	return role.name
}).join();
}
private setUserToUpdate(userData: any) {
	const { id, first_name, last_name, email, telephone, role } = userData;
	this.userUpdateData = {id, first_name, last_name, email, telephone, role };
}
private updateUser() {
	const userdata = {
		first_name : this.userUpdateData.first_name,
		last_name : this.userUpdateData.last_name,
		email : this.userUpdateData.email,
		telephone : this.userUpdateData.telephone,
		role : this.userUpdateData.role
	};
		this.isLoadingModal = true;
		updateUserData(userdata,this.userUpdateData.id).subscribe((response: any) => {
		if(response.data.status == "success") {
			this.isLoadingModal = false;
			$('#updateUserModal').modal('hide')
			this.userUpdateData = {id: '', first_name : '', last_name : '', email : '', telephone : '', role : ''};
			Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 3000, overlay: false,
							id: 'toastAuthy', zindex: 99,  title: 'User updated successfully.',
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-user-check',
							iconColor: 'white',
							// iconUrl: null,  // iconText: 'ico-success',
						});
						getUsers().subscribe((response: any) => {
							this.Users = response.data.data;
						});
				} else if (response.data.status == "error") {
						this.isLoadingModal = false;
						const alertComp: any = document.getElementById('alertDiv');
						this.modalAlert = true;
						this.alertText = response.data.message;
						$('#alertDiv').removeClass("hide-div", "badge-success" );
					 	// $('#alertDiv').addClass("badge-danger",);
						console.log(alertComp);
						setTimeout(() => {
						this.modalAlert = false;
						//  console.log('after 7');
						$('#alertDiv').addClass("hide-div");
						$('#alertDiv').removeClass("badge-warning", "badge-danger", "badge-success");
						this.alertText = '';
						}, 7000)
						//  Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#B22222', timeout: 3000, overlay: false,id: 'toastAuthy', zindex: 99,
						// 	title: response.data.message,
						// 	titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
						// 	icon: 'fa fa-times',   
						// 	iconColor: 'white',
						// });
						 
			}
 
	})
}

private beforeAddUser(){
	this.userUpdateData = {id: '', first_name : '', last_name : '', email : '', telephone : '', role : ''};
}
private addNewUser() {
	const userdata = {
		first_name : this.userUpdateData.first_name,
		last_name : this.userUpdateData.last_name,
		email : this.userUpdateData.email,
		telephone : this.userUpdateData.telephone,
		role : this.userUpdateData.role
	}
	this.isLoadingModal = true;
	addUser(userdata).subscribe((response: any) => {
			if(response.data.status == "success") {
			this.isLoadingModal = false;
			$('#addUserModal').modal('hide');
			this.userUpdateData = {id: '', first_name : '', last_name : '', email : '', telephone : '', role : ''};
			Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 3000, overlay: false,
							id: 'toastAuthy', zindex: 99,  title: 'User added successfully.',
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-user-check',   
							iconColor: 'white',
							// iconUrl: null,  // iconText: 'ico-success',
						});
							getUsers().subscribe((response: any) => {
							this.Users = response.data.data;
						});
			} 
			// else if (response.data.status == "error") 
			else {		console.log('in else for adduser');
			
						this.isLoadingModal = false;
						this.modalAlert = true;
				      	const alertComp: any = document.getElementById('alertDiv');
						this.alertText = 'Kindly provide valid user information';
						$('#alertDiv').removeClass("hide-div", "badge-success" );
					 	// $('#alertDiv').addClass("badge-danger",);
						setTimeout(() => {
						this.modalAlert = false;
						//  console.log('after 7');
						$('#alertDiv').addClass("hide-div");
						$('#alertDiv').removeClass("badge-warning", "badge-danger", "badge-success");
						this.alertText = '';
						}, 7000)
					// Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#B22222', timeout: 3000, overlay: false,id: 'toastAuthy', zindex: 99,
					// 		title: response.data.message,
					// 		titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					// 		icon: 'fa fa-times',   
					// 		iconColor: 'white',
					// 	});
			}
	})
}
private popSelect(dd: any) {
	// console.log(dd.target.value, 'selecInput');
	this.userUpdateData.role = dd.target.value
}

private deleteUser(id: string) {     
    Toast.question({
    timeout: 20000,
    close: false,
    overlay: false,
    id: 'question',
    zindex: 1000,
    title: '',
    message: 'Are you sure ?',
    position: 'center',
    buttons: [
        ['<button><b>YES</b></button>',  (instance, toast) => {
		instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
		deleteUser(id).subscribe((response: any) => {
   		 console.log(response, 'user update response');
        if(response.data.status == "success") {
        	Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 3000, overlay: false,id: 'toastAuthy', zindex: 99,
                        title: response.data.message, titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false, overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',
                    });
        } else {

		}
        getUsers().subscribe((response: any) => {
							this.Users = response.data.data;
						});
    });
         
    }, true],
    ['<button>NO</button>', (instance: any, toast: any) => {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
       }, event],
    ],
   
  });
}
destroyed() {
	//
}
}
