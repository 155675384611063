
import { Options, Vue } from 'vue-class-component';
import { VueEditor } from "vue3-editor";
import { getEmailTemplates, addEmailTemplate, updateEmailTemplate, deleteEmailTemplate } from '../store/api/usermgt';
import { EmailTemplate } from '../store/models/configsettings';
import Loading from 'vue-loading-overlay';
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { event } from 'jquery';
@Options({
  components: {
      VueEditor, Loading, PulseLoader
  },
})
export default class MailTemplate extends Vue {
private content = "";
private title = '';
private EmailTemplates = [];
private Template!: EmailTemplate;
private TemplateData: any = {};
private formview = 'create';
private isLoading = false;
private loadingIconColor = '#00b8d0';
private isLoadingBtn = false;

created(): void {
 this.getTemplates();
}
private getTemplates() {
	this.isLoading = true;
    getEmailTemplates().subscribe((response: any) => {
		this.EmailTemplates = response.data.templates;
		this.isLoading = false;
    });
}

private addNewTemplate() {

    if(this.formview == 'create') {
        console.log(this.formview, 'formview');
        
            const templateData =  {
            title : this.title,
            template : this.content
            }
            this.isLoading = true;
            addEmailTemplate(templateData).subscribe((response: any) => {
            console.log(response, 'user update response')
			if(response.data.status == "success") {
            this.isLoading = false;
            getEmailTemplates().subscribe((response: any) => {
                    this.EmailTemplates = response.data.templates;
                });
			this.title = '';
			this.content = '';
			Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 3000, overlay: false,id: 'toastAuthy', zindex: 99,
							title: response.data.message,
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-check',   
							iconColor: 'white',
						});
            
			}
	})
    } else {
        this.editTemplate();
        console.log(this.formview, 'formview');
    }

    

}
private updateTemplate(template: any, view: string) {
    console.log(view, 'formview in update');
    this.title = template.title;
     this.content = template.template;
     this.TemplateData = template;
     this.formview = view;
}

private editTemplate() {
    const templateData =  {
       title : this.title,
    template : this.content
    }
    this.isLoading = true;
    updateEmailTemplate(templateData, this.TemplateData.id).subscribe((response: any) => {
    console.log(response, 'user update response');
        if(response.data.status == "success") {
        this.isLoading = false;
        this.title = '';
        this.content = '';
        this.TemplateData = null;
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 5000, overlay: false,id: 'toastAuthy', zindex: 99,
                        title: response.data.message, titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false, overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',
                    });
            getEmailTemplates().subscribe((response: any) => {
                    this.EmailTemplates = response.data.templates;
                });
        this.formview = 'create';
        }
    });
}
private deleteTemplate(id: string) {
     Toast.question({
    timeout: 20000,
    close: false,
    overlay: false,
    id: 'question',
    zindex: 1000,
    title: '',
    message: 'Are you sure ?',
    position: 'center',
    buttons: [
        ['<button><b>YES</b></button>',  (instance, toast) => {
		instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        deleteEmailTemplate(id).subscribe((response: any) => {
        console.log(response, 'user update response');
        if(response.data.status == "success") {
        this.title = '';
        this.content = '';
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 5000, overlay: false,id: 'toastAuthy', zindex: 99,
                        title: response.data.message, titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false, overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',
                    });
         getEmailTemplates().subscribe((response: any) => {
                    this.EmailTemplates = response.data.templates;
                });
        } else {
             Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 5000, overlay: false,id: 'toastAuthy', zindex: 99,
                        title: 'Template delete unsuccessfull', titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false, overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',
                    });
        }
    });
         
    }, true],
    ['<button>NO</button>', (instance: any, toast: any) => {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
       }, event],
    ],
   
  });
    console.log(id, 'deleteId')

}
    
}
