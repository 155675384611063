import { ApiConfigService } from "./apiconfig";

const API = new ApiConfigService();

export function getLeadSources() {
  return API.$Request().get('/lead-sources');
}

export function addLeadSource(newData : {name : string}) {
  return API.$Request().post('/lead-sources/add-lead', newData);
}

export function updateLeadSource(dataId : string, update : {name : string}) {
  return API.$Request().post(`/lead-sources/update/${dataId}`, update);
}

export function deleteLeadSource(dataId : string) {
  return API.$Request().delete(`/lead-sources/delete/${dataId}`);
}