import { createStore } from 'vuex'

export default createStore({
  state: {
    userGroups: ['Admin', 'User',],
  },
  mutations: {
    deleteRole: function (state, role: string) {
			state.userGroups = state.userGroups.filter(item => item !== role);
		},
		addRole: function (state, newRole: string) {
			state.userGroups.push(newRole);
		},
  },
  actions: {
  },
  modules: {
  }
})