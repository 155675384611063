import { ApiConfigService } from "./apiconfig";

const API = new ApiConfigService();

export function getProducts() {
  return API.$Request().get('/products');
}

export function getChecklists() {
  return API.$Request().get(`/onboarding-checklists`);
}

export function addChecklist(newData : {product_id : string, checklist : string}) {
  return API.$Request().post('/onboarding-checklists/add-checklist', newData);
}

export function updateChecklist(dataId : string, update : {product_id : string, checklist : string}) {
  return API.$Request().post(`/onboarding-checklists/update/${dataId}`, update);
}

export function deleteChecklist(dataId : string) {
  return API.$Request().delete(`/onboarding-checklists/delete/${dataId}`);
}