
import {Vue, Options} from 'vue-class-component';
import {addTasks, deleteTask, getTasks, getTaskTypes, getUsers, TaskData, updateTask} from "@/store/api/tasksApi";
import {addNote, deleteNote, getNotes, NoteData, updateNote} from "@/store/api/notesApi";
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import $, {event} from "jquery";
import { getCompanies, getOpportunities } from '@/store/api/opportunityApi';
import { VueEditor } from "vue3-editor";
import { getCompany } from '@/store/api/company';


@Options({
    name: 'peopleInfoView',
    components :{
      Loading, VueEditor
    },
})


export default class ViewCompany extends Vue{
  private isLoading = false;

  private opportunityList : any[] = []; //eslint-disable-line
  private companyList : any[] = []; //eslint-disable-line
  private usersList : any[] = []; //eslint-disable-line
  private taskTypes : any[] = []; //eslint-disable-line

  private editId = "";
  private editIndex = -1;
  private opportunity = "";
  private company = "";
  private taskOwner = "";
  private taskType = "";

  private noteEditId = "";
  private noteEditIndex = -1;
  private noteOpportunity = "";
  private noteCompany = "";

  private CompanyData;

  private companyTasks : Record<string, unknown>[] = [];
  private companyNotes : Record<string, unknown>[] = [];
  // Refers to the current task being viewed. Not to be mistaken with currentData.
  private currentTask = {
    task_type : {},
    task_owner : {}
  };

  private currentData : TaskData = {
    task_owner : "",
    company_id : "",
    task_type_id : "",
    opportunity_id : "",
    task_title: "",
    task_description : "",
    priority: "",
    status: "Not Started",
    start_date : "",
    end_date : "",
    source_id : "",
    task_source : "Company",
  };

  private noteData : NoteData = {
    note : "",
    company_id : "",
    opportunity_id : "",
    source_id : "",
    note_source : "Company",
  }

  private subscriptionList : any[] = []; //eslint-disable-line

  created () : void {
    this.CompanyData = this.$route.params;
    this.company = this.CompanyData.name;
    this.currentData.company_id = this.CompanyData.id;
    this.noteData.company_id = this.CompanyData.id;

    console.log('The company data is : ', this.CompanyData);
    this._fetchCompany(this.$route.params.id as string);
    this._getTasks();
    this._getTaskTypes();
    this._getCompanies();
    this._getOpportunities();
    this._getUsers();
    this._getNotes();
  }

  beforeUnmount() : void { 
    this.subscriptionList.forEach((subscription) => subscription.unsubscribe())
    console.log('The unsubscription goes fine');
  }

  private setCurrentTask(data, index) {
    this.currentTask = data;
    this.editIndex = index;
    console.log("The current task is: ", this.currentTask);
  }

  private updateStatus(data) {
    this.setEdit(data, this.editIndex);
    this.saveData();
  }

  private setEdit(data, index) {
    const {task_title, task_description, task_owner, task_type_id, opportunity_id, 
      company_id, priority, status, start_date, end_date, source_id, task_source
    } = data;
    const editData = {task_title, task_description, task_owner : task_owner.id, task_type_id, opportunity_id,
      company_id, priority, status, start_date, end_date : new Date(end_date).toLocaleDateString(), source_id, task_source
    };
    this.currentData = editData;
    this.editId = data.id;
    this.editIndex = index;
    this.company = data.company?.name || "";
    this.opportunity = data.opportunity?.name || "";
    this.taskOwner = data.task_owner?.first_name || "";
    this.taskType = data.task_type?.type || ""
    console.log('The current edit is: ', data);
  }

  private setNoteEdit(data, index) {
    const {opportunity_id, company_id, note, source_id, note_source} = data;
    const editData = { opportunity_id, company_id, note, source_id, note_source};
    this.noteData = editData;
    this.noteEditId = data.id;
    this.noteEditIndex = index;
    this.noteCompany = data.company?.name || "";
    this.noteOpportunity = data.opportunity?.name || "";
    console.log('The current edit is: ', data);
  }

  private saveData() {
    // this runs, if the data is an edited data.
    if(this.editId && this.currentData.task_title) {
      
      this._updateTask();
    } else if(!this.editId && this.currentData.task_title) {

      this.currentData.start_date = new Date().toISOString();
      this.currentData.source_id = this.CompanyData.id;

      this._addTask();
    }
  }

  private saveNoteData() {
    // this runs, if the data is an edited data.
    if(this.noteEditId && this.noteData.note) {

      this._updateNote();
    } else if(!this.noteEditId && this.noteData.note) {

      this.noteData.source_id = this.CompanyData.id;
      this._addNote();
    }
  }

  private clearData() {
    $('#myModal').modal('hide');
    $('#viewModal').modal('hide');
    this.editId = "";
    this.editIndex = -1
    this.company = "";
    this.opportunity = "";
    this.taskOwner = "";
    this.taskType = "";
    this.currentData = {
      task_owner : "",
      company_id : this.CompanyData.id,
      task_type_id : "",
      opportunity_id : "",
      task_title: "",
      task_description : "",
      priority: "",
      status: "Not Started",
      start_date : "",
      end_date : "",
      source_id : "",
      task_source : "Company",
    };
  }

  private clearNoteData() {
    $('#noteModal').modal('hide');
    this.noteEditId = "";
    this.noteEditIndex = -1
    this.noteCompany = "";
    this.noteOpportunity = "";
    this.noteData = {
      note : "",
      company_id : this.CompanyData.id,
      opportunity_id : "",
      source_id : "",
      note_source : "Company",
    };
  }

  private _fetchCompany(dataId : string) {
    this.isLoading = true;
    const getSubscription = getCompany(dataId).subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('This company is fetched successfully');
        this.CompanyData = response.data.company;
        this.isLoading = false;
        console.log("The Company Data value is: ", this.CompanyData);
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _getCompanies() {
    const getSubscription = getCompanies().subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('Companies is fetched successfully');
        this.companyList = response.data.companies;
        console.log("The Company list value is: ", this.companyList);
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _getOpportunities() {
    const getSubscription = getOpportunities().subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('Get Opportunities is fetched successfully');
        this.opportunityList = response.data.opportunity;
        console.log("The Opportunity list value is: ", this.opportunityList);
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _getUsers() {
    const getSubscription = getUsers().subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('Get People is fetched successfully');
        this.usersList = response.data.users;
        console.log("The People list value is: ", this.usersList);
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _getTaskTypes() {
    const subscription = getTaskTypes().subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Data fetched successfully', response);
        let responseData : Record<string, unknown>[] = response.data.types;
        this.taskTypes = responseData;
      } else {
        console.log('Error occured while fetching products', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });

    this.subscriptionList.push(subscription);
  }

  private _getTasks() {
    const getSubscription = getTasks().subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('getTasks Data fetched successfully', response);
        let responseData : Record<string, unknown>[] = response.data.tasks;
        let taskList : any[] = []; //eslint-disable-line
        responseData.forEach((task) => {
          if(task.source_id === this.CompanyData.id) taskList.push(task);
        });
        this.companyTasks = taskList;
      } else {
        console.log('Error occured while fetching products', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });

    this.subscriptionList.push(getSubscription);
  }

  private _addTask() {
    this.isLoading = true;
    const subscription = addTasks(this.currentData).subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Task added successfully', response);
        this._getTasks();
        console.log('The company tasks value is: ', this.companyTasks);
        this.isLoading = false;
        this.clearData();

        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        console.log('Error occured while adding task', response);
        this.isLoading = false;
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while adding data', error);
    });

    this.subscriptionList.push(subscription);
  }

  private _updateTask() {
    this.isLoading = true;
    const getSubscription =  updateTask(this.editId, this.currentData).subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('update Task is successful');
        console.log('The update response is: ', response);
        this._getTasks();
        // this.companyTasks.splice(this.editIndex, 1, response.data.company);
        console.log("Company task list value is: ", this.companyTasks);
        this.isLoading = false;
        this.clearData();
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _deleteTask(taskId : string, index : number) {
    Toast.question({
      timeout: 20000,
      close: false,
      overlay: false,
      id: 'question',
      zindex: 1000,
      title: '',
      message: 'Are you sure ?',
      position: "topRight",
      buttons: [
          ['<button><b>YES</b></button>',  (instance, toast) => {
      instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        this.isLoading = true;
        const getSubscription = deleteTask(taskId).subscribe((response) => {
          if(response.data.status === 'success') {
            console.log('Company was deleted successfuly', response);
            this.companyTasks.splice(index, 1);
            Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-check',
              iconColor: 'white',
            });
          } else {
            console.log('Error occured while fetching data', response);
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-error',
              iconColor: 'white',
            });
          }

          this.isLoading = false;
        }, (error) => {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${error}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', error);
          this.isLoading = false;
        });
        this.subscriptionList.push(getSubscription);
      }, true],
      ['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
         }, event],
      ],
   
    });
  }

  private _getNotes() {
    const getSubscription = getNotes().subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Notes Data is fetched successfully', response);
        let responseData : Record<string, unknown>[] = response.data.notes;
        let taskList : any[] = []; //eslint-disable-line
        responseData.forEach((task) => {
          if(task.source_id === this.CompanyData.id) taskList.push(task);
        });
        this.companyNotes = taskList;
      } else {
        console.log('Error occured while fetching products', response);
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      console.log('Error occured while fetching data', error);
    });

    this.subscriptionList.push(getSubscription);
  }

  private _addNote() {
    this.isLoading = true;
    const subscription = addNote(this.noteData).subscribe((response) => {
      if(response.data.status === 'success') {
        console.log('Task added successfully', response);
        this._getNotes();
        console.log('The company tasks value is: ', this.companyTasks);
        this.isLoading = false;
        this.clearNoteData();

        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        console.log('Error occured while adding task', response);
        this.isLoading = false;
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `Error occured ${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while adding data', error);
    });

    this.subscriptionList.push(subscription);
  }

  private _updateNote() {
    this.isLoading = true;
    const getSubscription =  updateNote (this.noteEditId, this.noteData).subscribe((response) => {
      if(response.data && response.data.status === 'success') {
        console.log('update Task is successful');
        console.log('The update response is: ', response);
        this._getNotes();
        // this.companyTasks.splice(this.editIndex, 1, response.data.company);
        console.log("Company task list value is: ", this.companyTasks);
        this.isLoading = false;
        this.clearNoteData();
        Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-check',
          iconColor: 'white',
        });
      } else {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', response.message);
      }
    }, (error) => {
      Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
        id: 'toastAuthy', zindex: 99,  title: `${error}`,
        titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
        icon: 'fa fa-error',
        iconColor: 'white',
      });
      this.isLoading = false;
      console.log('Error occured while fetching data', error);
    });
    this.subscriptionList.push(getSubscription);
  }

  private _deleteNote(noteId : string, index : number) {
    Toast.question({
      timeout: 20000,
      close: false,
      overlay: false,
      id: 'question',
      zindex: 1000,
      title: '',
      message: 'Are you sure ?',
      position: "topRight",
      buttons: [
          ['<button><b>YES</b></button>',  (instance, toast) => {
      instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        this.isLoading = true;
        const getSubscription = deleteNote(noteId).subscribe((response) => {
          if(response.data.status === 'success') {
            console.log('Company was deleted successfuly', response);
            this.companyNotes.splice(index, 1);
            Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-check',
              iconColor: 'white',
            });
          } else {
            console.log('Error occured while fetching data', response);
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-error',
              iconColor: 'white',
            });
          }

          this.isLoading = false;
        }, (error) => {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${error}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', error);
          this.isLoading = false;
        });
        this.subscriptionList.push(getSubscription);
      }, true],
      ['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
         }, event],
      ],
   
    });
  }
}
