import { ApiConfigService } from "./apiconfig";

const API = new ApiConfigService();


export function getOpportunities() {
  return API.$Request().get('/opportunities');
}
export function getOpportunity( id: string) {
  return API.$Request().get(`/opportunities/view/${id}`);
}

export function getCompanies() {
  return API.$Request().get('/companies');
}
export function addOpportunity(opp: any) {
  return API.$Request().post('/opportunities/add-opportunity', opp);
}
export function deleteOpportunity(opp: any) {
  return API.$Request().delete(`opportunities/delete/${opp}`);
}

export function updateOpportunity(oppId: string, update: any) {
  return API.$Request().post(`/opportunities/update/${oppId}`, update);
}