import { ApiConfigService } from "./apiconfig";

const API = new ApiConfigService();

export function getIndustries() {
  return API.$Request().get('/industries');
}

export function addIndustry(newData : {name : string}) {
  return API.$Request().post('/industries/add-industry', newData);
}

export function updateIndustry(dataId : string, update : {name : string}) {
  return API.$Request().post(`/industries/update/${dataId}`, update);
}

export function deleteIndustry(dataId : string) {
  return API.$Request().delete(`/industries/delete/${dataId}`);
}