
  import { Vue, Options } from "vue-class-component";
  import { addCompany, deleteCompany, getCountries, getProspects, updateCompany } from "@/store/api/company";
  import Toast from 'izitoast';
  import 'izitoast/dist/css/iziToast.css';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import $, {event} from "jquery";
import { getIndustries } from "@/store/api/industry";

  @Options({
    components: {
      Loading,
    }
  })

  export default class ProspectList extends Vue {
    private isLoading = false;
    private prospectList : any[] = []; //eslint-disable-line
    private industryList : any[] = []; //eslint-disable-line
    private countryList : any[] = []; //eslint-disable-line
    private industry = "";
    private country = "";
    private editId = "";
    private editIndex = -1;
    private currentData = {
      name : "",
      phone : "",
      email : "",
      website : "",
      address : "",
      industry_id : "",
      country_id : "",
      status : false,
    };

    private subscriptionList : any[] = []; //eslint-disable-line

    created() { //eslint-disable-line
      this.getProspects();
      this.getCountries();
      this.getIndustries();
    }

    beforeUnmount() : void { 
      this.subscriptionList.forEach((subscription) => subscription.unsubscribe());
      console.log('The unsubscription goes fine');
    }

    private setEdit(data, index) {
      this.currentData = data;
      this.editId = data.id;
      this.editIndex = index;
      this.country = data.country.country || "Choose Country";
      this.industry = data.industry.name || "Choose Industry";
      console.log('The current edit is: ', data);
    }

    private saveData() {
      // this runs, if the data is an edited data.
      if(this.editId && this.currentData.name) {
        for (let i = 0; i < this.countryList.length; i++) {
          const element = this.countryList[i];
          if(element.country === this.country) {
            this.currentData.country_id = element.id;
            break;
          }
        }

        for (let i = 0; i < this.industryList.length; i++) {
          const element = this.industryList[i];
          if(element.name === this.industry) {
            this.currentData.industry_id = element.id;
            break;
          }
        }

        this.updateCompany();
      } else if(!this.editId && this.currentData.name) {
        for (let i = 0; i < this.countryList.length; i++) {
          const element = this.countryList[i];
          if(element.country === this.country) {
            this.currentData.country_id = element.id;
            break;
          }
        }

        for (let i = 0; i < this.industryList.length; i++) {
          const element = this.industryList[i];
          if(element.name === this.industry) {
            this.currentData.industry_id = element.id;
            break;
          }
        }

        this.addCompany();
      }
    }

    private clearData() {
      $('#myModal').modal('hide');
      this.editId = "";
      this.editIndex = -1;
      this.country = "";
      this.industry = "";
      this.currentData = {
        name : "",
        phone : "",
        email : "",
        website : "",
        address : "",
        industry_id : "",
        country_id : "",
        status : false,
      };
    }

    private getCountries() {
      const getSubscription = getCountries().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          console.log('Get Prospects is fetched successfully');
          this.countryList = response.data.countries;
          console.log("The Countries list value is: ", this.countryList);
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private getIndustries() {
      const getSubscription = getIndustries().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          console.log('Get Industries is fetched successfully');
          this.industryList = response.data.industries;
          console.log("The Countries list value is: ", this.industryList);
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private getProspects() {
      this.isLoading = true;
      const getSubscription = getProspects().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          console.log('Get Prospects is fetched successfully');
          this.prospectList = response.data.companies;
          console.log("The prospects list value is: ", this.prospectList);
          this.isLoading = false;
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private addCompany() {
      this.isLoading = true;
      const getSubscription = addCompany(this.currentData).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          console.log('Add company is successful');
          this.prospectList.push(response.data.company);
          console.log("The prospects list value is: ", this.prospectList);
          this.isLoading = false;
          this.clearData();
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private updateCompany() {
      this.isLoading = true;
      const {name, phone, email, website, address, industry_id, country_id, status} = this.currentData
      const newData = {name, phone, email, website, address, industry_id, country_id, status};
      const getSubscription =  updateCompany(this.editId, newData).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          console.log('update company is successful');
          this.prospectList.splice(this.editIndex, 1, response.data.company);
          console.log("The prospects list value is: ", this.prospectList);
          this.isLoading = false;
          this.clearData();
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private deleteCompany(companyId : string, index : number) {
      Toast.question({
        timeout: 20000,
        close: false,
        overlay: false,
        id: 'question',
        zindex: 1000,
        title: '',
        message: 'Are you sure ?',
        position: "topRight",
        buttons: [
            ['<button><b>YES</b></button>',  (instance, toast) => {
        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          this.isLoading = true;
          const getSubscription = deleteCompany(companyId).subscribe((response) => {
            if(response.data.status === 'success') {
              console.log('Company was deleted successfuly', response);
              this.prospectList.splice(index, 1);
              Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',
                iconColor: 'white',
              });
            } else {
              console.log('Error occured while fetching data', response);
              Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-error',
                iconColor: 'white',
              });
            }

            this.isLoading = false;
          }, (error) => {
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title: `${error}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-error',
              iconColor: 'white',
            });
            console.log('Error occured while fetching data', error);
            this.isLoading = false;
          });
          this.subscriptionList.push(getSubscription);
        }, true],
        ['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
           }, event],
        ],
   
      });
    }
  }
