<template>
    <div class="content container-fluid">
				
					<!-- Page Header -->
					<div class="page-header">
						<div class="row align-items-center">
							<div class="col">
								<h3 class="page-title">Users</h3>
								<ul class="breadcrumb">
									<li class="breadcrumb-item"><a>Settings</a></li>
									<li class="breadcrumb-item active">Users</li>
								</ul>
							</div>
							<div class="col-auto">
								<a  data-toggle="modal" data-target="#addUserModal"  class="btn btn-primary btn-sm" @click="beforeAddUser()">
									<i class="fas fa-plus"></i> <small > Add New User </small>
								</a>
								<!-- <a class="btn btn-primary filter-btn" href="javascript:void(0);" id="filter_search">
									<i class="fas fa-filter"></i> <div class="float-right">  <button class="btn btn-sm btn-info"> Add New Document</button> </div>
								</a> -->
							</div>
						</div>
					</div>
					
				<div class="row">
						<div class="col-sm-12">
							<div class="card card-table"> 
								<div class="card-body">
									<div class="table-responsive">
										<table class="table table-center table-hover datatable">
											<thead class="thead-light">
												<tr>
													<th>First Name</th>
													<th>Last Name</th>
													<th>Email  </th>
													<th>Phone</th>
													<th>Role</th>
													<th>Status</th>
													<th colspan="2" >Action</th>
												</tr>
											</thead>
																<loading v-model:active="isLoading"  :can-cancel="true"   :is-full-page="true"></loading> 

											<tbody>
												<tr v-for="(user, i) in Users" :key="i" >
													<td>{{user.first_name}}</td>
													<td>
														<h2 class="table-avatar">
															<a  >
                                                                <!-- <img class="avatar avatar-sm mr-2 avatar-img rounded-circle" src="../assets/img/profiles/avatar-04.jpg" alt="User Image"> -->
                                                                {{user.last_name}}</a>
														</h2>
													</td>
													<td>{{user.email}}</td>
													<td> {{user.telephone}} </td>
													<td v-text="userRoles(user.roles)"></td>
													<td><span class="badge badge-pill" :class="user.status === 'active' ? 'bg-success-light' : 'bg-danger-light'">{{user.status}}</span></td>
													<td  >
														<a href="javascript:void(0); " data-toggle="modal" data-target="#updateUserModal" class="btn btn-sm btn-white text-success mr-2" @click="setUserToUpdate(user)"><i class="far fa-edit mr-1" ></i> Edit</a> 
														<a href="javascript:void(0);" class="btn btn-sm btn-white text-danger" @click.prevent="deleteUser(user.id)"><i class="far fa-trash-alt mr-1"></i>Delete</a>
														<!-- <a href="javascript:void(0);" class="btn btn-sm btn-white text-danger"><i class="far fa-trash-alt mr-1"></i>Delete</a> -->
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>  
					<!-- Modal Add New User-->
                    <div class="modal right  fade" id="addUserModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="addUserModalLabel">
                      <div class="modal-dialog modal-lg " role="document">
                        <div class="modal-content p-4" style="background-color: #eeeeee;">
                        <span class="closeModal" data-dismiss="modal"  > </span>

                          <div class="modal-header mt-3 ml-2" style="background-color: #eeeeee;">
									<h5 >Add a new user</h5>
                          </div>
									<hr>
                     <div v-show="modalAlert" class=" animated slideInDown float-right text-danger mr-2   pb-2 px-3" id="alertDiv"> <i class="fa fa-info-circle mr-1 text-danger"> </i> &nbsp; <span style="font-size:14px; font-weight:500;"> {{alertText}} </span></div>
								<div class="modal-body ml-3">
									<form class=" mt-2"  >
										<div class="row">
											<div class="col-md-6 col-sm-10">
												<div class="form-group">
													<label class="req">First Name</label>
													<input v-model="userUpdateData.first_name" type="text" class="form-control" placeholder="Enter First Name">
												</div>
											</div>
											<div class="col-md-6 col-sm-10">
												<div class="form-group">
													<label class="req">Last Name</label>
													<input v-model="userUpdateData.last_name" type="text" class="form-control" placeholder="Enter Last Name">
												</div>
											</div>
											<div class="col-md-6 col-sm-10">
											
												<div class="form-group">
													<label class="req">Email</label>
													<input v-model="userUpdateData.email" type="email" class="form-control" placeholder="Enter Email">
												</div>
											</div>
											<div class="col-md-6 col-sm-10">
												<div class="form-group">
													<label class="req">Phone</label>
													<input v-model="userUpdateData.telephone" userUpdateData.telephone type="text" class="form-control" placeholder="Enter Phone">
												</div>
											</div>
											<div class="col-md-12 col-sm-10">
													<div class="form-group">
														<label class="req form-control-label" for=""> Role </label> 
															<select @input="popSelect($event)" class="custom-select   mb-3">
														<option selected>Select user role</option>
														<option v-for="(role, i) in roles" :key="i"   :value="role.name">{{role.name}}</option>
														</select>
											</div>
											</div>
										</div>
									</form>
									<div class="text-right mt-4">
											<button type="submit" class="btn btn-primary btn-lg " @click.prevent="addNewUser()" :disabled="isLoadingModal"> 
										<pulse-loader v-if="isLoadingModal" :loading="true" color="white" size="10px" style="display: inline-block"></pulse-loader>
											&nbsp;<span  > <i data-feather="user-plus"> </i> &nbsp;{{isLoadingModal ? 'Adding User' : 'Add User'}}</span> 
											</button>
										</div>
                          </div>

                        </div><!-- modal-content -->
                      </div><!-- modal-dialog -->
                    </div><!-- modal -->

					<!-- Modal Update User-->
                    <div class="modal right fade" id="updateUserModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="updateUserModalLabel">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content" style="background-color: #eeeeee;">
                        <span class="closeModal" data-dismiss="modal"  > </span>

                          <div class="modal-header mb-0" style="background-color: #eeeeee;">
                                  <div class=" ml-4 mr-0 mt-3">
                              <h5> Update User Infomation</h5>
                              </div>
                          </div>
                     <div v-show="modalAlert" class=" animated slideInDown float-right text-danger mr-2 mt-4 pt-2 pb-2 px-3" id="alertDiv"> <i class="fa fa-info-circle mr-1 text-danger"> </i> &nbsp; <span style="font-size:14px; font-weight:500;"> {{alertText}} </span></div>
								<div class="modal-body">
									<div  class="element-wrapper" >
										<div class="element-box">	 						 
								<form  class="p-3">
									<div class="form-group">
										<label class="form-control-label">First Name</label>
										<input v-model="userUpdateData.first_name" class="form-control" type="text">
									</div>
									<div class="form-group">
										<label class="form-control-label">Last Name</label>
										<input v-model="userUpdateData.last_name" class="form-control" type="text">
									</div>
									<div class="form-group">
										<label class="form-control-label">Email</label>
										<input v-model="userUpdateData.email" class="form-control" type="text">
									</div>
									<div class="form-group">
										<label class="form-control-label">Phone</label>
										<input v-model="userUpdateData.telephone" class="form-control" type="text">
									</div>
									<div class="form-group">
										<label class="req form-control-label" for=""> Role </label> 
                                       <select @input="popSelect($event)"  class="custom-select   mb-3">
										<option selected>Select user role</option>
										<option v-for="(role, i) in roles" :key="i"   :value="role.name">{{role.name}}</option>
										</select>
										
									</div>

								
									<div class="form-group mb-0">
										<button class="btn btn-lg btn-block btn-primary" @click.prevent="updateUser()" :disabled="isLoadingModal"> 
											<!-- <i data-feather="user-plus"> </i> -->
										<pulse-loader v-if="isLoadingModal" :loading="true" color="white" size="10px" style="display: inline-block"></pulse-loader>
										&nbsp; 
										{{isLoadingModal ? 'Updating' : 'Update'}} 
										</button>
									</div>
								</form>
										</div>
                            </div>
                          </div>

                        </div><!-- modal-content  -->
                      </div><!-- modal-dialog -->
                    </div><!-- modal -->
					

					
				</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { getUsers, updateUserData, getUserRoles, addUser, deleteUser } from '../store/api/usermgt';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import $ from 'jquery';
import Toast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
// import vSelect from "vue-select-3";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { UserUpdate } from '../store/models/user';
import { event } from 'jquery';

@Options({
  components: {
		Loading, PulseLoader
  },
})

/* eslint-disable */
export default class Users extends Vue {
private Users = [];
private userUpdateData: UserUpdate = {
	id: '',
    first_name : '',
    last_name : '',
    email : '',
    telephone : '',
    role : '',
}
private roles = [];
private options2 = [{
      text: "name1",
      value: "value1"
    }, {
      text: "name2",
      value: "value2"
    }, {
      text: "name3",
      value: "value3"
    }]
private isLoading = false;
private isLoadingModal = false;
private loadingIconColor = '#00b8d0';
private alertText = '';
private modalAlert = false;
created() {
 this.getUsers();
}
private getUsers() {
	this.isLoading = true;
    getUsers().subscribe((response: any) => {
		// console.log(response, "userresponse");

		this.Users = response.data.data;
		this.isLoading = false;
    });
	getUserRoles().subscribe((response: any) => {
		// console.log(response.data.roles, 'roles');
		this.roles = response.data.roles;
    });
}
private userRoles(roles: any) {
return roles.map((role: any) => {
	return role.name
}).join();
}
private setUserToUpdate(userData: any) {
	const { id, first_name, last_name, email, telephone, role } = userData;
	this.userUpdateData = {id, first_name, last_name, email, telephone, role };
}
private updateUser() {
	const userdata = {
		first_name : this.userUpdateData.first_name,
		last_name : this.userUpdateData.last_name,
		email : this.userUpdateData.email,
		telephone : this.userUpdateData.telephone,
		role : this.userUpdateData.role
	};
		this.isLoadingModal = true;
		updateUserData(userdata,this.userUpdateData.id).subscribe((response: any) => {
		if(response.data.status == "success") {
			this.isLoadingModal = false;
			$('#updateUserModal').modal('hide')
			this.userUpdateData = {id: '', first_name : '', last_name : '', email : '', telephone : '', role : ''};
			Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 3000, overlay: false,
							id: 'toastAuthy', zindex: 99,  title: 'User updated successfully.',
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-user-check',
							iconColor: 'white',
							// iconUrl: null,  // iconText: 'ico-success',
						});
						getUsers().subscribe((response: any) => {
							this.Users = response.data.data;
						});
				} else if (response.data.status == "error") {
						this.isLoadingModal = false;
						const alertComp: any = document.getElementById('alertDiv');
						this.modalAlert = true;
						this.alertText = response.data.message;
						$('#alertDiv').removeClass("hide-div", "badge-success" );
					 	// $('#alertDiv').addClass("badge-danger",);
						console.log(alertComp);
						setTimeout(() => {
						this.modalAlert = false;
						//  console.log('after 7');
						$('#alertDiv').addClass("hide-div");
						$('#alertDiv').removeClass("badge-warning", "badge-danger", "badge-success");
						this.alertText = '';
						}, 7000)
						//  Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#B22222', timeout: 3000, overlay: false,id: 'toastAuthy', zindex: 99,
						// 	title: response.data.message,
						// 	titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
						// 	icon: 'fa fa-times',   
						// 	iconColor: 'white',
						// });
						 
			}
 
	})
}

private beforeAddUser(){
	this.userUpdateData = {id: '', first_name : '', last_name : '', email : '', telephone : '', role : ''};
}
private addNewUser() {
	const userdata = {
		first_name : this.userUpdateData.first_name,
		last_name : this.userUpdateData.last_name,
		email : this.userUpdateData.email,
		telephone : this.userUpdateData.telephone,
		role : this.userUpdateData.role
	}
	this.isLoadingModal = true;
	addUser(userdata).subscribe((response: any) => {
			if(response.data.status == "success") {
			this.isLoadingModal = false;
			$('#addUserModal').modal('hide');
			this.userUpdateData = {id: '', first_name : '', last_name : '', email : '', telephone : '', role : ''};
			Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 3000, overlay: false,
							id: 'toastAuthy', zindex: 99,  title: 'User added successfully.',
							titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
							icon: 'fa fa-user-check',   
							iconColor: 'white',
							// iconUrl: null,  // iconText: 'ico-success',
						});
							getUsers().subscribe((response: any) => {
							this.Users = response.data.data;
						});
			} 
			// else if (response.data.status == "error") 
			else {		console.log('in else for adduser');
			
						this.isLoadingModal = false;
						this.modalAlert = true;
				      	const alertComp: any = document.getElementById('alertDiv');
						this.alertText = 'Kindly provide valid user information';
						$('#alertDiv').removeClass("hide-div", "badge-success" );
					 	// $('#alertDiv').addClass("badge-danger",);
						setTimeout(() => {
						this.modalAlert = false;
						//  console.log('after 7');
						$('#alertDiv').addClass("hide-div");
						$('#alertDiv').removeClass("badge-warning", "badge-danger", "badge-success");
						this.alertText = '';
						}, 7000)
					// Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#B22222', timeout: 3000, overlay: false,id: 'toastAuthy', zindex: 99,
					// 		title: response.data.message,
					// 		titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
					// 		icon: 'fa fa-times',   
					// 		iconColor: 'white',
					// 	});
			}
	})
}
private popSelect(dd: any) {
	// console.log(dd.target.value, 'selecInput');
	this.userUpdateData.role = dd.target.value
}

private deleteUser(id: string) {     
    Toast.question({
    timeout: 20000,
    close: false,
    overlay: false,
    id: 'question',
    zindex: 1000,
    title: '',
    message: 'Are you sure ?',
    position: 'center',
    buttons: [
        ['<button><b>YES</b></button>',  (instance, toast) => {
		instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
		deleteUser(id).subscribe((response: any) => {
   		 console.log(response, 'user update response');
        if(response.data.status == "success") {
        	Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 3000, overlay: false,id: 'toastAuthy', zindex: 99,
                        title: response.data.message, titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false, overlayClose: true,
                        icon: 'fa fa-check',   
                        iconColor: 'white',
                    });
        } else {

		}
        getUsers().subscribe((response: any) => {
							this.Users = response.data.data;
						});
    });
         
    }, true],
    ['<button>NO</button>', (instance: any, toast: any) => {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
       }, event],
    ],
   
  });
}
destroyed() {
	//
}
}
</script>

<style scoped>
.hide-div {
    display: none!important;
}
.closeModal {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.closeModal:hover {
  opacity: 1;
}
.closeModal:before, .closeModal:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.closeModal:before {
  transform: rotate(45deg);
}
.closeModal:after {
  transform: rotate(-45deg);
}
</style>