
  import { Vue, Options } from "vue-class-component";
  import { addCompany, getCountries, updateCompany } from "@/store/api/company";
  import { getOpportunities, getCompanies, addOpportunity, deleteOpportunity, updateOpportunity } from "@/store/api/opportunityApi";
  import { GetAllProducts, getStage  } from "@/store/api/configsettings";
  import { getPeople  } from "@/store/api/people";
  import { getUsers  } from "@/store/api/usermgt";
  import Toast from 'izitoast';
  import 'izitoast/dist/css/iziToast.css';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import $, {event} from "jquery";
  import { getIndustries } from "@/store/api/industry";
  import VueSelect from 'vue-next-select';
  import 'vue-next-select/dist/index.min.css';
//   <vue-select v-model.trim="selectedOption" :options="productNames" close-on-select required></vue-select> 
  @Options({
    components: {
      Loading, VueSelect
    }
  })

  export default class OpportunityList extends Vue {
    private isLoading = false;
    private prospectList : any[] = []; //eslint-disable-line
    private opportunityList : any[] = []; //eslint-disable-line
    private industryList : any[] = []; //eslint-disable-line
    private countryList : any[] = []; //eslint-disable-line
    private companyList : any[] = []; //eslint-disable-line
    private productList : any[] = []; //eslint-disable-line
    private contactList : any[] = []; //eslint-disable-line
    private stages : any[] = []; //eslint-disable-line
    private users : any[] = []; //eslint-disable-line
    private industry = "";
    private country = "";
    private editId = "";
    private oppId = "";
    private editIndex = -1;
    
    private currentData = {
      name : "",
      phone : "",
      email : "",
      website : "",
      address : "",
      industry_id : "",
      country_id : "",
      status : false,
    };
    private opportunity = {
        product_id: "",
        company_id: "",
        main_contact: "",
        stage_id: "",
        deal_date: "",
        opportunity_owner: "",
        close_date: "",
        priority: "",
        license_type: "",
        number_of_users: "",
        license_value: "",
        description: "",
    };
    private opportunityUpdate = {
        product_id: "",
        company_id: "",
        main_contact: "",
        stage_id: "",
        deal_date: "",
        opportunity_owner: "",
        close_date: "",
        priority: "",
        license_type: "",
        number_of_users: "",
        license_value: "",
        description: "",
    };

    private subscriptionList : any[] = []; //eslint-disable-line

    created() { //eslint-disable-line
      this.getAllOpportunities();
      this.getCountries();
      this.getIndustries();
      this.getAllProducts();
      this.getCompanies();
      this.getStages();
      this.getContacts();
      this.getUsers();
    }

    beforeUnmount() : void { 
      this.subscriptionList.forEach((subscription) => subscription.unsubscribe());
      // console.log('The unsubscription goes fine');
    }

    private setEdit(data, index) {
      this.currentData = data;
      this.editId = data.id;
      this.editIndex = index;
      this.country = data.country.country || "Choose Country";
      this.industry = data.industry.name || "Choose Industry";
      console.log('The current edit is: ', data);
    }

    private saveData() {
      // this runs, if the data is an edited data.
      if(this.editId && this.currentData.name) {
        for (let i = 0; i < this.countryList.length; i++) {
          const element = this.countryList[i];
          if(element.country === this.country) {
            this.currentData.country_id = element.id;
            break;
          }
        }

        for (let i = 0; i < this.industryList.length; i++) {
          const element = this.industryList[i];
          if(element.name === this.industry) {
            this.currentData.industry_id = element.id;
            break;
          }
        }

        this.updateCompany();
      } else if(!this.editId && this.currentData.name) {
        for (let i = 0; i < this.countryList.length; i++) {
          const element = this.countryList[i];
          if(element.country === this.country) {
            this.currentData.country_id = element.id;
            break;
          }
        }

        for (let i = 0; i < this.industryList.length; i++) {
          const element = this.industryList[i];
          if(element.name === this.industry) {
            this.currentData.industry_id = element.id;
            break;
          }
        }

        this.addCompany();
      }
    }

    private clearData() {
      $('#myModal').modal('hide');
      this.editId = "";
      this.editIndex = -1;
      this.country = "";
      this.industry = "";
      this.currentData = {
        name : "",
        phone : "",
        email : "",
        website : "",
        address : "",
        industry_id : "",
        country_id : "",
        status : false,
      };
    }
    private closeModal() {
      $('#leadModal').modal('hide');
        this.opportunity = {
        product_id: "",
        company_id: "",
        main_contact: "",
        stage_id: "",
        opportunity_owner: "",
        close_date: "",
        priority: "",
        deal_date: "",
        license_type: "",
        number_of_users: "",
        license_value: "",
        description: "",
    };
    }
    private closeEditModal() {
      $('#myEditModal').modal('hide');
      this.oppId = '';
    //     this.opportunityUpdate = {
    //     product_id: "",
    //     company_id: "",
    //     main_contact: "",
    //     stage_id: "",
    //     opportunity_owner: "",
    //     license_type: "",
    //     number_of_users: "",
    //     license_value: "",
    //     description: "",
    // };
    }

    private getCountries() {
      const getSubscription = getCountries().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          // console.log('Get Prospects is fetched successfully');
          
          this.countryList = response.data.countries;
          // console.log("The Countries list value is: ", this.countryList);
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private getIndustries() {
      const getSubscription = getIndustries().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          // console.log('Get Industries is fetched successfully');
          this.industryList = response.data.industries;
          // console.log("The Countries list value is: ", this.industryList);
        }  
      });
      this.subscriptionList.push(getSubscription);
    }

    private getCompanies() {
      const getSubscription = getCompanies().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.companyList = response.data.companies;
        } 
      });
      this.subscriptionList.push(getSubscription);
    }
    private getAllProducts() {
      const getSubscription = GetAllProducts().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.productList = response.data.products;
        }  
      });
      this.subscriptionList.push(getSubscription);
    }

    private getContacts() {
      const getSubscription = getPeople().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.contactList = response.data.people;
        }
      });
      this.subscriptionList.push(getSubscription);
    }

    private getStages() {
      const getSubscription = getStage().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.stages = response.data.stages;
        }
      });
      this.subscriptionList.push(getSubscription);
    }

    private getUsers() {
      const getSubscription = getUsers().subscribe((response) => {
        if(response.data) {
          this.users = response.data.data;
        }
      });
      this.subscriptionList.push(getSubscription);
    }

    private getAllOpportunities() {
      this.isLoading = true;
      const getSubscription = getOpportunities().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.opportunityList = response.data.opportunity;
          this.isLoading = false;
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }


    private addOpportunity(opp: any) {
        console.log(opp, 'opportunity');
          this.isLoading = true;
      const getSubscription = addOpportunity(opp).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          getOpportunities().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.opportunityList = response.data.opportunity;
        }})
          this.isLoading = false;
          this.closeModal();
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 4000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 4000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 4000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }
    private addCompany() {
      this.isLoading = true;
      const getSubscription = addCompany(this.currentData).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          // console.log('Add company is successful');
          this.prospectList.push(response.data.company);
          this.companyList.push(response.data.company);
          // console.log("The prospects list value is: ", this.prospectList);
          this.isLoading = false;
          this.clearData();
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private updateCompany() {
      this.isLoading = true;
      const getSubscription =  updateCompany(this.editId, this.currentData).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          // console.log('update company is successful');
          this.prospectList.splice(this.editIndex, 1, response.data.company);
          // console.log("The prospects list value is: ", this.prospectList);
          this.isLoading = false;
          this.clearData();
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }


    private editOpp(x: any) {
      console.log(x, 'curr dtat');
      this.oppId = x.id;
    //     this.opportunityUpdate = {
    //     product_id: "",
    //     company_id: "",
    //     main_contact: "",
    //     stage_id: "",
    //     opportunity_owner: "",
    //     close_date: "",
    //     priority: "",
    //     deal_date: "",
    //     license_type: "",
    //     number_of_users: "",
    //     license_value: "",
    //     description: "",
    // };
    //  this.opportunityUpdate.close_date = x.close_date;
     this.opportunityUpdate.priority = x.priority;
    //  this.opportunityUpdate.deal_date =  x.deal_date;
    //  this.opportunityUpdate.license_type = x.license_type;
     this.opportunityUpdate.number_of_users = x.number_of_users;
     this.opportunityUpdate.license_value = x.license_value;
     this.opportunityUpdate.description = x.description;
    }
    private updateOpportunity(data: any) {
      this.isLoading = true;
      const getSubscription =  updateOpportunity(this.oppId, this.opportunityUpdate).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
        getOpportunities().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.opportunityList = response.data.opportunity;
        }})
          this.isLoading = false;
          this.closeEditModal();
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
          // Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          //   id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          //   titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          //   icon: 'fa fa-check',
          //   iconColor: 'white',
          // });
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }
    private updateOpportunityStage(stageName: any, data: any) {
      console.log(this.stages, '--', stageName, '---', data);
      const stageId = this.stages.find((stage) => {
          if(stage.name === stageName) {
            return stage.id
          }
      });
      console.log(stageId, 'stageId');
      let license_typeCode = 0;
      if(data.license_type == "Per User License" || data.license_type == '1') {
        license_typeCode = 1;
      } else {
        license_typeCode = 2;
      }
         const opportunityUpdate = {
         product_id: data.product_id,
        company_id: data.company_id,
        main_contact: data.main_contact,
        stage_id: stageId.id,
        opportunity_owner: data.opportunity_owner,
        close_date: data.close_date,
        priority: data.priority,
        deal_date: data.deal_date,
        license_type: license_typeCode,
        number_of_users: data.number_of_users,
        license_value: data.license_value,
        description: data.description,
    };
      
      this.isLoading = true;
      const getSubscription =  updateOpportunity(data.id, opportunityUpdate).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
        getOpportunities().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.opportunityList = response.data.opportunity;
        }})
         this.isLoading = false;
 
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private deleteOpp(oppId : string) {
      Toast.question({
        timeout: 20000,
        close: false,
        overlay: false,
        id: 'question',
        zindex: 1000,
        title: '',
        message: 'Are you sure ?',
        position: "topRight",
        buttons: [
            ['<button><b>YES</b></button>',  (instance, toast) => {
        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          this.isLoading = true;
          const getSubscription = deleteOpportunity(oppId).subscribe((response) => {
            if(response.data.status === 'success') {
                  getOpportunities().subscribe((response) => {
                if(response.data && response.data.status === 'success') {
                this.opportunityList = response.data.opportunity;
                }})
                this
              Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',
                iconColor: 'white',
              });
            } else {
              console.log('Error occured while fetching data', response);
              Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-error',
                iconColor: 'white',
              });
            }

            this.isLoading = false;
          }, (error) => {
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title: `${error}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-error',
              iconColor: 'white',
            });
            console.log('Error occured while fetching data', error);
            this.isLoading = false;
          });
          this.subscriptionList.push(getSubscription);
        }, true],
        ['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
           }, event],
        ],
   
      });
    }

    private numberWithCommas(x: any) {
    if (!x) return 0;
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

    private dateSelect(x: any) {
      console.log(new Date(x), x, 'datr'); 
  }
  }
