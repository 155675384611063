import {ApiConfigService} from './apiconfig';

const API = new ApiConfigService();

export function getPeople(){
    return API.$Request().get('/people');
}

export function getCompanies(){
    return API.$Request().get('/companies');
}

export function addPeople(Person: any){
    return API.$Request().post('/people/add-people', Person);
}

export function updatePeople(Person: any, personid:string){
    return API.$Request().post(`/people/update/${personid}`, Person);
}

export function deletePeople(personid:string){
    return API.$Request().delete(`/people/delete/${personid}`);
}