import { ApiConfigService } from './apiconfig';
import { User } from '../models/user';
import { EmailTemplate } from '../models/configsettings';
import toast from '../../components/toast';

const API = new ApiConfigService();
export async function userLogin(userLogin: any) {
    try {
        const response = await API.LoginRequest().post('/auth/login', userLogin);
        if (response.data.status == "success") {
            console.log(response.data, 'response login');
            
            localStorage.setItem('token', response.data.message.original.access_token);
            localStorage.setItem('user', JSON.stringify(response.data.message.original.user));
        }  
        return response.data;
    } catch (error) {
        toast.error('', 'Login Error', 5000);
        return error;
    }
}

// function handleError(error: any) {
//     toast.error(error.response.data, 'Error', 5000);
// }
export function logOut() {
 return API.$Request().post('/auth/logout'); 
}
export function getUsers() {
    return API.$Request().get('/users');
}
export function getUserRoles() {
    return API.$Request().get('/roles'); 
}
   
export function updateUserData(user: User, userid: string) {
    return API.$Request().post(`/users/update/${userid}`, user);
}
export function addUser(user: User) {
    return API.$Request().post('/users/add-user', user);
}
export function deleteUser(  userId: string) {
    return API.$Request().delete(`/users/delete/${userId}` );
}

//mail templates
export function getEmailTemplates() {
    return API.$Request().get('/email-templates');
}
export function addEmailTemplate(mail: any) {
    return API.$Request().post('/email-templates/add-template', mail);
}
export function getEmailTemplate(  templateId: string) {
    return API.$Request().post(`/email-templates/view/${templateId}` );
}
export function deleteEmailTemplate(  templateId: string) {
    return API.$Request().delete(`/email-templates/delete/${templateId}` );
}
export function updateEmailTemplate(template: EmailTemplate, templateId: string) {
    return API.$Request().post(`/email-templates/update/${templateId}`, template);
}

// docTemplate
export function getDocumentTemplates() {
    return API.$Request().get('/document-templates');
}
export function getProducts() {
    return API.$Request().get('/products');
}
export function addDocumentTemplate(Doc: any) {
    return API.$Request().post('/document-templates/add-template', Doc);
}
export function deleteDocumentTemplate(  templateId: string) {
    return API.$Request().delete(`/email-templates/delete/${templateId}` );
}
export function updateDocumentTemplate(Doc: EmailTemplate, docId: string) {
    return API.$Request().post(`/document-templates/update/${docId}`, Doc);
}

