import { ApiConfigService } from "./apiconfig";

const API = new ApiConfigService();

export function getRoles() {
  return API.$Request().get('/roles-and-permissions');
}

export function getPermissions() {
  return API.$Request().get('/permissions');
}

export function addPermissionsToRole(role : string, permission : string[]) {
  return API.$Request().post('/roles/add-permissions-to-role', {role, permission});
}

export function addRole(newData : {name : string}) {
  return API.$Request().post('/roles/add-role', newData);
}

export function editRole(roleId : string, newData : {name : string}) {
  return API.$Request().post(`/roles/update/${roleId}`, newData);
}

export function deleteRole (roleId : string) {
  return API.$Request().delete(`/roles/delete/${roleId}`);
}
