<template>
  <loading v-model:active="isLoading"  :can-cancel="true"   :is-full-page="true"></loading> 
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="page-title">Opportunities</h3>
          <ul class="breadcrumb">
            <li class="breadcrumb-item"><a>Main</a></li>
            <li class="breadcrumb-item active">Opportunity List</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /Page Header -->
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Opportunity List</h4>
                    <p class="card-title-desc mb-3">
                      An Opportunity represents any kind of business development effort that you would like to track and move along through a process. Make sure to accurately update your expected close date. This will allow you to better forecast what your expected sales are for the end of your sales cycle.
                    </p>
                                  <div class="float-right mt-2"> 
                            <button data-toggle="modal" data-target="#leadModal" type="button" class="btn btn-outline-primary w-xs  "
                                >Add New Opportunity</button>
                        </div>
                    <div id="datatable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer"><div class="row"><div class="col-sm-12 col-md-6"><div class="dataTables_length" id="datatable_length"><label>Show <select name="datatable_length" aria-controls="datatable" class="custom-select custom-select-sm form-control form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label></div></div>
                        <div class="col-sm-12 col-md-6"><div id="datatable_filter" class="dataTables_filter"><label>Search:<input type="search" class="form-control form-control-sm" placeholder="" aria-controls="datatable"></label></div></div>
                        
          
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <table id="datatable" class="table table-bordered dt-responsive nowrap dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px; width: 100%;" role="grid" aria-describedby="datatable_info">
                        <thead>
                            <tr role="row">
                                <th class="sorting_asc" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 163.2px;" aria-sort="ascending" aria-label="Name: activate to sort column descending">Company</th>
                                <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 118.2px;" aria-label="Industry: activate to sort column ascending">Product</th>
                                <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 86.2px;" aria-label="Opportunity: activate to sort column ascending">Stage</th>
                                <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 102.2px;" aria-label="Email Address: activate to sort column ascending"> License</th>
                                <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 75.2px;" aria-label="Phone: activate to sort column ascending">Date Created</th>
                                <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 75.2px;" aria-label="Phone: activate to sort column ascending">Value</th>
                                <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 75.2px;" aria-label="Phone: activate to sort column ascending">Close Date</th>
                                <th class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" style="width: 75.2px;" aria-label="Phone: activate to sort column ascending"> Priority</th>
                                <th style="width: 20.2px;" class="sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label=": activate to sort column ascending"></th>
                                </tr>
                        </thead>
                        <tbody>                    
                          <tr v-for="data, index in opportunityList" :key="index" role="row" class="odd">
                                <td class="sorting_1 dtr-control">{{data.company.name }}</td>
                                <td>{{data.product.name }}</td>
                                <td> <span class="pt-2" :class="{ 'badge badge-dark': data.stage.order_no === 1, 'badge badge-success': data.stage.order_no === 199, 'badge badge-danger': data.stage.order_no === 200, 'badge badge-primary': data.stage.order_no < 199 && data.stage.order_no >= 2, }" >
                                    {{data.stage.name }}
                                    </span> </td>
                                <td> <span> {{ data.license_type = "1" ? "Per User License" : "Corporate License"}}</span></td>
                                <td>{{data.deal_date.split(' ')[0] }}</td>
                                <td>&#x20A6;{{numberWithCommas(data.license_value) }}</td>
                                <td>{{data.close_date.split(' ')[0] }}</td>
                                <td>
                                  <span class="pt-2 px-3 "    :class="{ 'badge badge-sm badge-danger': data.priority === 'High', 'badge badge-sm badge-primary': data.priority === 'Low', 'badge badge-sm badge-warning': data.priority === 'Medium' }" >
                                    {{ data.priority }}
                                    </span>
                                 </td>
                                <td>
                                    <div class="btn-group dropup">
                                        <a class="font-size-16 px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                        </a>
                                        <div class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -164px, 0px);" x-placement="top-start">
                                               <router-link :to="{name:`opportunityInfo`, params: data}" class="dropdown-item text-info"  >
                                                  <i class="fa fa-eye"></i> View
                                                </router-link>
                                            <a @click="editOpp(data)" class="dropdown-item" href="javascript:;" data-toggle="modal" data-target="#myEditModal" style="cursor : pointer;">
                                               <i class="fa fa-edit"> </i>
                                              Edit
                                            </a>
                                            <a @click="deleteOpp(data.id)" class="dropdown-item text-danger" href="#">
                                              <i class="fa fa-trash"> </i>
                                              Delete
                                            </a>
                                            <div class="dropdown-divider"></div>
                                               <a class="dropdown-item text-success" style="cursor:pointer;" @click="updateOpportunityStage('Won', data)"><i class="fa fa-thumbs-up" ></i> Won</a>
                                               <a class="dropdown-item text-danger" style="cursor:pointer;" @click="updateOpportunityStage('Lost', data)"><i class=" fa fa-thumbs-down" ></i> Lost</a>

                                        </div>
                                    </div>
                                </td>
                          </tr>
                        </tbody>
                    </table></div></div>
                    <div class="row" style="margin-top : 15px">
                      <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing 1 to {{opportunityList.length}} of {{opportunityList.length}}</div>
                      </div>
                      <div style="display: flex; justify-content: flex-end;" class="col-sm-12 col-md-7"><div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                        <ul class="pagination"><li class="paginate_button page-item previous disabled" id="datatable_previous"><a href="#" aria-controls="datatable" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li><li class="paginate_button page-item active"><a href="#" aria-controls="datatable" data-dt-idx="1" tabindex="0" class="page-link">1</a></li><li class="paginate_button page-item next" id="datatable_next"><a href="#" aria-controls="datatable" data-dt-idx="4" tabindex="0" class="page-link">Next</a></li></ul>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div> <!-- end col -->
        <!-- end col -->
    </div>
  <!-- <button data-toggle="modal" data-target="#myModal" class="btn btn-info w-xs  "     >Add New Opportunity</button> -->

 
    <!--Start Modal -->

    <!-- end modal -->
    <!--Start Modal -->
    <div class="modal right fade" id="leadModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-lg" role="document">
             <!--Start Modal -->
    <div class="modal right fade" id="myModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel" > 
      <div class="modal-dialog" role="document" >
        <div class="modal-content" style="background-color: #eeeeee; z-index:2000!important;">
        <span @click="clearData" style="cursor: pointer;" class="closeModal"> </span>

            <div class="modal-header mb-0" style="background-color: #eeeeee;">
                    <div class=" ml-4 mr-0 mt-3">
                <h5> Add Company </h5>
                </div>
            </div>
<form @submit.prevent="saveData" >
          <div class="modal-body" >
              <div class="row">
                  <div class="col-md-12">
                      <div class="form-group">
                          <label>Company Name <span style="color : red;">*</span></label>
                          <input required v-model.trim="currentData.name" type="text" class="form-control" placeholder="Enter Company Name">
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12">
                      <div class="form-group">
                          <!--<div class="float-right">
                              <a href="#" data-toggle="modal" data-target="#modalIndustry">
                                  <span>Add New</span>
                              </a>
                          </div>-->
                          <label>Industry <span class="req"></span></label>
                          <select required v-model.trim="industry" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="19" tabindex="-1" aria-hidden="true">
                              <option value="" disabled >--Choose an option--</option>
                              <option v-for="data, index in industryList" :key="index">{{data.name}}</option>
                          </select>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-6">
                      <div class="form-group">
                          <label>Phone <span class="req"></span></label>
                          <input required v-model.trim="currentData.phone" class="form-control" type="text" placeholder="Enter Phone">
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <label>Email <span class="req"></span></label>
                          <input required v-model.trim="currentData.email" type="text" class="form-control" placeholder="Enter Email">
                      </div>
                  </div>
              </div>
              <div class="form-group">
                  <label>Website</label>
                  <input required v-model.trim="currentData.website" type="text" class="form-control" placeholder="Enter Website">
              </div>
              <div class="form-group">
                  <label>Address</label>
                  <div>
                      <textarea required v-model.trim="currentData.address" class="form-control" rows="2" placeholder="Enter Address"></textarea>
                  </div>
              </div>
              <div class="form-group">
                  <label>Country <span class="req"></span> </label>
                  <select required v-model="country" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="22" tabindex="-1" aria-hidden="true">
                      <option value="" disabled >--Choose an option--</option>
                      <option v-for="data, index in countryList" :key="index">{{data.country}}</option>
                  </select>
              </div>
          </div>

          <div class="modal-footer">
              <button @click="clearData" type="button" class="btn btn-light waves-effect">Discard</button>
              <button   type="submit" class="btn btn-primary waves-effect waves-light">Save</button>
          </div>
          </form >
        </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- end modal -->
        <div class="modal-content p-4" style="background-color: #eeeeee;">
        <span @click="closeModal" style="cursor: pointer;" class="closeModal"> </span>

            <div class="modal-header mb-0" style="background-color: #eeeeee;">
                    <div class=" ml-2 mr-0">
                <h5> <i class="fa fa-braille fa-2x mr-3"></i> {{editId ? "Update Opportunity" : "Create Opportunity"}}</h5>
                </div>
            </div>
            <form @submit.prevent="addOpportunity(opportunity)" >
                   <div class="modal-body ">
                  <small class="text-muted mb-4"> All Fields are required **</small>
                                <div class="form-group mt-2">
                                    <label>Product <span class="req"></span></label>
                                    <div>
                                        <select required v-model.trim="opportunity.product_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="1" tabindex="-1" aria-hidden="true">
                                            <option value="" disabled>Choose a Product</option>
                                            <option v-for="data, index in productList" :value="data.id" :key="index">{{data.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="float-right">
                                                <a href="#" data-toggle="modal" data-target="#myModal">
                                                    <span class="text-muted small"> <i class="fa fa-plus-circle text-success"> </i>  Add New</span>
                                                </a>
                                            </div>
                                            <label>Company <span class="req"></span></label>
                                            <div>
                                                <select required v-model.trim="opportunity.company_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="4" tabindex="-1" aria-hidden="true">
                                                    <option value="" disabled> Select Company</option>
                                                 <option v-for="data, index in companyList" :value="data.id" :key="index">{{data.name}}</option>
                                               </select>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="float-right">
                                                <a href="#" data-toggle="modal" data-target="#modalPeople">
                                                    <span class="text-muted small"> <i class="fa fa-plus-circle text-success"> </i>  Add New</span>
                                                </a>
                                            </div>
                                            <label>Main Contact <span class="req"></span></label>
                                            <div>
                                                <select required v-model.trim="opportunity.main_contact" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="7" tabindex="-1" aria-hidden="true">
                                                    <option  value="" disabled>Select Contact</option>
                                                      <option v-for="data, index in contactList" :value="data.id" :key="index">{{data.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>License Type <span class="req"></span></label>
                                            <select required v-model.trim="opportunity.license_type" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="10" tabindex="-1" aria-hidden="true">
                                                <option value="" disabled>Choose an option</option>
                                                <option value="1" selected >Per User License</option>
                                                <option value="2">Corporate License</option>
                                            </select>
                                           
                                        </div>
                                    </div>
                                    <div class="col-md-4" id="hideshowUsers">
                                        <div class="form-group">
                                            <label>Number of Users <span class="req"></span></label>
                                            <input required v-model.trim="opportunity.number_of_users"  class="form-control" type="number"  placeholder="Enter Number">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Value (NGN) <span class="req"></span></label>
                                            <input required v-model.trim="opportunity.license_value" type="number" class="form-control" placeholder="Enter Value">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Stage <span class="req"></span></label>
                                            <select required v-model.trim="opportunity.stage_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="13" tabindex="-1" aria-hidden="true">
                                                <option value="" disabled>Select Current Stage</option>
                                                <option v-for="data, index in stages" :value="data.id" :key="index">{{data.name}}</option>
                                            </select>
                                            <!-- <span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="14" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-jkr3-container"><span class="select2-selection__rendered" id="select2-jkr3-container" role="textbox" aria-readonly="true" title="New">New</span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span> -->
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Opportunity Owner <span class="req"></span></label>
                                            <div>
                                                <select   required v-model.trim="opportunity.opportunity_owner" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="16" tabindex="-1" aria-hidden="true">
                                                    <option value="" disabled>Select Opportunity Owner</option>
                                                    <option v-for="data, index in users" :value="data.id" :key="index">{{data.first_name}} {{data.last_name}}</option>         
                                                </select>
                                              
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Priority <span class="req"></span></label>
                                            <div>
                                                <select   required v-model.trim="opportunity.priority" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="16" tabindex="-1" aria-hidden="true">
                                                    <option value="" disabled>Set Priority Level</option>
                                                    <option value="High" selected >High</option>
                                                    <option value="Medium">Medium</option>     
                                                    <option value="Low">Low</option>     
                                                </select>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Deal Date <span class="req"></span></label>
                                          <input required v-model.trim="opportunity.deal_date" type="date" @input="dateSelect(opportunity.deal_date)" class="form-control" placeholder="Enter Date">
                                            <!-- <span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="14" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-jkr3-container"><span class="select2-selection__rendered" id="select2-jkr3-container" role="textbox" aria-readonly="true" title="New">New</span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span> -->
                                        </div>
                                    </div>
                                      
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Close Date <span class="req"></span></label>
                                         <input required v-model.trim="opportunity.close_date" type="date" class="form-control" placeholder="Enter Date">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Description <span class="req"></span></label>
                                    <div>
                                        <textarea  required v-model.trim="opportunity.description" class="form-control" rows="2" placeholder="Enter Description"></textarea>
                                    </div>
                                </div>
                    </div>

          <div class="modal-footer mt-n3 mb-n2">
              <button @click="closeModal" type="button" class="btn btn-light waves-effect">Discard</button>
              <button type="submit" class="btn btn-primary waves-effect waves-light">Save</button>
          </div>
      </form >
        </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- end modal -->

        <!--Start Modal -->
    <div class="modal right fade" id="myEditModal"   data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content p-4" style="background-color: #eeeeee;">
        <span @click="closeEditModal" style="cursor: pointer;" class="closeModal"> </span>

            <div class="modal-header mb-0" style="background-color: #eeeeee;">
                    <div class=" ml-2 mr-0">
                <h5> <i class="fa fa-braille fa-2x mr-3"></i> Update Opportunity Info</h5>
                </div>
            </div>
<form @submit.prevent="updateOpportunity(opportunityUpdate)" >
       <div class="modal-body ">
<small class="text-muted mb-4"> All Fields are required **</small>
                                <div class="form-group mt-2">
                                    <label>Product <span class="req"></span></label>
                                    <div>
                                        <select required v-model.trim="opportunityUpdate.product_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="1" tabindex="-1" aria-hidden="true">
                                            <option  value="" disabled>Choose a Product</option>
                                            <option v-for="data, index in productList" :value="data.id" :key="index">{{data.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <!-- <div class="float-right">
                                                <a href="#" data-toggle="modal" data-target="#myModal">
                                                    <span class="text-muted small"> <i class="fa fa-plus-circle text-success"> </i>  Add New</span>
                                                </a>
                                            </div> -->
                                            <label>Company <span class="req"></span></label>
                                            <div>
                                                <select required v-model.trim="opportunityUpdate.company_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="4" tabindex="-1" aria-hidden="true">
                                                    <option value="" disabled> Select Company</option>
                                                 <option v-for="data, index in companyList" :value="data.id" :key="index">{{data.name}}</option>
                                               </select>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <!-- <div class="float-right">
                                                <a href="#" data-toggle="modal" data-target="#modalPeople">
                                                    <span class="text-muted small"> <i class="fa fa-plus-circle text-success"> </i>  Add New</span>
                                                </a>
                                            </div> -->
                                            <label>Main Contact <span class="req"></span></label>
                                            <div>
                                                <select required v-model.trim="opportunityUpdate.main_contact" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="7" tabindex="-1" aria-hidden="true">
                                                    <option  value="" disabled>Select Contact</option>
                                                      <option v-for="data, index in contactList" :value="data.id" :key="index">{{data.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>License Type <span class="req"></span></label>
                                            <select required v-model.trim="opportunityUpdate.license_type" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="10" tabindex="-1" aria-hidden="true">
                                                <option value="" disabled>Choose an option</option>
                                                <option value="1" selected >Per User License</option>
                                                <option value="2">Corporate License</option>
                                            </select>
                                           
                                        </div>
                                    </div>
                                    <div class="col-md-4" id="hideshowUsers">
                                        <div class="form-group">
                                            <label>Number of Users <span class="req"></span></label>
                                            <input required v-model.trim="opportunityUpdate.number_of_users"  class="form-control" type="number"  placeholder="Enter Number">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Value (NGN) <span class="req"></span></label>
                                            <input required v-model.trim="opportunityUpdate.license_value" type="number" class="form-control" placeholder="Enter Value">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Stage <span class="req"></span></label>
                                            <select required v-model.trim="opportunityUpdate.stage_id" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="13" tabindex="-1" aria-hidden="true">
                                                <option value="" disabled>Select Current Stage</option>
                                                <option v-for="data, index in stages" :value="data.id" :key="index">{{data.name}}</option>
                                            </select>
                                            <!-- <span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="14" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-jkr3-container"><span class="select2-selection__rendered" id="select2-jkr3-container" role="textbox" aria-readonly="true" title="New">New</span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span> -->
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Opportunity Owner <span class="req"></span></label>
                                            <div>
                                                <select   required v-model.trim="opportunityUpdate.opportunity_owner" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="16" tabindex="-1" aria-hidden="true">
                                                    <option value="" disabled>Select Opportunity Owner</option>
                                                    <option v-for="data, index in users" :value="data.id" :key="index">{{data.first_name}} {{data.last_name}}</option>         
                                                </select>
                                              
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Priority <span class="req"></span></label>
                                            <div>
                                                <select   required v-model.trim="opportunityUpdate.priority" class="form-control select2 select2-hidden-accessible" style="width: 100%" data-select2-id="16" tabindex="-1" aria-hidden="true">
                                                    <option value="" disabled>Set Priority Level</option>
                                                    <option value="High" selected >High</option>
                                                    <option value="Medium">Medium</option>     
                                                    <option value="Low">Low</option>     
                                                </select>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Deal Date <span class="req"></span></label>
                                          <input required v-model.trim="opportunityUpdate.deal_date" type="date" @input="dateSelect(opportunity.deal_date)" class="form-control" placeholder="Enter Date">
                                            <!-- <span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="14" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-jkr3-container"><span class="select2-selection__rendered" id="select2-jkr3-container" role="textbox" aria-readonly="true" title="New">New</span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span> -->
                                        </div>
                                    </div>
                                      
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Close Date <span class="req"></span></label>
                                         <input required v-model.trim="opportunityUpdate.close_date" @input="dateSelect(opportunity.close_date)" type="date" class="form-control" placeholder="Enter Date">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Description <span class="req"></span></label>
                                    <div>
                                        <textarea  required v-model.trim="opportunityUpdate.description" class="form-control" rows="2" placeholder="Enter Description"></textarea>
                                    </div>
                                </div>
                            </div>

          <div class="modal-footer mt-n3 mb-n2">
              <button @click="closeEditModal" type="button" class="btn btn-light waves-effect">Discard</button>
              <button type="submt" class="btn btn-primary waves-effect waves-light"  >Update</button>
          </div>
</form  >
        </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- end modal -->


                
  </div>
</template>

<script lang="ts">
  import { Vue, Options } from "vue-class-component";
  import { addCompany, getCountries, updateCompany } from "@/store/api/company";
  import { getOpportunities, getCompanies, addOpportunity, deleteOpportunity, updateOpportunity } from "@/store/api/opportunityApi";
  import { GetAllProducts, getStage  } from "@/store/api/configsettings";
  import { getPeople  } from "@/store/api/people";
  import { getUsers  } from "@/store/api/usermgt";
  import Toast from 'izitoast';
  import 'izitoast/dist/css/iziToast.css';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import $, {event} from "jquery";
  import { getIndustries } from "@/store/api/industry";
  import VueSelect from 'vue-next-select';
  import 'vue-next-select/dist/index.min.css';
//   <vue-select v-model.trim="selectedOption" :options="productNames" close-on-select required></vue-select> 
  @Options({
    components: {
      Loading, VueSelect
    }
  })

  export default class OpportunityList extends Vue {
    private isLoading = false;
    private prospectList : any[] = []; //eslint-disable-line
    private opportunityList : any[] = []; //eslint-disable-line
    private industryList : any[] = []; //eslint-disable-line
    private countryList : any[] = []; //eslint-disable-line
    private companyList : any[] = []; //eslint-disable-line
    private productList : any[] = []; //eslint-disable-line
    private contactList : any[] = []; //eslint-disable-line
    private stages : any[] = []; //eslint-disable-line
    private users : any[] = []; //eslint-disable-line
    private industry = "";
    private country = "";
    private editId = "";
    private oppId = "";
    private editIndex = -1;
    
    private currentData = {
      name : "",
      phone : "",
      email : "",
      website : "",
      address : "",
      industry_id : "",
      country_id : "",
      status : false,
    };
    private opportunity = {
        product_id: "",
        company_id: "",
        main_contact: "",
        stage_id: "",
        deal_date: "",
        opportunity_owner: "",
        close_date: "",
        priority: "",
        license_type: "",
        number_of_users: "",
        license_value: "",
        description: "",
    };
    private opportunityUpdate = {
        product_id: "",
        company_id: "",
        main_contact: "",
        stage_id: "",
        deal_date: "",
        opportunity_owner: "",
        close_date: "",
        priority: "",
        license_type: "",
        number_of_users: "",
        license_value: "",
        description: "",
    };

    private subscriptionList : any[] = []; //eslint-disable-line

    created() { //eslint-disable-line
      this.getAllOpportunities();
      this.getCountries();
      this.getIndustries();
      this.getAllProducts();
      this.getCompanies();
      this.getStages();
      this.getContacts();
      this.getUsers();
    }

    beforeUnmount() : void { 
      this.subscriptionList.forEach((subscription) => subscription.unsubscribe());
      // console.log('The unsubscription goes fine');
    }

    private setEdit(data, index) {
      this.currentData = data;
      this.editId = data.id;
      this.editIndex = index;
      this.country = data.country.country || "Choose Country";
      this.industry = data.industry.name || "Choose Industry";
      console.log('The current edit is: ', data);
    }

    private saveData() {
      // this runs, if the data is an edited data.
      if(this.editId && this.currentData.name) {
        for (let i = 0; i < this.countryList.length; i++) {
          const element = this.countryList[i];
          if(element.country === this.country) {
            this.currentData.country_id = element.id;
            break;
          }
        }

        for (let i = 0; i < this.industryList.length; i++) {
          const element = this.industryList[i];
          if(element.name === this.industry) {
            this.currentData.industry_id = element.id;
            break;
          }
        }

        this.updateCompany();
      } else if(!this.editId && this.currentData.name) {
        for (let i = 0; i < this.countryList.length; i++) {
          const element = this.countryList[i];
          if(element.country === this.country) {
            this.currentData.country_id = element.id;
            break;
          }
        }

        for (let i = 0; i < this.industryList.length; i++) {
          const element = this.industryList[i];
          if(element.name === this.industry) {
            this.currentData.industry_id = element.id;
            break;
          }
        }

        this.addCompany();
      }
    }

    private clearData() {
      $('#myModal').modal('hide');
      this.editId = "";
      this.editIndex = -1;
      this.country = "";
      this.industry = "";
      this.currentData = {
        name : "",
        phone : "",
        email : "",
        website : "",
        address : "",
        industry_id : "",
        country_id : "",
        status : false,
      };
    }
    private closeModal() {
      $('#leadModal').modal('hide');
        this.opportunity = {
        product_id: "",
        company_id: "",
        main_contact: "",
        stage_id: "",
        opportunity_owner: "",
        close_date: "",
        priority: "",
        deal_date: "",
        license_type: "",
        number_of_users: "",
        license_value: "",
        description: "",
    };
    }
    private closeEditModal() {
      $('#myEditModal').modal('hide');
      this.oppId = '';
    //     this.opportunityUpdate = {
    //     product_id: "",
    //     company_id: "",
    //     main_contact: "",
    //     stage_id: "",
    //     opportunity_owner: "",
    //     license_type: "",
    //     number_of_users: "",
    //     license_value: "",
    //     description: "",
    // };
    }

    private getCountries() {
      const getSubscription = getCountries().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          // console.log('Get Prospects is fetched successfully');
          
          this.countryList = response.data.countries;
          // console.log("The Countries list value is: ", this.countryList);
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private getIndustries() {
      const getSubscription = getIndustries().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          // console.log('Get Industries is fetched successfully');
          this.industryList = response.data.industries;
          // console.log("The Countries list value is: ", this.industryList);
        }  
      });
      this.subscriptionList.push(getSubscription);
    }

    private getCompanies() {
      const getSubscription = getCompanies().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.companyList = response.data.companies;
        } 
      });
      this.subscriptionList.push(getSubscription);
    }
    private getAllProducts() {
      const getSubscription = GetAllProducts().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.productList = response.data.products;
        }  
      });
      this.subscriptionList.push(getSubscription);
    }

    private getContacts() {
      const getSubscription = getPeople().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.contactList = response.data.people;
        }
      });
      this.subscriptionList.push(getSubscription);
    }

    private getStages() {
      const getSubscription = getStage().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.stages = response.data.stages;
        }
      });
      this.subscriptionList.push(getSubscription);
    }

    private getUsers() {
      const getSubscription = getUsers().subscribe((response) => {
        if(response.data) {
          this.users = response.data.data;
        }
      });
      this.subscriptionList.push(getSubscription);
    }

    private getAllOpportunities() {
      this.isLoading = true;
      const getSubscription = getOpportunities().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.opportunityList = response.data.opportunity;
          this.isLoading = false;
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }


    private addOpportunity(opp: any) {
        console.log(opp, 'opportunity');
          this.isLoading = true;
      const getSubscription = addOpportunity(opp).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          getOpportunities().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.opportunityList = response.data.opportunity;
        }})
          this.isLoading = false;
          this.closeModal();
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 4000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 4000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 4000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }
    private addCompany() {
      this.isLoading = true;
      const getSubscription = addCompany(this.currentData).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          // console.log('Add company is successful');
          this.prospectList.push(response.data.company);
          this.companyList.push(response.data.company);
          // console.log("The prospects list value is: ", this.prospectList);
          this.isLoading = false;
          this.clearData();
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private updateCompany() {
      this.isLoading = true;
      const getSubscription =  updateCompany(this.editId, this.currentData).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          // console.log('update company is successful');
          this.prospectList.splice(this.editIndex, 1, response.data.company);
          // console.log("The prospects list value is: ", this.prospectList);
          this.isLoading = false;
          this.clearData();
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }


    private editOpp(x: any) {
      console.log(x, 'curr dtat');
      this.oppId = x.id;
    //     this.opportunityUpdate = {
    //     product_id: "",
    //     company_id: "",
    //     main_contact: "",
    //     stage_id: "",
    //     opportunity_owner: "",
    //     close_date: "",
    //     priority: "",
    //     deal_date: "",
    //     license_type: "",
    //     number_of_users: "",
    //     license_value: "",
    //     description: "",
    // };
    //  this.opportunityUpdate.close_date = x.close_date;
     this.opportunityUpdate.priority = x.priority;
    //  this.opportunityUpdate.deal_date =  x.deal_date;
    //  this.opportunityUpdate.license_type = x.license_type;
     this.opportunityUpdate.number_of_users = x.number_of_users;
     this.opportunityUpdate.license_value = x.license_value;
     this.opportunityUpdate.description = x.description;
    }
    private updateOpportunity(data: any) {
      this.isLoading = true;
      const getSubscription =  updateOpportunity(this.oppId, this.opportunityUpdate).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
        getOpportunities().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.opportunityList = response.data.opportunity;
        }})
          this.isLoading = false;
          this.closeEditModal();
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
          // Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
          //   id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
          //   titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          //   icon: 'fa fa-check',
          //   iconColor: 'white',
          // });
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }
    private updateOpportunityStage(stageName: any, data: any) {
      console.log(this.stages, '--', stageName, '---', data);
      const stageId = this.stages.find((stage) => {
          if(stage.name === stageName) {
            return stage.id
          }
      });
      console.log(stageId, 'stageId');
      let license_typeCode = 0;
      if(data.license_type == "Per User License" || data.license_type == '1') {
        license_typeCode = 1;
      } else {
        license_typeCode = 2;
      }
         const opportunityUpdate = {
         product_id: data.product_id,
        company_id: data.company_id,
        main_contact: data.main_contact,
        stage_id: stageId.id,
        opportunity_owner: data.opportunity_owner,
        close_date: data.close_date,
        priority: data.priority,
        deal_date: data.deal_date,
        license_type: license_typeCode,
        number_of_users: data.number_of_users,
        license_value: data.license_value,
        description: data.description,
    };
      
      this.isLoading = true;
      const getSubscription =  updateOpportunity(data.id, opportunityUpdate).subscribe((response) => {
        if(response.data && response.data.status === 'success') {
        getOpportunities().subscribe((response) => {
        if(response.data && response.data.status === 'success') {
          this.opportunityList = response.data.opportunity;
        }})
         this.isLoading = false;
 
          Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-check',
            iconColor: 'white',
          });
        } else {
          Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
            id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
            titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
            icon: 'fa fa-error',
            iconColor: 'white',
          });
          this.isLoading = false;
          console.log('Error occured while fetching data', response.message);
        }
      }, (error) => {
        Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
          id: 'toastAuthy', zindex: 99,  title: `${error}`,
          titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
          icon: 'fa fa-error',
          iconColor: 'white',
        });
        this.isLoading = false;
        console.log('Error occured while fetching data', error);
      });
      this.subscriptionList.push(getSubscription);
    }

    private deleteOpp(oppId : string) {
      Toast.question({
        timeout: 20000,
        close: false,
        overlay: false,
        id: 'question',
        zindex: 1000,
        title: '',
        message: 'Are you sure ?',
        position: "topRight",
        buttons: [
            ['<button><b>YES</b></button>',  (instance, toast) => {
        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          this.isLoading = true;
          const getSubscription = deleteOpportunity(oppId).subscribe((response) => {
            if(response.data.status === 'success') {
                  getOpportunities().subscribe((response) => {
                if(response.data && response.data.status === 'success') {
                this.opportunityList = response.data.opportunity;
                }})
                this
              Toast.success({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: '#297763', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title: `${response.data.message}`,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-check',
                iconColor: 'white',
              });
            } else {
              console.log('Error occured while fetching data', response);
              Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
                id: 'toastAuthy', zindex: 99,  title:`${response.data.message}`,
                titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
                icon: 'fa fa-error',
                iconColor: 'white',
              });
            }

            this.isLoading = false;
          }, (error) => {
            Toast.error({ message: "", class: 'toastAuth',transitionInMobile: 'fadeInUp', backgroundColor: 'red', timeout: 6000, overlay: false,
              id: 'toastAuthy', zindex: 99,  title: `${error}`,
              titleColor: 'white', titleSize: '15px',   position: 'topRight', drag: false,   overlayClose: true,
              icon: 'fa fa-error',
              iconColor: 'white',
            });
            console.log('Error occured while fetching data', error);
            this.isLoading = false;
          });
          this.subscriptionList.push(getSubscription);
        }, true],
        ['<button>NO</button>', (instance: any, toast: any) => { //eslint-disable-line
 
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 
           }, event],
        ],
   
      });
    }

    private numberWithCommas(x: any) {
    if (!x) return 0;
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

    private dateSelect(x: any) {
      console.log(new Date(x), x, 'datr'); 
  }
  }
</script>

<style scoped>
  .closeModal {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .closeModal:hover {
    opacity: 1;
  }
  .closeModal:before, .closeModal:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .closeModal:before {
    transform: rotate(45deg);
  }
  .closeModal:after {
    transform: rotate(-45deg);
  }
</style>